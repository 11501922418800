import confObjective from '../common/conf/objective';

export const getDecimalLength = (value: string | number = ''): number => {
  if (typeof value === 'number') {
    value = value.toString();
  }

  const array = value.split('.');

  return array.length > 1 ? array[1].length : 0;
};

export const max100 = (value: number) => {
  if (value > 100) {
    return 100;
  }
  return value;
};

export const localeFormatNumber = (value: number | string | undefined, locale: string) => {
  if (value === undefined) {
    return value;
  }

  try {
    const localeFormat = (() => {
      if (locale === 'en') {
        return 'en-US';
      }
      return 'ja-JP';
    })();
    const number: number = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat(localeFormat).format(number);
  } catch {
    return value;
  }
};

export const calculatePercentage = (current: number, min: number, max: number): number => {
  const range = max - min;
  const percentage = ((current - min) / range) * 100;
  const rounded = confObjective.metrics.rounding(Math.round(percentage * 100) / 100);
  return rounded === 0 ? 0 : rounded;
};

export const calculateValueFromPercentage = (percentage: number, min: number, max: number) => {
  const percent = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;

  const range = max - min;
  const value = (percent / 100) * range + min;
  return Math.round(value * 100) / 100; // Rounding to two decimal places
};
