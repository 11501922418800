import React, { FC } from 'react';
import { icons, Button } from 're-design';
import { useTranslation } from 'react-i18next';
import DefaultPageBody from '../../layouts/DefaultPageBody';
import HeadTag from '../../atoms/HeadTag';

import { PageError as BasePageError, ErrorBody, ErrorFooter } from './styled';
import Typo from '../../quarks/Typo';
import AttentionIcon from '../../quarks/icons/Attention';
import Heading from '../../quarks/Heading';

const PageError: FC<{
  refetch?: null | (() => void);
  includeLayout?: boolean;
  headTag?: string;
}> = ({ refetch, includeLayout = false, headTag }) => {
  const { t } = useTranslation();

  const body = () => (
    <BasePageError>
      {!!headTag && <HeadTag title={headTag} />}
      <div className="mb-6">
        <AttentionIcon size="64px" pathClassName="fill-current text-disabled" className="mx-auto" />
      </div>
      <ErrorBody>
        <Heading color="text-02" align="center" className="mb-6">{t('ページの読み込みに失敗しました')}</Heading>
        <Typo variant="body-02" color="text-02">
          {t('しばらく時間をおいてから再度お試しください。')}
        </Typo>
        <Typo variant="body-02" color="text-02">
          {t('改善されない場合は、Wistantサポートまでお問い合わせください。')}
        </Typo>
      </ErrorBody>

      <ErrorFooter>
        {!!refetch && (
          <Button variant="quinary" outline icon={<icons.RefreshIcon />} onClick={() => refetch()}>
            {t('ページを再読み込み')}
          </Button>
        )}
      </ErrorFooter>
    </BasePageError>
  );

  if (includeLayout) {
    return <DefaultPageBody>{body()}</DefaultPageBody>;
  }
  return body();
};

export default PageError;
