
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DomainSource": [
      "DomainSourceCompany",
      "DomainSourceTeam",
      "DomainSourceUser"
    ],
    "FeedbackLayer": [
      "FeedbackFinalLayer",
      "FeedbackMiddleLayer",
      "FeedbackReadLayer",
      "FeedbackSelfLayer",
      "FeedbackSuperviseLayer"
    ],
    "FeedbackSheetItem": [
      "FeedbackSheetCalculateItem",
      "FeedbackSheetCommentItem",
      "FeedbackSheetGradeItem",
      "FeedbackSheetGuidelineItem",
      "FeedbackSheetListItem",
      "FeedbackSheetNumberItem",
      "FeedbackSheetObjectiveItem",
      "FeedbackSheetPropertyItem",
      "FeedbackSheetSectionItem",
      "FeedbackSheetSingleLineItem"
    ],
    "FeedbackSheetItemTemplate": [
      "FeedbackSheetCalculateItemTemplate",
      "FeedbackSheetCommentItemTemplate",
      "FeedbackSheetGradeItemTemplate",
      "FeedbackSheetGuidelineItemTemplate",
      "FeedbackSheetListItemTemplate",
      "FeedbackSheetNumberItemTemplate",
      "FeedbackSheetObjectiveItemTemplate",
      "FeedbackSheetPropertyItemTemplate",
      "FeedbackSheetSectionItemTemplate",
      "FeedbackSheetSingleLineItemTemplate"
    ],
    "FeedbackSheetTemplate": [
      "CompanyFeedbackSheetTemplate",
      "SystemFeedbackSheetTemplate"
    ],
    "O3ObjectiveMetricsSummary": [
      "O3CompanyObjectiveMetricsSummary",
      "O3TeamObjectiveMetricsSummary"
    ],
    "ObjectiveSheetItem": [
      "ObjectiveSheetCalculateItem",
      "ObjectiveSheetCommentItem",
      "ObjectiveSheetGradeItem",
      "ObjectiveSheetGuidelineItem",
      "ObjectiveSheetListItem",
      "ObjectiveSheetNumberItem",
      "ObjectiveSheetPropertyItem",
      "ObjectiveSheetSectionItem",
      "ObjectiveSheetSingleLineItem"
    ],
    "ObjectiveSheetItemTemplate": [
      "ObjectiveSheetCalculateItemTemplate",
      "ObjectiveSheetCommentItemTemplate",
      "ObjectiveSheetGradeItemTemplate",
      "ObjectiveSheetGuidelineItemTemplate",
      "ObjectiveSheetListItemTemplate",
      "ObjectiveSheetNumberItemTemplate",
      "ObjectiveSheetPropertyItemTemplate",
      "ObjectiveSheetSectionItemTemplate",
      "ObjectiveSheetSingleLineItemTemplate"
    ],
    "Okr": [
      "KeyMetricTag",
      "KeyResult",
      "Objective"
    ],
    "PersonalSheetItem": [
      "PersonalSheetCommentItem",
      "PersonalSheetGradeItem",
      "PersonalSheetGuidelineItem",
      "PersonalSheetNumberItem"
    ],
    "PersonalSheetItemTemplate": [
      "PersonalSheetCommentItemTemplate",
      "PersonalSheetGradeItemTemplate",
      "PersonalSheetGuidelineItemTemplate",
      "PersonalSheetNumberItemTemplate"
    ],
    "ResultIndicator": [
      "BoolIndicator",
      "NumberIndicator",
      "RateIndicator",
      "StepRateIndicator"
    ]
  }
};
      export default result;
    