import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { getItem, setItem, removeItem } from './local-storage';
import { createNewEvent } from '../utils/event';
import { WISTANT_APP_JWT, WISTANT_APP_JWT_MAX_AGE } from '../constants/cookies';

const KEY_AUTH_TOKEN = 'authToken';
export const AUTH_TOKEN_EVENT = 'token-changed';
const tokenChangedEvent = createNewEvent(AUTH_TOKEN_EVENT);

export function getToken() {
  return getItem(KEY_AUTH_TOKEN);
}

export function setToken(token: string) {
  setItem(KEY_AUTH_TOKEN, token);
  document.dispatchEvent(tokenChangedEvent);
}

export function resetToken() {
  removeItem(KEY_AUTH_TOKEN);
  document.dispatchEvent(tokenChangedEvent);
}

export function decodeToken(token: string) {
  if (!token || typeof token !== 'string') {
    throw new Error('token was not a string');
  }

  const tokenDecoded: {
    email: string;
  } = jwtDecode(token);
  if (!tokenDecoded) {
    throw new Error('cannot decode token');
  }

  return {
    email: tokenDecoded.email,
  };
}

export function setTokenCookie(authToken?: string) {
  if (!authToken) return;
  const cookies = new Cookies();
  cookies.set(WISTANT_APP_JWT, authToken, {
    path: '/',
    maxAge: WISTANT_APP_JWT_MAX_AGE,
    sameSite: 'lax',
    secure: true,
  });
  setToken(authToken);
}

export function removeTokenCookie() {
  const cookies = new Cookies();
  cookies.remove(WISTANT_APP_JWT);
  resetToken();
}
