import { createElement, FC, memo } from 'react';
import { Between, TypoColor, typoColorMap } from './types';
import { TextAlign } from './Typo';
import { twMerge } from 'tailwind-merge';
import { TypoSize } from './enum';

export type HeadingVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type HeadingBetween = Between;
export type HeadingBreak = "auto" | "all";

export interface HeadingProps {
  variant?: HeadingVariant;
  color?: TypoColor;
  className?: string;
  bold?: boolean;
  align?: TextAlign;
  break?: HeadingBreak;
  between?: HeadingBetween;
}

export const headingVariantMap: Record<HeadingVariant, string> = {
  "h1": `${TypoSize.ExtraLarge}`,
  "h2": `${TypoSize.Medium}`,
  "h3": `${TypoSize.Normal}`,
  "h4": `${TypoSize.Normal}`,
  "h5": `${TypoSize.Small}`,
  "h6": `${TypoSize.Tiny}`,
};

export const headingBreakMap: Record<HeadingBreak, string> = {
  "auto": `break-all`,
  "all": `break-all`
};

export const typoBetweenMap: Record<HeadingBetween, string> = {
  "none": '',
  "min": 'between-3',
  "small": 'between-5',
  "medium": 'between-6',
  "normal": 'between-7',
  "large": 'between-9',
};


const Heading: FC<HeadingProps> = memo(
  ({
    children,
    className,
    variant = "h1",
    color = "text-01",
    between,
    align,
    bold: _bold,
    break: _break,
    ...props
  }) => {
    let bold = _bold;

    const classNames: string[] = ['capitalize-first'];
    if (color) {
      classNames.push(typoColorMap[color]);
    }
    if (between) {
      classNames.push(typoBetweenMap[between]);
    }
    if (variant) {
      classNames.push(headingVariantMap[variant]);

      if (["h1", "h3"].includes(variant)) {
        if (bold === undefined) {
          bold = true;
        }
      }
    }
    if (bold) {
      classNames.push('font-bold');
    }
    if (align) {
      switch(align) {
        default:
          break;
        case "center":
          classNames.push(`text-center`);
          break;
        case "left":
          classNames.push(`text-left`);
          break;
        case "right":
          classNames.push(`text-right`);
          break;
        case "inherit":
          classNames.push(`text-inherit`);
          break;
      }
    }

    if(_break) {
      classNames.push(headingBreakMap[_break])
    }

    const el = createElement(`${variant}`, { ...props, className: twMerge(classNames, className) }, children);
    return el;
  },
);

export default Heading;
