import { useContext, FC, lazy } from 'react';
import { ModalActionContext } from '../components/contexts/ModalContext';

export enum ModalKey {
  CSVImportTeamsModal = 'CSVImportTeamsModal',
  CSVImportReportLinesModal = 'CSVImportReportLinesModal',
  CSVImportMembersModal = 'CSVImportMembersModal',
  CSVImportPairs = 'CSVImportPairs',
  CSVImportGroupTagMembers = 'CSVImportGroupTagMembers',
  BeforeObjectiveFormSelectSheet = 'BeforeObjectiveFormSelectSheet',
  BulkFeedbackCreateSetupAssigneesWithCsv = 'BulkFeedbackCreateSetupAssigneesWithCsv',
  BulkRetryInviteUser = 'BulkRetryInviteUser',
  CheckIn = 'CheckIn',
  CheckInSetting = 'CheckInSetting',
  ChildOKRSetting = 'ChildOKRSetting',
  CompanyProfile = 'CompanyProfile',
  ConfirmModal = 'ConfirmModal',
  ConnectChatworkAccountModal = 'ConnectChatworkAccountModal',
  ConnectMicrosoftTeamsAccount = 'ConnectMicrosoftTeamsAccount',
  ConnectSlackAccount = 'ConnectSlackAccount',
  ConnectTalknoteAccount = 'ConnectTalknoteAccount',
  ContactEdit = 'ContactEdit',
  CreditCard = 'CreditCard',
  CustomPropertyUpsertForm = 'CustomPropertyUpsertForm',
  EditAccount = 'EditAccount',
  EditStartingMonthModal = 'EditStartingMonthModal',
  EditUser = 'EditUser',
  FeedbackAssignReceiverModal = 'FeedbackAssignReceiverModal',
  FeedbackCreateSetupAssignees = 'FeedbackCreateSetupAssignees',
  FeedbackCreateSetupBulkLayerAssignees = 'FeedbackCreateSetupBulkLayerAssignees',
  FeedbackHrPublish = 'FeedbackHrPublish',
  FeedbackPublicationType = 'FeedbackPublicationType',
  FeedbackReceiversFilters = 'FeedbackReceiversFilters',
  FeedbackSenderRequestModal = 'FeedbackSenderRequestModal',
  FeedbackSetPeriod = 'FeedbackSetPeriod',
  FeedbackSetReceivers = 'FeedbackSetReceivers',
  FeedbackSetting = 'FeedbackSetting',
  FeedbackSetupAssigneesCustomSheet = 'FeedbackSetupAssigneesCustomSheet',
  FeedbackTemplatePreview = 'FeedbackTemplatePreview',
  FormTopicModal = 'FormTopicModal',
  GroupForm = 'GroupForm',
  GroupTagForm = 'GroupTagForm',
  GroupTagMemberForm = 'GroupTagMemberForm',
  GroupTagMemberModal = 'GroupTagMemberModal',
  HrAuthorityTable = 'HrAuthorityTable',
  HrReportLineSettingsModal = 'HrReportLineSettingsModal',
  HrScopedRoleSetting = 'HrScopedRoleSetting',
  HrmosSyncTeamsModal = 'HrmosSyncTeamsModal',
  HrmosSyncUsersModal = 'HrmosSyncUsersModal',
  ImportDataModal = 'ImportDataModal',
  ImportExportExcludedHrmosUserModal = 'ImportExportExcludedHrmosUserModal',
  CSVImportUserCustomProperties = 'CSVImportUserCustomProperties',
  IndicatorUpdateModal = 'IndicatorUpdateModal',
  InviteUserModal = 'InviteUserModal',
  KeyResultUpdateField = 'KeyResultUpdateField',
  KeyResultUpsert = 'KeyResultUpsert',
  MetricTagForm = 'MetricTagForm',
  O3sKptEdit = 'O3sKptEdit',
  O3sReschedule = 'O3sReschedule',
  O3sSheetTopicEdit = 'O3sSheetTopicEdit',
  O3sSummaryTableSheetModal = 'O3sSummaryTableSheetModal',
  ObjectiveCategoryFormModal = 'ObjectiveCategoryFormModal',
  ObjectiveChartModal = 'ObjectiveChartModal',
  ObjectiveSetting = 'ObjectiveSetting',
  ObjectiveSheetEditSheetModal = 'ObjectiveSheetEditSheetModal',
  ObjectiveSheetEditTemplateModal = 'ObjectiveSheetEditTemplateModal',
  ObjectiveSheetEventApproveModal = 'ObjectiveSheetEventApproveModal',
  ObjectiveSheetEventCancelModal = 'ObjectiveSheetEventCancelModal',
  ObjectiveSheetEventRejectModal = 'ObjectiveSheetEventRejectModal',
  ObjectiveSheetFormItemUpdate = 'ObjectiveSheetFormItemUpdate',
  ObjectiveSheetRequestApprovalModal = 'ObjectiveSheetRequestApprovalModal',
  ObjectiveSheetScheduleUpsert = 'ObjectiveSheetScheduleUpsert',
  ObjectiveSheetSetCsvPersonsModal = 'ObjectiveSheetSetCsvPersonsModal',
  ObjectiveSheetSetPersons = 'ObjectiveSheetSetPersons',
  ObjectiveSheetTargetObjectivesModal = 'ObjectiveSheetTargetObjectivesModal',
  ObjectiveTeamSetting = 'ObjectiveTeamSetting',
  ObjectiveUpdateField = 'ObjectiveUpdateField',
  ObjectiveUpsert = 'ObjectiveUpsert',
  UpdatePairModal = 'UpdatePairModal',
  CreatePairModal = 'CreatePairModal',
  PairSetting = 'PairSetting',
  ParentOKRSetting = 'ParentOKRSetting',
  PasswordChange = 'PasswordChange',
  PersonalSheetEditSheetModal = 'PersonalSheetEditSheetModal',
  PersonalSheetEditTemplateModal = 'PersonalSheetEditTemplateModal',
  PersonalSheetFormItemUpdate = 'PersonalSheetFormItemUpdate',
  PersonalSheetSetCsvPersonsModal = 'PersonalSheetSetCsvPersonsModal',
  PersonalSheetSetPersons = 'PersonalSheetSetPersons',
  PreSurveyTemplateUpdateModal = 'PreSurveyTemplateUpdateModal',
  PreSurveyTemplateCreateModal = 'PreSurveyTemplateCreateModal',
  RemindNotificationModal = 'RemindNotificationModal',
  Resign = 'Resign',
  ResignConfirm = 'ResignConfirm',
  RoleSettingModal = 'RoleSettingModal',
  SelectObjective = 'SelectObjective', //NOTUSED
  TeamForm = 'TeamForm',
  TeamReorderForm = 'TeamReorderForm',
  TreeTeamFilter = 'TreeTeamFilter',
  UserHrAuthority = 'UserHrAuthority',
  BookNextMeetingModal = 'BookNextMeeting',
  OkrActivityModal = 'OkrActivityModal',
  SyncWithReportLineModal = 'SyncWithReportLineModal',
  FeedbackPublishPreviewModal = 'FeedbackPublishPreviewModal',
  FormFeedbackRejectReasonModal = 'FormFeedbackRejectReasonModal',
  FeedbackRequestRejectConfirmModal = 'FeedbackRequestRejectConfirmModal',
  StartAssignToFeedbackSheetLayersBySchedule = 'StartAssignToFeedbackSheetLayersBySchedule',
  SelectFeedbackScheduleForCopy = 'SelectFeedbackScheduleForCopy',
}

export function useOpenModal<T>(
  modalType: FC<T> | ModalKey,
  modalKey?: string,
): (options?: T) => void {
  const { openModalWithKey, openModal, closeModal, closeModalWithKey } =
    useContext(ModalActionContext);

  const modalKeysMap: Record<ModalKey, () => any> = {
    [ModalKey.CSVImportReportLinesModal]: () =>
      lazy(() => import('../components/organisms/_modals/CSVImportReportLines')),
    [ModalKey.CSVImportMembersModal]: () =>
      lazy(() => import('../components/organisms/_modals/CSVImportMembers')),
    [ModalKey.CSVImportTeamsModal]: () =>
      lazy(() => import('../components/organisms/_modals/CSVImportTeams')),
    [ModalKey.CSVImportPairs]: () =>
      lazy(() => import('../components/organisms/_modals/CSVImportPairs')),
    [ModalKey.CSVImportGroupTagMembers]: () =>
      lazy(() => import('../components/organisms/_modals/CSVImportGroupTagMembers')),
    [ModalKey.BeforeObjectiveFormSelectSheet]: () =>
      lazy(() => import('../components/organisms/_modals/BeforeObjectiveFormSelectSheet')),
    [ModalKey.BulkFeedbackCreateSetupAssigneesWithCsv]: () =>
      lazy(() => import('../components/organisms/_modals/BulkFeedbackCreateSetupAssigneesWithCsv')),
    [ModalKey.BulkRetryInviteUser]: () =>
      lazy(() => import('../components/organisms/_modals/BulkRetryInviteUser')),
    [ModalKey.CheckIn]: () => lazy(() => import('../components/organisms/_modals/CheckIn')),
    [ModalKey.CheckInSetting]: () =>
      lazy(() => import('../components/organisms/_modals/CheckInSetting')),
    [ModalKey.CompanyProfile]: () =>
      lazy(() => import('../components/organisms/_modals/CompanyProfile')),
    [ModalKey.ChildOKRSetting]: () =>
      lazy(() => import('../components/organisms/_modals/ChildOKRSetting')),
    [ModalKey.ConfirmModal]: () =>
      lazy(() => import('../components/organisms/_modals/ConfirmModal')),
    [ModalKey.ConnectChatworkAccountModal]: () =>
      lazy(() => import('../components/organisms/_modals/ConnectChatworkAccountModal')),
    [ModalKey.ConnectMicrosoftTeamsAccount]: () =>
      lazy(() => import('../components/organisms/_modals/ConnectMicrosoftTeamsAccount')),
    [ModalKey.ConnectSlackAccount]: () =>
      lazy(() => import('../components/organisms/_modals/ConnectSlackAccount')),
    [ModalKey.ConnectTalknoteAccount]: () =>
      lazy(() => import('../components/organisms/_modals/ConnectTalknoteAccount')),
    [ModalKey.ContactEdit]: () => lazy(() => import('../components/organisms/_modals/ContactEdit')),
    [ModalKey.CreditCard]: () => lazy(() => import('../components/organisms/_modals/CreditCard')),
    [ModalKey.CustomPropertyUpsertForm]: () =>
      lazy(() => import('../components/organisms/_modals/CustomPropertyUpsertForm')),
    [ModalKey.EditAccount]: () => lazy(() => import('../components/organisms/_modals/EditAccount')),
    [ModalKey.EditStartingMonthModal]: () =>
      lazy(() => import('../components/organisms/_modals/EditStartingMonthModal')),
    [ModalKey.EditUser]: () => lazy(() => import('../components/organisms/_modals/EditUser')),
    [ModalKey.FeedbackAssignReceiverModal]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackAssignReceiverModal')),
    [ModalKey.FeedbackCreateSetupAssignees]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackCreateSetupAssignees')),
    [ModalKey.FeedbackCreateSetupBulkLayerAssignees]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackCreateSetupBulkLayerAssignees')),
    [ModalKey.FeedbackHrPublish]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackHrPublish')),
    [ModalKey.FeedbackPublicationType]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackPublicationType')),
    [ModalKey.FeedbackReceiversFilters]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackReceiversFilters')),
    [ModalKey.FeedbackSenderRequestModal]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackSenderRequestModal')),
    [ModalKey.FeedbackSetPeriod]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackSetPeriod')),
    [ModalKey.FeedbackSetReceivers]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackSetReceivers')),
    [ModalKey.FeedbackSetting]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackSetting')),
    [ModalKey.FeedbackSetupAssigneesCustomSheet]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackSetupAssigneesCustomSheet')),
    [ModalKey.FeedbackTemplatePreview]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackTemplatePreview')),
    [ModalKey.FormTopicModal]: () =>
      lazy(() => import('../components/organisms/_modals/FormTopicModal')),
    [ModalKey.GroupForm]: () => lazy(() => import('../components/organisms/_modals/GroupForm')),
    [ModalKey.GroupTagForm]: () =>
      lazy(() => import('../components/organisms/_modals/GroupTagForm')),
    [ModalKey.GroupTagMemberForm]: () =>
      lazy(() => import('../components/organisms/_modals/GroupTagMemberForm')),
    [ModalKey.GroupTagMemberModal]: () =>
      lazy(() => import('../components/organisms/_modals/GroupTagMemberModal')),
    [ModalKey.HrAuthorityTable]: () =>
      lazy(() => import('../components/organisms/_modals/HrAuthorityTable')),
    [ModalKey.HrReportLineSettingsModal]: () =>
      lazy(() => import('../components/organisms/_modals/HrReportLineSettingsModal')),
    [ModalKey.HrScopedRoleSetting]: () =>
      lazy(() => import('../components/organisms/_modals/HrScopedRoleSetting')),
    [ModalKey.HrmosSyncTeamsModal]: () =>
      lazy(() => import('../components/organisms/_modals/HrmosSyncModal/teams')),
    [ModalKey.HrmosSyncUsersModal]: () =>
      lazy(() => import('../components/organisms/_modals/HrmosSyncModal/users')),
    [ModalKey.ImportDataModal]: () =>
      lazy(() => import('../components/organisms/_modals/ImportDataModal')),
    [ModalKey.ImportExportExcludedHrmosUserModal]: () =>
      lazy(() => import('../components/organisms/_modals/ImportExportExcludedHrmosUserModal')),
    [ModalKey.CSVImportUserCustomProperties]: () =>
      lazy(() => import('../components/organisms/_modals/CSVImportUserCustomProperties')),
    [ModalKey.IndicatorUpdateModal]: () =>
      lazy(() => import('../components/organisms/_modals/IndicatorUpdateModal')),
    [ModalKey.InviteUserModal]: () =>
      lazy(() => import('../components/organisms/_modals/InviteUserModal')),
    [ModalKey.KeyResultUpdateField]: () =>
      lazy(() => import('../components/organisms/_modals/KeyResultUpdateField')),
    [ModalKey.KeyResultUpsert]: () =>
      lazy(() => import('../components/organisms/_modals/KeyResultUpsert')),
    [ModalKey.MetricTagForm]: () =>
      lazy(() => import('../components/organisms/_modals/MetricTagForm')),
    [ModalKey.O3sKptEdit]: () => lazy(() => import('../components/organisms/_modals/O3sKptEdit')),
    [ModalKey.O3sReschedule]: () =>
      lazy(() => import('../components/organisms/_modals/O3sReschedule')),
    [ModalKey.O3sSheetTopicEdit]: () =>
      lazy(() => import('../components/organisms/_modals/O3sSheetTopicEdit')),
    [ModalKey.O3sSummaryTableSheetModal]: () =>
      lazy(() => import('../components/organisms/_modals/O3sSummaryTableSheetModal')),
    [ModalKey.ObjectiveCategoryFormModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveCategoryFormModal')),
    [ModalKey.ObjectiveChartModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveChartModal')),
    [ModalKey.ObjectiveSetting]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSetting')),
    [ModalKey.ObjectiveSheetEditSheetModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetEditSheetModal')),
    [ModalKey.ObjectiveSheetEditTemplateModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetEditTemplateModal')),
    [ModalKey.ObjectiveSheetEventApproveModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetEventApproveModal')),
    [ModalKey.ObjectiveSheetEventCancelModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetEventCancelModal')),
    [ModalKey.ObjectiveSheetEventRejectModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetEventRejectModal')),
    [ModalKey.ObjectiveSheetFormItemUpdate]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetFormItemUpdate')),
    [ModalKey.ObjectiveSheetRequestApprovalModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetRequestApprovalModal')),
    [ModalKey.ObjectiveSheetScheduleUpsert]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetScheduleUpsert')),
    [ModalKey.ObjectiveSheetSetCsvPersonsModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetSetCsvPersonsModal')),
    [ModalKey.ObjectiveSheetSetPersons]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetSetPersons')),
    [ModalKey.ObjectiveSheetTargetObjectivesModal]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveSheetTargetObjectivesModal')),
    [ModalKey.ObjectiveTeamSetting]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveTeamSetting')),
    [ModalKey.ObjectiveUpdateField]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveUpdateField')),
    [ModalKey.ObjectiveUpsert]: () =>
      lazy(() => import('../components/organisms/_modals/ObjectiveUpsert')),
    [ModalKey.UpdatePairModal]: () =>
      lazy(() =>
        import('../components/organisms/_modals/Pair').then(module => ({
          default: module.UpdatePairModal,
        })),
      ),
    [ModalKey.CreatePairModal]: () =>
      lazy(() =>
        import('../components/organisms/_modals/Pair').then(module => ({
          default: module.CreatePairModal,
        })),
      ),
    [ModalKey.PairSetting]: () => lazy(() => import('../components/organisms/_modals/PairSetting')),
    [ModalKey.ParentOKRSetting]: () =>
      lazy(() => import('../components/organisms/_modals/ParentOKRSetting')),
    [ModalKey.PasswordChange]: () =>
      lazy(() => import('../components/organisms/_modals/PasswordChange')),
    [ModalKey.PersonalSheetEditSheetModal]: () =>
      lazy(() => import('../components/organisms/_modals/PersonalSheetEditSheetModal')),
    [ModalKey.PersonalSheetEditTemplateModal]: () =>
      lazy(() => import('../components/organisms/_modals/PersonalSheetEditTemplateModal')),
    [ModalKey.PersonalSheetFormItemUpdate]: () =>
      lazy(() => import('../components/organisms/_modals/PersonalSheetFormItemUpdate')),
    [ModalKey.PersonalSheetSetCsvPersonsModal]: () =>
      lazy(() => import('../components/organisms/_modals/PersonalSheetSetCsvPersonsModal')),
    [ModalKey.PersonalSheetSetPersons]: () =>
      lazy(() => import('../components/organisms/_modals/PersonalSheetSetPersons')),
    [ModalKey.PreSurveyTemplateCreateModal]: () =>
      lazy(() =>
        import('../components/organisms/_modals/PreSurveyTemplateModal').then(module => ({
          default: module.PreSurveyTemplateCreateModal,
        })),
      ),
    [ModalKey.PreSurveyTemplateUpdateModal]: () =>
      lazy(() =>
        import('../components/organisms/_modals/PreSurveyTemplateModal').then(module => ({
          default: module.PreSurveyTemplateUpdateModal,
        })),
      ),
    [ModalKey.RemindNotificationModal]: () =>
      lazy(() => import('../components/organisms/_modals/RemindNotificationModal')),
    [ModalKey.Resign]: () => lazy(() => import('../components/organisms/_modals/Resign')),
    [ModalKey.ResignConfirm]: () =>
      lazy(() => import('../components/organisms/_modals/ResignConfirm')),
    [ModalKey.RoleSettingModal]: () =>
      lazy(() => import('../components/organisms/_modals/RoleSettingModal')),
    [ModalKey.SelectObjective]: () =>
      lazy(() => import('../components/organisms/_modals/SelectObjective')),
    [ModalKey.TeamForm]: () => lazy(() => import('../components/organisms/_modals/TeamForm')),
    [ModalKey.TeamReorderForm]: () =>
      lazy(() => import('../components/organisms/_modals/TeamReorderForm')),
    [ModalKey.TreeTeamFilter]: () =>
      lazy(() => import('../components/organisms/_modals/TreeTeamFilter')),
    [ModalKey.UserHrAuthority]: () =>
      lazy(() => import('../components/organisms/_modals/UserHrAuthority')),
    [ModalKey.BookNextMeetingModal]: () =>
      lazy(() => import('../components/organisms/_modals/BookNextMeeting')),
    [ModalKey.OkrActivityModal]: () =>
      lazy(() => import('../components/organisms/_modals/OkrActivityModal')),
    [ModalKey.SyncWithReportLineModal]: () =>
      lazy(() => import('../components/organisms/_modals/SyncWithReportLineModal')),
    [ModalKey.FeedbackPublishPreviewModal]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackPublishPreviewModal')),
    [ModalKey.FormFeedbackRejectReasonModal]: () =>
      lazy(() => import('../components/organisms/_modals/FormFeedbackRejectReasonModal')),
    [ModalKey.FeedbackRequestRejectConfirmModal]: () =>
      lazy(() => import('../components/organisms/_modals/FeedbackRequestRejectConfirmModal')),
    [ModalKey.StartAssignToFeedbackSheetLayersBySchedule]: () =>
      lazy(
        () => import('../components/organisms/_modals/StartAssignToFeedbackSheetLayersBySchedule'),
      ),
    [ModalKey.SelectFeedbackScheduleForCopy]: () =>
      lazy(() => import('../components/organisms/_modals/SelectFeedbackScheduleForCopy')),
  };

  return (options?: T) => {
    const _getComponentFromModalType = <T = any>(modalType: FC<T> | ModalKey) => {
      if (typeof modalType === 'string') {
        if (modalType in modalKeysMap) {
          return modalKeysMap[modalType]();
        }
        return null;
      }
      return modalType;
    };

    if (modalKey) {
      openModalWithKey(_getComponentFromModalType(modalType), modalKey, {
        onClose: () => closeModalWithKey(modalKey),
        ...(options as T),
      });
    } else {
      openModal(_getComponentFromModalType(modalType), {
        onClose: closeModal,
        ...(options as T),
      });
    }
  };
}

export function useCloseModal(modalKey: string | undefined = undefined): () => void {
  const { closeModal, closeModalWithKey } = useContext(ModalActionContext);
  return () => {
    if (modalKey) {
      closeModalWithKey(modalKey);
    } else {
      closeModal();
    }
  };
}

export function useCloseAllModal(): () => void {
  const { closeAllModal } = useContext(ModalActionContext);
  return () => {
    closeAllModal();
  };
}

export function useConfirmModal(modalKey?: string): (options?: any) => void {
  const { closeModal, closeModalWithKey } = useContext(ModalActionContext);

  const openConfirmModal = useOpenModal(ModalKey.ConfirmModal, modalKey);

  return options => {
    openConfirmModal({
      title: '',
      description: '',
      onSubmit: () => {
        return;
      },
      onClose: () => (!modalKey ? closeModal() : closeModalWithKey(modalKey)),
      onCancel: () => (!modalKey ? closeModal() : closeModalWithKey(modalKey)),
      ...options,
    });
  };
}
