import { ToastContainer as BaseToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';

export const StyledToastContainer = styled(BaseToastContainer)`
  z-index: var(--index-toast);

  .Toastify__toast {
    width: 264px;
    min-height: auto;
    border-radius: var(--radius-tiny);
    padding: var(--spacing-5);
    font-size: 1.4rem;
    line-height: 1.8rem;
    box-shadow: 0 0 8px 0 var(--shadow-01) !important;
    animation-duration: 0.3s !important;
    animation-timing-function: ease-out !important;
  }

  .Toastify__toast-body {
    max-width: 216px;
    min-height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Toastify__toast--success {
    background-color: var(--success);
    color: var(--text-05);
  }

  .Toastify__toast--info {
    background-color: var(--interactive-01);
    color: var(--inverse-interactive);
  }

  .Toastify__toast--warning {
    background-color: var(--interactive-02);
    color: var(--inverse-interactive);
  }

  .Toastify__toast--error {
    background-color: var(--error);
    color: var(--text-05);
  }
`;

export const ActionLabelButton = styled.button`
  outline: none;
  background: transparent;
  border: 0;
  padding: 0;
  color: var(--inverse-interactive);
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-decoration: underline;
`;
