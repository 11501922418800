import { useContext } from 'react';
import { Languages } from '../common/enum';
import { LangActionContext } from '../components/contexts/LangContext';
import { getItem } from './local-storage';

export const DEFAULT_LANG = 'ja';

export const isAvailableLanguage = (lang: string | null | undefined): boolean =>
  lang ? Languages.has(lang) : false;

export const useGetCurrentLanguage = () => {
  const { getCurrentLang } = useContext(LangActionContext);
  return (): string => {
    const currentLang = getCurrentLang();
    return currentLang;
  };
};

export const useSetLanguage = () => {
  const { setLang } = useContext(LangActionContext);
  return (value: string, userId?: string | undefined): void => {
    setLang(value, userId);
  };
};

export const getCurrentLangFromLocaleStorage = (
  userId?: string | undefined | null,
  query?: undefined | any,
) => {
  const currentLang =
    (isAvailableLanguage(query?.lang)
      ? query.lang
      : getItem(userId ? `currentLang-${userId}` : 'currentLang')) ||
    getItem('currentLang') ||
    DEFAULT_LANG;

  return currentLang;
};
