import {
  UserScopedRoleKeys,
  User,
  UserRoles,
  UserStatuses,
  ScopedRole,
  Scope,
} from '../generated/graphql';
import { HrRoles } from '../common/enum';
import i18n from '../lib/i18n';
import { takeAwayMaybeElement } from './array';

export const hasRole = (actor: User, permittedRoles: string[] = []) => {
  if (!actor) return false;

  const roles = takeAwayMaybeElement<ScopedRole[]>(actor.scopedRoles).map(sr => sr.role?.key);
  return roles.some(role => permittedRoles.includes(role!));
};

export const hasAllRole = (actor: User, permittedRoles: string[] = []) => {
  if (!actor) return false;

  const roles = takeAwayMaybeElement<ScopedRole[]>(actor.scopedRoles).map(sr => sr.role?.key);
  return permittedRoles.every(role => roles.includes(role as UserScopedRoleKeys));
};

export const hasOwnerRole = (actor: User) => {
  return hasRole(actor, [UserRoles.Owner]);
};

export const hasHRProfessionalRole = (actor: User) => {
  return hasRole(actor, [
    UserRoles.AccountManagement,
    UserRoles.HrSetting,
    UserRoles.HrManagement,
    UserRoles.HrDataExport,
  ]);
};

export const hasAllHRProfessionalRole = (actor: User) => {
  return hasAllRole(actor, [
    UserRoles.AccountManagement,
    UserRoles.HrSetting,
    UserRoles.HrManagement,
    UserRoles.HrDataExport,
  ]);
};

export const hasAccountManagementRole = (actor: User) => {
  return hasRole(actor, [UserRoles.AccountManagement]);
};
export const hasHrSettingRole = (actor: User) => {
  return hasRole(actor, [UserRoles.HrSetting]);
};
export const hasHrManagementRole = (actor: User) => {
  return hasRole(actor, [UserRoles.HrManagement]);
};
export const hasHrDataExportRole = (actor: User) => {
  return hasRole(actor, [UserRoles.HrDataExport]);
};

export const hasMemberRole = (actor: User) => {
  return hasRole(actor, [UserRoles.Member]);
};

export const filterScopedRole = (actor: User, permittedScopedRoles: any[] = []): ScopedRole[] => {
  if (!actor || !Array.isArray(actor.scopedRoles)) return [];

  return takeAwayMaybeElement<ScopedRole[]>(actor.scopedRoles).filter(scopedRole =>
    permittedScopedRoles.some(({ role, sourceType, sourceId }) => {
      if (!role) return false;
      const _hasRole = scopedRole.role?.key === role;
      if (!_hasRole) return false; // role がマッチしないならダメ
      if (!sourceType && !sourceId) return true; // role の指定しかない場合は、マッチ

      return takeAwayMaybeElement<Scope[]>(scopedRole?.scopes).some(actorScope => {
        if (sourceType !== actorScope.sourceType) return false; // sourceType がマッチしないならダメ
        if (!sourceId) return true; // sourceId の指定しかない場合は、マッチ
        return +sourceId === +actorScope.sourceId!; // sourceId のマッチ次第
      });
    }),
  );
};

export const getHrpRolesString = (actor: User) => {
  const hrpRolesString: string[] = [];
  const hrpScopedRoles: ScopedRole[] = filterScopedRole(actor, [
    { role: UserRoles.AccountManagement },
    { role: UserRoles.HrManagement },
    { role: UserRoles.HrDataExport },
    { role: UserRoles.HrSetting },
  ]);
  if (hrpScopedRoles.length) {
    hrpScopedRoles.forEach((sr: ScopedRole) =>
      hrpRolesString.push(HrRoles.labelOf(sr?.role?.key!)),
    );
  }
  return hrpRolesString.length ? hrpRolesString.join(', ') : '';
};

export const getRolesString = (actor: User) => {
  const rolesString = [];
  if (actor) {
    if (hasOwnerRole(actor)) rolesString.push(i18n.t('システム管理者'));
    const hrpRolesString = getHrpRolesString(actor);
    if (hrpRolesString)
      rolesString.push(
        i18n.t('HR管理者（{{hrpRolesString}}）', {
          hrpRolesString,
        }),
      );
  }
  return rolesString.join(', ');
};

export const filterMembersWithoutMemberRole = (members: User[]) => {
  return takeAwayMaybeElement<User[]>(members).filter(
    member => member.status !== UserStatuses.Deleted && !hasMemberRole(member),
  );
};

export const filterMembersWithoutHrpRole = (members: User[]) => {
  return takeAwayMaybeElement<User[]>(members).filter(
    member => member.status !== UserStatuses.Deleted && !hasHRProfessionalRole(member),
  );
};

export const filterMembersWithoutOwnerRole = (members: User[]) => {
  return takeAwayMaybeElement<User[]>(members).filter(
    member => member.status !== UserStatuses.Deleted && !hasOwnerRole(member),
  );
};
