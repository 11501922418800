import React, { FC } from 'react';
import AppRouter from './Router';
import ModalProvider from '../../contexts/ModalContext';
import SideSheetProvider from '../../contexts/SideSheetContext';
import ToastContainer from '../../atoms/Toast';
import LocalStorageProvider from '../../contexts/LocalStorage';
import ErrorBoundary from '../ErrorBoundary';

import CacheBuster from '../../organisms/CacheBuster';
import { HelmetProvider } from 'react-helmet-async';

const Root: FC = () => {
  return (
    <HelmetProvider>
      <CacheBuster />
      <ErrorBoundary>
        <LocalStorageProvider>
          <ModalProvider>
            <SideSheetProvider>
              <ToastContainer />
              <AppRouter />
            </SideSheetProvider>
          </ModalProvider>
        </LocalStorageProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default Root;
