import { useMemo } from 'react';
import i18next from 'i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  PureQueryOptions,
  QueryResult,
  QueryHookOptions,
  split,
  useQuery,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
// import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { withScalars } from 'apollo-link-scalars';
import {
  OperationDefinitionNode,
  DocumentNode,
  buildClientSchema,
  IntrospectionQuery,
} from 'graphql';
import { createClient } from 'graphql-ws';
import {
  DateTimeResolver,
  DateResolver,
  TimeResolver,
  JSONResolver,
  JSONObjectResolver,
} from 'graphql-scalars';
import { getToken, resetToken, setToken } from './auth-token';
import generatedIntrospection from '../generated/introspection-result';
import introspectionResult from '../generated/graphql.introspection.json';
import sentry from '../utils/sentry';
import VersionUpLink from './version-up-link';
import { toast } from '../components/atoms/Toast';
import { useHostInfo } from './use-host';

const uri = `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/graphql`;
const wsUri = `${process.env.REACT_APP_WS_SCHEME}://${process.env.REACT_APP_WS_HOST}/subscriptions`;

export const initApolloClient = () => {
  const hostInfo = useHostInfo();
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();

  const getAuthorizationToken = async () => {
    let token = getToken();
    let tokenType = 'JWT';
    const shouldUseAuth0 = !!hostInfo?.auth0?.sso || !!hostInfo?.auth0?.google;
    if (shouldUseAuth0) {
      try {
        const authToken = await getAccessTokenSilently({
          audience: hostInfo?.auth0?.audience!,
        });
        if (authToken) {
          tokenType = 'Bearer';
          token = authToken;
          setToken(authToken);
        }
      } catch (e) {
        // console.error(e);
      }
    }
    return token ? `${tokenType} ${token}` : '';
  };
  // const getAuthorizationToken = () => {
  //   const token = getToken();
  //   let tokenType = 'JWT';
  //   const shouldUseAuth0 = !!hostInfo?.auth0?.sso || !!hostInfo?.auth0?.google;
  //   if (shouldUseAuth0) {
  //     setAuth0Token(); // 非同期で処理
  //     tokenType = 'Bearer';
  //   }
  //   return token ? `${tokenType} ${token}` : '';
  // };
  // const setAuth0Token = async () => {
  //   const shouldUseAuth0 = !!hostInfo?.auth0?.sso || !!hostInfo?.auth0?.google;
  //   if (shouldUseAuth0) {
  //     try {
  //       const auth0Token = await getAccessTokenSilently({
  //         audience: hostInfo?.auth0?.audience!,
  //       });
  //       if (auth0Token) setToken(auth0Token);
  //       return auth0Token;
  //     } catch (e) {
  //       console.error(e);
  //       return null;
  //     }
  //   }
  // };

  const errorLink = useMemo(
    () =>
      onError(error => {
        const { graphQLErrors, networkError, forward, operation } = error;
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) => {
            sentry.error(message, locations, { operation });
            if (
              message === 'アクセス権限がありません（無効なトークン）。' ||
              message === 'お使いのネットワークからのご利用は許可されておりません。'
            ) {
              toast.error(message);
              if (isAuthenticated) {
                logout({ returnTo: `${window.location.origin}/login` });
              } else {
                resetToken();
              }
            }
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          });
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          console.log('error', error);

          toast.error(
            i18next.t('ネットワークエラーが発生しました。通信環境の良い場所でお試しください'),
          );
        }
        return forward(operation);
      }),
    [isAuthenticated, logout],
  );

  const authLink = setContext(
    async (_: any, { headers = {} }: { headers?: Record<string, any> }) => {
      const authorization = await getAuthorizationToken();
      return {
        headers: {
          ...headers,
          Authorization: authorization || '',
        },
      };
    },
  );

  // setContext を使用すると、WS のネットワークエラーになる
  // ( すなわち、この方法では Auth0 の getAccessTokenSilently をうまく処理できない）
  // const wsLink = useMemo(
  //   () =>
  //     setContext(async () => {
  //       const shouldUseAuth0 = !!hostInfo?.auth0?.sso || !!hostInfo?.auth0?.google;
  //       if (shouldUseAuth0) {
  //         try {
  //           const auth0Token = await getAccessTokenSilently({
  //             audience: hostInfo?.auth0?.audience!,
  //           });
  //           if (auth0Token) setToken(auth0Token);
  //         } catch (e) {
  //           //
  //         }
  //       }
  //       return new WebSocketLink({
  //         uri: wsUri,
  //         options: {
  //           reconnect: true,
  //           connectionParams: { token: getToken() },
  //         },
  //       });
  //     }),
  //   [getAccessTokenSilently, hostInfo],
  // );
  // const wsLink = useMemo(() => {
  //   const authorization = getAuthorizationToken();
  //   return new WebSocketLink({
  //     uri: wsUri,
  //     options: {
  //       reconnect: true,
  //       connectionParams: { authorization },
  //     },
  //   });
  // }, [getAuthorizationToken]);
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUri,
      connectionParams: async () => ({ authorization: await getAuthorizationToken() }),
    }),
  );

  const versionUpLink = useMemo(() => new VersionUpLink(), []);

  const httpLink = useMemo(
    () =>
      createUploadLink({
        uri,
        credentials: 'same-origin',
        includeExtensions: true,
      }),
    [],
  );

  const customScalarLink = useMemo(
    () =>
      withScalars({
        schema: buildClientSchema((introspectionResult as unknown) as IntrospectionQuery),
        typesMap: {
          Date: DateResolver,
          Time: TimeResolver,
          DateTime: DateTimeResolver,
          JSON: JSONResolver,
          JSONObject: JSONObjectResolver,
        },
      }),
    [],
  );

  const splitLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    ApolloLink.from([customScalarLink, errorLink, /*authLink,*/ wsLink]),
    ApolloLink.from([customScalarLink, errorLink, authLink, versionUpLink, httpLink]),
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  return client;
};

export function createReusableQueryHook<TData, TVariables>(
  query: DocumentNode,
  variables: TVariables,
): {
  refetchQuery: PureQueryOptions;
  useReusableQuery: (
    options?: QueryHookOptions<TData, TVariables> | undefined,
  ) => QueryResult<TData, TVariables>;
} {
  const refetchQuery: PureQueryOptions = {
    query,
    variables,
  };

  const useReusableQuery = (options?: QueryHookOptions<TData, TVariables> | undefined) => {
    return useQuery<TData, TVariables>(query, {
      variables,
      ...options,
    });
  };

  return { refetchQuery, useReusableQuery };
}
