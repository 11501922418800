import palette from './palette';

const theme_dark = {
  'ui-background-01': palette.black, //Default page background
  'ui-background-02': palette.gray100, //Wizard Layout background
  'ui-background-03': palette.gray90, //O3s background

  'ui-01': palette.gray100, //default container background
  'ui-02': palette.gray90, //default container background on hover
  'ui-03': palette.gray80,
  'ui-04': palette.gray50,
  'ui-05': palette.gray80,

  'interactive-01': palette.green50,
  'interactive-02': palette.orange50,
  'interactive-03': palette.purple50,
  'interactive-04': palette.blue50,
  'interactive-05': palette.red50,

  'hover-interactive-01': palette.green70,
  'hover-interactive-02': palette.orange70,
  'hover-interactive-03': palette.purple70,
  'hover-interactive-04': palette.blue70,
  'hover-interactive-05': palette.red70,
  'inverse-interactive': palette.white,

  'text-01': palette.gray10, //Primary Text
  'text-02': palette.gray40, //Secondary Text / Label Text
  'text-03': palette.gray80, //Tertiary Text
  'text-04': palette.gray60, //Placeholder Text
  'text-05': palette.gray10, // Interactive Text
  'text-error': palette.red50, // Error message text
  'text-disabled': palette.gray80, // Disabled elements  / Disabled label / Disabled text / Disabled icons
  'text-success': palette.blue50, //Success message text

  link: palette.gray40, //link;
  'link-visited': palette.gray40, //Visited link;
  'link-hover': palette.gray10, //Hover link;
  'link-active': palette.gray10, //Active link;

  'border-01': palette.gray90, //container border
  'border-02': palette.gray70, //input border
  'border-03': palette.gray40,
  'border-04': palette.gray70,

  'chart-background': palette.gray60,
  'chart-01': palette.green50,
  'chart-02': palette.orange50,
  'chart-03': palette.purple50,
  'chart-04': palette.blue50,
  'chart-05': palette.red50,
  'chart-06': palette.springGreen50,
  'chart-07': palette.gray50,
  'chart-08': palette.yellow50,

  'chart-01-pale': palette.green30,
  'chart-04-pale': palette.blue30,
  'chart-05-pale': palette.red30,

  focus: palette.green50, //border focus; field focus;
  disabled: palette.gray80, //(input disabled)
  'disabled-checked': palette.green100,
  error: palette.red50, //field error,
  success: palette.blue50,

  'focus-pale': palette.gray80,
  'error-pale': palette.red30,
  'success-pale': palette.gray60,
  'success-bold': palette.gray20,

  'shadow-01': 'rgba(0, 0, 0, 0.5)',
  'shadow-02': 'rgba(0, 0, 0, 0.2)',
  'shadow-03': 'rgba(0, 0, 0, 0.75)',
  'shadow-04': 'rgba(0, 0, 0, 0.1)',
};

export default theme_dark;
