import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ThemeActionContext } from '../../contexts/ThemeContext';

export const PageTutorialWrap = styled.div`
    

    ${() => {

        const { currentTheme } = useContext(ThemeActionContext);
        const deg1 = currentTheme === 'light' ? '#cfe8eb' : '#3d4141';
        const deg2 = currentTheme === 'light' ? '#eaf5f6' : '#2d2f2f';

        return css`
            background: linear-gradient(135deg, ${deg1}, ${deg1} 50%, ${deg2} 0, ${deg2});
            overflow: hidden;
            position: relative;
        
            &:before {
                background: linear-gradient(45deg, ${deg1}, ${deg1} 20%, ${deg2} 0, ${deg2});
                opacity: .5;
                z-index: 2;
            } 
            &:after {
                background: linear-gradient(135deg, ${deg1}, ${deg1} 15%, ${deg2} 0, ${deg2});
                opacity: .2;
                z-index: 3;
            }  
        `
    }}
`;