import { JSONContent } from '@tiptap/core';
import { generateHTML, generateJSON } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import ImageExtension from '../components/organisms/_forms/TextEditor/Image';
import { Maybe, RichText, RichTextInput } from '../generated/graphql';
import { isJson } from './string';
import { draftjsToTiptap } from '../lib/draftjs-to-tiptap';

const getTiptapExtensions = () => [
  // FIXME: `yarn test` が通らない（ので、一時的にコメントアウトしていた）
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3],
    },
  }),
  Link.configure({
    autolink: true,
    openOnClick: true,
    linkOnPaste: false,
  }),
  Underline.configure({}),
  Image.configure({ inline: true }),
  ImageExtension.configure(),
];
export const isDraftJs = (raw: string | JSON | null): boolean => {
  try {
    const json: Record<string, any> | null = (() => {
      if (typeof raw === 'string' && isJson(raw)) {
        return JSON.parse(raw);
      }
      return typeof raw === 'object' ? raw : {};
    })();
    return !!json && 'blocks' in json && 'entityMap' in json;
  } catch {
    return false;
  }
};

export const isTiptap = (raw: string | JSON): boolean => {
  try {
    const json: Record<string, any> | null = (() => {
      if (typeof raw === 'string' && isJson(raw)) {
        return JSON.parse(raw);
      }
      return typeof raw === 'object' ? raw : {};
    })();

    return !!json && 'type' in json && 'content' in json;
  } catch {
    return false;
  }
};

export const getRichTextInput = (value: string | null | undefined = ''): RichTextInput | null => {
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = value || '';

  return {
    data: value ? generateJSON(value || '', getTiptapExtensions()) : {},
    text: getInnerTextFromHtml(tempDivElement.innerHTML),
  };
};

export const getRichTextInitialValueMap = (value: Maybe<RichText> | undefined) => {
  const emptyReturn = {
    raw: null,
    body: null,
    hasText: false,
    text: null,
  };
  if (!value) {
    return emptyReturn;
  }
  const raw: JSONContent = (() => {
    try {
      return isDraftJs(value?.data as any) ? draftjsToTiptap(value?.data as any) : value?.data;
    } catch (_) {
      return null;
    }
  })();

  if (!raw?.type) {
    return emptyReturn;
  }

  try {
    const body = generateHTML(raw, getTiptapExtensions());

    const text = (() => {
      const tempDivElement = document.createElement('div');
      tempDivElement.innerHTML = body;
      return getInnerTextFromHtml(tempDivElement.innerHTML);
    })();

    const hasText = !!text || body?.includes?.('img');
    return {
      raw,
      body,
      hasText,
      text,
    };
  } catch (_) {
    return emptyReturn;
  }
};

export const getRichTextJsontoPlainText = (richTextJson?: Maybe<RichText> | undefined) => {
  const data: any = isDraftJs(richTextJson?.data)
    ? draftjsToTiptap(richTextJson?.data)
    : richTextJson?.data;
  try {
    const body = generateHTML(data, getTiptapExtensions());
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = body;
    return getInnerTextFromHtml(tempDivElement.innerHTML);
  } catch (_) {
    return '';
  }
};

const getInnerTextFromHtml = (innerHtml: any) => {
  // Define tags where you want to insert line breaks

  const startBlockTags = ['div', 'ol', 'ul', 'blockquote'];
  const endBlockTags = [...startBlockTags, 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];

  const openPattern = new RegExp(`<(\/?)(${startBlockTags.join('|')})[^>]*?>`, 'gi'); //eslint-disable-line
  const closePattern = new RegExp(`<\/(${endBlockTags.join('|')})>`, 'gi'); //eslint-disable-line

  // Replace matched opening tags with tag + line break
  let modifiedHTML = innerHtml.replace(openPattern, (match: string) => {
    return match + '\n';
  });

  // Replace matched closing tags with line g
  modifiedHTML = modifiedHTML.replace(closePattern, (match: string) => {
    return '\n' + match;
  });

  modifiedHTML = modifiedHTML.replace(/<[^>]+>/g, '');
  return modifiedHTML;
};
