import React, { FC, ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider as BaseAuth0Provider, AppState } from '@auth0/auth0-react';
import { useHostInfo } from '../../../lib/use-host';
import LoadingPage from '../../pages/App/Loading';

const createRedirectCallback = (history: any) => (appState: AppState) => {
  history.replace({
    pathname: appState && appState.returnTo ? appState.returnTo : '/',
    search: '',
  });
};

interface Auth0ProviderProps {
  children?: ReactNode;
}
const Auth0Provider: FC<Auth0ProviderProps> = ({ children }) => {
  const hostInfo = useHostInfo();
  const history = useHistory();
  const onRedirectCallback = useMemo(() => createRedirectCallback(history), [history]);

  return !hostInfo ? (
    <LoadingPage />
  ) : (
    <BaseAuth0Provider
      domain={hostInfo?.auth0?.domain!}
      clientId={hostInfo?.auth0?.clientId!}
      audience={hostInfo?.auth0?.audience!}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </BaseAuth0Provider>
  );
};

export default Auth0Provider;
