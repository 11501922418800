import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { isCompanyExpired } from '../../../utils/payment';
import { CompanyStatuses, User } from '../../../generated/graphql';
import WarningBar from '../../atoms/WarningBar';
import { MMMdoEEE } from '../../../utils/date-fns';
import { useTranslation } from 'react-i18next';
import url from '../../../constants/url';
import { hasOwnerRole } from '../../../utils/role';

interface ExpiredBarProps {
  expiredAt: Date;
  status: CompanyStatuses;
  user: User;
}

const ExpiredBar: FC<ExpiredBarProps> = ({ expiredAt, status, user }) => {
  const { t } = useTranslation();
  const isExpired = isCompanyExpired(expiredAt, status);
  const isOwner = hasOwnerRole(user);
  return (
    <WarningBar
      url={isOwner ? url.settings.company.billing.index : undefined}
      variant={isExpired ? 'warn' : 'default'}
    >
      {isExpired ? (
        <>
          {/* @ts-expect-error */}
          <Trans
            defaults="{{date}}で無料トライアルが終了いたしました。"
            values={{
              date: MMMdoEEE(expiredAt),
            }}
            components={[<span />]}
          />
        </>
      ) : (
        <>
          {/* @ts-expect-error */}
          <Trans
            defaults="{{date}}での無料トライアルが終了いたします。"
            values={{
              date: MMMdoEEE(expiredAt),
            }}
            components={[<span />]}
          />
        </>
      )}

      {isOwner ? (
        <span>
          {/* @ts-expect-error */}
          <Trans
            defaults="継続してご利用いただくには、「<0>有料プランのお申込み</0>」へお進みください。"
            components={[<em />]}
          />
        </span>
      ) : (
        <span>{t('管理者に連絡して有料プランのお申込み手続きを完了してください。')}</span>
      )}
    </WarningBar>
  );
};

export default ExpiredBar;
