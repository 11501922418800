import { useContext, FC, lazy } from 'react';
import {
  SideSheetActionContext,
  SideSheetActionType,
} from '../components/contexts/SideSheetContext';
import { buildPathAndQuery } from '../utils/url';
import { useRouter } from './use-router';

export enum SideSheetKey {
  CsvFilesDownload = 'CsvFilesDownload',
  HeaderSupport = 'HeaderSupport',
  KeyMetricDetail = 'KeyMetricDetail',
  KeyResultDetail = 'KeyResultDetail',
  Member = 'Member',
  Notification = 'Notification',
  ObjectiveDetail = 'ObjectiveDetail',
  Team = 'Team',
}

export const getSideSheetsMap: () => Record<SideSheetKey, () => any> = () => ({
  [SideSheetKey.CsvFilesDownload]: () =>
    lazy(() => import('../components/organisms/_sidesheets/CsvFilesDownload')),
  [SideSheetKey.HeaderSupport]: () =>
    lazy(() => import('../components/organisms/_sidesheets/HeaderSupport')),
  [SideSheetKey.KeyMetricDetail]: () =>
    lazy(() => import('../components/organisms/_sidesheets/KeyMetricDetail')),
  [SideSheetKey.KeyResultDetail]: () =>
    lazy(() => import('../components/organisms/_sidesheets/KeyResultDetail')),
  [SideSheetKey.Member]: () => lazy(() => import('../components/organisms/_sidesheets/Member')),
  [SideSheetKey.Notification]: () =>
    lazy(() => import('../components/organisms/_sidesheets/Notification')),
  [SideSheetKey.ObjectiveDetail]: () =>
    lazy(() => import('../components/organisms/_sidesheets/ObjectiveDetail')),
  [SideSheetKey.Team]: () => lazy(() => import('../components/organisms/_sidesheets/Team')),
});

const ALLOWED_QUERY_SIDESHEETS: SideSheetKey[] = [
  SideSheetKey.ObjectiveDetail,
  SideSheetKey.KeyResultDetail,
  SideSheetKey.KeyMetricDetail,
  SideSheetKey.Member,
  SideSheetKey.Team,
];
export const SIDESHEET_UUID: Record<any, string> = {
  [SideSheetKey.ObjectiveDetail]: 'ODSS',
  [SideSheetKey.KeyResultDetail]: 'KRDSS',
  [SideSheetKey.KeyMetricDetail]: 'KMDSS',
  [SideSheetKey.Member]: 'MSS',
  [SideSheetKey.Team]: 'TSS',
};

export const findDescriptedComponentName = (enc: string): string | undefined => {
  return Object.keys(SIDESHEET_UUID).find(key => {
    if (SIDESHEET_UUID[key] === enc) {
      return true;
    }
    return false;
  });
};

const _getPropsByComponentName = (name: SideSheetKey, options: Record<string, any>) => {
  if (!name || !ALLOWED_QUERY_SIDESHEETS.includes(name)) return {};

  switch (name) {
    case SideSheetKey.ObjectiveDetail:
      return {
        c: SIDESHEET_UUID[name],
        p: {
          id: options.objectiveId!,
        },
      };
    case SideSheetKey.KeyResultDetail:
      return {
        c: SIDESHEET_UUID[name],
        p: {
          id: options.keyResultId!,
        },
      };
    case SideSheetKey.KeyMetricDetail:
      return {
        c: SIDESHEET_UUID[name],
        p: {
          id: options.keyMetricId!,
        },
      };
    case SideSheetKey.Member:
      return {
        c: SIDESHEET_UUID[name],
        p: {
          id: options.memberId!,
        },
      };
    case SideSheetKey.Team:
      return {
        c: SIDESHEET_UUID[name],
        p: {
          id: options.teamId!,
        },
      };
  }

  return {};
};

export const getRouterQueryForSideSheet = (
  pathname: string,
  router: Record<string, any>,
  componentName: SideSheetKey | undefined,
  options: any,
): string => {
  if (!componentName || !ALLOWED_QUERY_SIDESHEETS.includes(componentName)) {
    return '';
  }
  const { location, query } = router;

  const modalEncryptedKey = window.btoa(
    JSON.stringify(_getPropsByComponentName(componentName, options)),
  );

  const updating = {
    ...query,
    sd: modalEncryptedKey,
  };
  return buildPathAndQuery({
    location: {
      ...location,
      pathname,
    },
    updating,
  });
};

const _setRouterQueryForSideSheet = <T>(
  router: Record<string, any>,
  componentName: SideSheetKey | undefined,
  options: any,
) => {
  if (!componentName || !ALLOWED_QUERY_SIDESHEETS.includes(componentName)) {
    return;
  }
  const { push } = router;

  push(getRouterQueryForSideSheet(router.pathname, router, componentName, options));
};

interface UnsetRouterQueryForSideSheetOptions {
  nextLocationPathname?: string;
}

const _unsetRouterQueryForSideSheet = (
  router: Record<string, any>,
  options?: UnsetRouterQueryForSideSheetOptions | undefined,
) => {
  const { location, push } = router;

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has('sd')) {
    push(
      buildPathAndQuery({
        location: {
          ...location,
          pathname: options?.nextLocationPathname || location.pathname,
        },
        removing: ['sd', 'w'],
      }),
    );
  }
};

interface UseCloseSideSheetOptions {
  nextLocationPathname?: string;
}
export function useCloseSideSheet(sideSheetOptions?: {
  disableQuery?: boolean;
}): (options?: UseCloseSideSheetOptions) => void {
  const router = useRouter();
  const { closeSideSheet } = useContext(SideSheetActionContext);
  return (options?: UseCloseSideSheetOptions) => {
    if (!sideSheetOptions?.disableQuery) {
      _unsetRouterQueryForSideSheet(router, options);
    }

    closeSideSheet();
  };
}

export function useOpenSideSheet<T>(
  sideSheetType: FC<T> | SideSheetKey,
  sideSheetOptions?: {
    disableQuery?: boolean;
  },
): (options?: T) => void {
  const router = useRouter();
  const { openSideSheet } = useContext(SideSheetActionContext);
  const closeSideSheet = useCloseSideSheet();

  const sideSheetsMap = getSideSheetsMap();

  return (options?: T) => {
    const componentName =
      typeof sideSheetType === 'string' ? sideSheetType : sideSheetType.displayName;
    if (
      sideSheetOptions?.disableQuery ||
      !componentName ||
      !ALLOWED_QUERY_SIDESHEETS.includes(componentName as SideSheetKey)
    ) {
      _unsetRouterQueryForSideSheet(router);
    }

    const _getComponentFromSideSheetType = <T = any>(sideSheetType: FC<T> | SideSheetKey) => {
      if (typeof sideSheetType === 'string') {
        if (sideSheetType in sideSheetsMap) {
          return sideSheetsMap[sideSheetType]();
        }
        return null;
      }
      return sideSheetType;
    };

    openSideSheet(_getComponentFromSideSheetType(sideSheetType), {
      onClose: closeSideSheet,
      ...(options as T),
    });

    if (!sideSheetOptions?.disableQuery) {
      _setRouterQueryForSideSheet(router, componentName as SideSheetKey, options);
    }
  };
}

export function useSwitchSideSheet<T>(
  sideSheetType: FC<T> | SideSheetKey,
  sideSheetOptions?: {
    disableQuery?: boolean;
  },
): (options?: T) => void {
  const router = useRouter();
  const { switchSideSheet } = useContext(SideSheetActionContext);
  const closeSideSheet = useCloseSideSheet();

  const sideSheetsMap = getSideSheetsMap();

  return (options?: T) => {
    const componentName =
      typeof sideSheetType === 'string' ? sideSheetType : sideSheetType.displayName;
    if (
      sideSheetOptions?.disableQuery ||
      !componentName ||
      !ALLOWED_QUERY_SIDESHEETS.includes(componentName as SideSheetKey)
    ) {
      _unsetRouterQueryForSideSheet(router);
    }

    const _getComponentFromSideSheetType = <T = any>(sideSheetType: FC<T> | SideSheetKey) => {
      if (typeof sideSheetType === 'string') {
        if (sideSheetType in sideSheetsMap) {
          return sideSheetsMap[sideSheetType]();
        }
        return null;
      }
      return sideSheetType;
    };

    switchSideSheet(_getComponentFromSideSheetType(sideSheetType), {
      onClose: closeSideSheet,
      ...(options as T),
    });

    if (!sideSheetOptions?.disableQuery) {
      _setRouterQueryForSideSheet(router, componentName as SideSheetKey, options);
    }
  };
}

export function useGetLastAction() {
  const { lastAction } = useContext(SideSheetActionContext);
  return (): SideSheetActionType | undefined | null => {
    return lastAction;
  };
}
