import React, { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, icons } from 're-design';
import { useApolloClient } from '@apollo/client';

import AppLogo from '../../atoms/AppLogo';
import HeadTag from '../../atoms/HeadTag';
import {
  Layout,
  LayoutBody,
  LayoutInner,
  LayoutTopbar,
  LayoutTopbarContent,
} from '../../molecules/FullscreenModal/styled';

import {
  Body,
  Wrap,
  Content,
  HorizontalRule,
  ErrorMessageBlock,
  ErrorMessageBlockTriggerArrow,
} from './styled';

import { resetToken } from '../../../lib/auth-token';
import Url from '../../../constants/url';
import sentry from '../../../utils/sentry';
import { useTheme } from '../../../lib/use-theme';
import Typo from '../../quarks/Typo';
import Box from '../../quarks/Box';
import Heading from '../../quarks/Heading';
import AngleArrowRightIcon from '../../quarks/icons/AngleArrowRight';

const Error404Topbar = () => (
  <LayoutTopbar>
    <LayoutTopbarContent>
      {/* @ts-expect-error */}
      <Link to={Url.index}>
        <AppLogo />
      </Link>
    </LayoutTopbarContent>
  </LayoutTopbar>
);

type ErrorBoundaryProps = {
  error: Error;
};

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ error }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const theme = useTheme();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleToggleErrorMessage = useCallback(() => {
    setShowErrorMessage(!showErrorMessage);
  }, [showErrorMessage, setShowErrorMessage]);

  useEffect(() => {
    if (error) {
      sentry.error(error.message, null, error);
    }
  }, [error]);

  const handleRefresh = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      resetToken();
      client.clearStore();
      document.location.reload();
    },
    [client],
  );

  return (
    <Layout variant="style-01">
      <HeadTag title={t('ページの読み込みに失敗しました')} />
      <LayoutInner>
        <Error404Topbar />
        <LayoutBody>
          <Body>
            <Wrap>
              <Content>
                <Box between="small" align="center">
                  <icons.InfoIcon width={64} height={64} fill={theme['disabled']} />
                </Box>

                <Box between="small">
                  <Heading
                    variant="h1"
                    color="text-02"
                    align="center"
                  >
                    {t('ページの読み込みに失敗しました')}
                  </Heading>
                </Box>

                <Box between="small">
                  <Typo variant="body-02" color="text-02">
                    {t('ご迷惑をおかけしております。大変申し訳ありません。')}
                  </Typo>
                  <Typo variant="body-02" color="text-02">
                    {t('画面表示でエラーが発生し、ページの読み込みに失敗しました。')}
                  </Typo>
                  <Typo variant="body-02" color="text-02">
                    {t(
                      '画面を更新してもう一度ログインしていただくことで、この問題が解決する場合があります。',
                    )}
                  </Typo>
                </Box>

                <Box>
                  <Button
                    type="button"
                    variant="light"
                    icon={<icons.RefreshIcon />}
                    onClick={handleRefresh}
                  >
                    {t('画面を更新してもう一度ログイン')}
                  </Button>
                </Box>

                <Box>
                  <Typo variant="body-02" color="text-02">
                    {t(
                      '改善されない場合は、右下のチャットからWistantサポートチームにお問い合わせください。',
                    )}
                  </Typo>
                  <Typo variant="body-02" color="text-02">
                    {t('Wistantサポートチーム')}：support@wistant.com
                  </Typo>
                </Box>

                <HorizontalRule />

                <Box>
                  <Button variant="transparent" onClick={handleToggleErrorMessage}>
                    <Heading
                      variant="h2"
                      color="text-02"
                      align="center"
                    >
                      {t('エラーの詳細を表示')}
                      <ErrorMessageBlockTriggerArrow isOpen={showErrorMessage}>
                        <AngleArrowRightIcon />
                      </ErrorMessageBlockTriggerArrow>
                    </Heading>
                  </Button>

                  <ErrorMessageBlock isOpen={showErrorMessage}>
                    <Typo variant="body-02" color="text-02" pre>
                      {error.stack}
                    </Typo>
                  </ErrorMessageBlock>
                </Box>
              </Content>
            </Wrap>
          </Body>
        </LayoutBody>
      </LayoutInner>
    </Layout>
  );
};

type ErrorBoundaryContainerProps = {};

type ErrorBoundaryState = {
  error: Error | null;
  hasError: boolean;
};

class ErrorBoundaryContainer extends React.Component<
  ErrorBoundaryContainerProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { error, hasError: true };
  }

  render() {
    const { error, hasError } = this.state;
    if (!hasError) return this.props.children;

    return <ErrorBoundary error={error!} />;
  }
}

export default ErrorBoundaryContainer;
