import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';

import { generateHead } from '../../../utils/helmet';

interface HeadTagProps {
  title: string;
  url?: string;
  notiCount?: number;
}
const HeadTag: FC<HeadTagProps> = memo(({ title, url, notiCount }) => {
  const _title = notiCount ? `(${notiCount}) ${title}` : title;

  return <Helmet {...generateHead({ title: _title, url })} />;
});

export default HeadTag;
