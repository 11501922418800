import React, { createContext, FC, useCallback } from 'react';
import {
  User,
  GetMeQuery,
  GetMeQueryVariables,
  GetMe,
  UserStatuses,
  PlanTypes,
  GetMeCompany,
  GetMeCompanyQueryVariables,
  GetMeCompanyQuery,
  GetMeTeamsQuery,
  GetMeTeams,
  GetMeTeamsQueryVariables,
  Team,
  GetMeCheckIn,
  GetMeCheckInQuery,
  GetMeCheckInQueryVariables,
  GlobalNavigation,
  Maybe,
} from '../../../generated/graphql';
import { useAuth0 } from '@auth0/auth0-react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useRouter } from '../../../lib/use-router';
import url from '../../../constants/url';
import LoadingPage from '../../pages/App/Loading';
import hubspot from '../../../utils/hubspot';
import { takeAwayMaybeElement } from '../../../utils/array';
import { setTokenCookie, removeTokenCookie } from '../../../lib/auth-token';

type Me = null | User;

type AuthContext = {
  me: Me | undefined;
  navigation: Maybe<GlobalNavigation> | undefined;
  companyPlanType: PlanTypes;
  features: {
    withObjective: boolean;
    withFeedback: boolean;
    withO3s: boolean;
    withGroupTag: boolean;
    withCheckIn: boolean;
    withPersonalSheet: boolean;
    withObjectiveSheet: boolean;
    withSupportAction: boolean;
  };
  refetchMe: () => Promise<Me>;
  logout: () => void;
  loading: boolean;
};

type Auth0User = {
  name: string;
  email: string;
  picture: string;
  token: object;
};

export const AuthContext = createContext<AuthContext>({} as AuthContext);
export const AuthProvider: FC<{
  redirect?: boolean;
  user: Auth0User;
  isLoading: boolean;
  isAuthenticated: boolean;
}> = ({ redirect = false, children, isAuthenticated }) => {
  const { logout: auth0Logout } = useAuth0();
  const { push } = useRouter();
  const client = useApolloClient();

  const { data: dataCheckIn } = useQuery<GetMeCheckInQuery, GetMeCheckInQueryVariables>(
    GetMeCheckIn,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const { loading: loadingTeams, data: dataTeams } = useQuery<
    GetMeTeamsQuery,
    GetMeTeamsQueryVariables
  >(GetMeTeams, {
    fetchPolicy: 'cache-first',
  });

  const { data: dataCompany, loading: loadingCompany } = useQuery<
    GetMeCompanyQuery,
    GetMeCompanyQueryVariables
  >(GetMeCompany, {
    fetchPolicy: 'cache-first',
  });

  const { data, refetch, loading: loadingMe } = useQuery<GetMeQuery, GetMeQueryVariables>(GetMe, {
    onCompleted: data => {
      if (data.getMe?.user?.status === UserStatuses.Invited) {
        push(url.register.index);
      }
      if (data?.getMe?.token) {
        setTokenCookie(data?.getMe?.token);
      }
    },
    onError: error => {
      console.error(error);
      if (redirect) {
        if (isAuthenticated) {
          auth0Logout();
        } else {
          removeTokenCookie();
          push(url.login.index);
        }
      }
    },
    fetchPolicy: 'network-only',
  });

  const getMe = data?.getMe;
  const auth = getMe?.user;
  const navigation = auth?.navigation;
  const company = dataCompany?.company;
  const checkIn = dataCheckIn?.checkIn;

  const refetchMe = useCallback(async () => {
    const { data } = await refetch();
    return data.getMe?.user;
  }, [refetch]);

  const logout = useCallback(() => {
    client.clearStore();
    hubspot.remove();
    removeTokenCookie();

    try {
      if (isAuthenticated) auth0Logout({ returnTo: `${window.location.origin}/login` });
      client.cache.reset();

      process.nextTick(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
    } catch (e) {
      console.log('warn', e)
    }
  }, [client, auth0Logout, isAuthenticated]);

  const loading = loadingMe || loadingCompany || loadingTeams;
  const companyPlanType = company?.planType || PlanTypes.Full;

  const value = {
    me: {
      ...auth,
      company,
      teams: takeAwayMaybeElement<Team[]>(dataTeams?.teams),
      checkIn,
      navigation,
    },
    navigation,
    company,
    companyPlanType,
    features: {
      withObjective: companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
      withPersonalSheet:
        companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
      withObjectiveSheet:
        companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
      withFeedback: companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
      withO3s:
        companyPlanType === PlanTypes.Ppo3 ||
        companyPlanType === PlanTypes.Standard ||
        companyPlanType === PlanTypes.Full,
      withGroupTag: companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
      withCheckIn: companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
      withSupportAction:
        companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full,
    },
    refetchMe,
    logout,
    loading,
  };

  return (
    <AuthContext.Provider value={value as AuthContext}>
      {loading || !data ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
};
