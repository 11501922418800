import styled, { css } from 'styled-components';
import { propGet, Tabs } from 're-design';
import { ReactNode } from 'react';
import { TextAlign } from '../../quarks/Typo';

interface SideSheetFooterProps {
  sticky?: boolean;
  align?: TextAlign;
  size?: string;
  minHeight?: string;
}

export const BaseSideSheetFooter = styled.footer`
  padding: var(--spacing-5);
  border-top: 1px solid var(--border-01);

  ${(props: SideSheetFooterProps) =>
    props.sticky &&
    css`
      justify-self: flex-end;
      background: var(--ui-01);
      padding: var(--spacing-5);
      border-top: 1px solid var(--border-01);
      width: 100%;
      z-index: 100;
    `}

  ${(props: SideSheetFooterProps) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${(props: SideSheetFooterProps) =>
    !!props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}

  ${(props: SideSheetFooterProps) =>
    !!props.size &&
    props.size === 'tiny' &&
    css`
      @media ${propGet('breakpoint.max.md')} {
        padding: var(--spacing-5) var(--spacing-4) var(--spacing-3) var(--spacing-2);
      }
    `}
`;

interface BaseSideSheetBodyProps {
  noPadding?: boolean;
  variant?: string;
}
export const BaseSideSheetBody = styled.div`
  padding: var(--spacing-8) var(--spacing-7);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  flex-grow: 1;

  ${(props: BaseSideSheetBodyProps) =>
    props.noPadding &&
    css`
      padding: 0;
    `}

  @media ${propGet('breakpoint.max.md')} {
    padding: var(--spacing-7) var(--spacing-5);
    ${(props: BaseSideSheetBodyProps) =>
      props.noPadding &&
      css`
        padding: 0;
      `}
  }

  ${(props: BaseSideSheetBodyProps) =>
    props.variant === 'gray' &&
    css`
      background: var(--ui-background-01);
    `}
`;

export const BaseSideSheetHeaderInnerActionsItem = styled.div`
  position: relative;

  & + & {
    margin-left: 8px;
    :before {
      content: '';
      height: 24px;
      top: 50%;
      left: -4px;
      transform: translateY(-50%);
      background: var(--border-01);
      width: 1px;
      position: absolute;
    }
  }
`;

export const BaseSideSheetHeaderInnerActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-2-inverse);
`;

export const BaseSideSheetHeaderInnerHeadingMeta = styled.span`
  margin-top: var(--spacing-3);
`;

export const BaseSideSheetHeaderInnerHeading = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow: hidden;
`;

export const BaseSideSheetHeaderInnerBack = styled.div`
  margin-left: var(--spacing-4-inverse);
`;

export const BaseSideSheetHeaderInnerIcon = styled.div`
  display: flex;
  margin-right: var(--spacing-4);
  align-items: center;
`;

export const BaseSideSheetHeaderInner = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

interface BaseSideSheetHeaderProps {
  sticky?: boolean;
}

export const BaseSideSheetHeader = styled.header`
  padding: var(--spacing-5);
  border-bottom: 1px solid var(--border-01);
  height: 72px;
  min-height: 72px;
  z-index: 2;

  ${(props: BaseSideSheetHeaderProps) =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
      background: var(--ui-01);
    `}

  nav {
    margin-bottom: var(--spacing-3) !important;
  }
`;

interface TabItem {
  key: string;
  label: string | ReactNode;
}

export const BaseSideSheetTabs = styled(Tabs)`
  padding-top: var(--spacing-5);
  @media ${propGet('breakpoint.max.md')} {
    padding-top: 0;
  }
`;
