import { FC } from 'react';
import { CommonIconProps, defaultPathClassName } from './utils';

const AngleArrowLeftIcon: FC<CommonIconProps> = props => {
  const { size = '100%', className, pathClassName = defaultPathClassName } = props;
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        className={pathClassName}
        d="M14.2418 6L15 6.8436L10.494 12.0078L10.9745 12.5586L10.9718 12.5556L14.9755 17.1438L14.2287 18C13.122 16.7322 10.0325 13.1916 9 12.0078C9.76691 11.1282 9.01909 11.9856 14.2418 6Z"
      />
    </svg>
  );
};

export default AngleArrowLeftIcon;