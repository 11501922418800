import styled, { css } from 'styled-components';
import { propGet } from 're-design';
import { WarningBarProps } from './types';
import { Link } from 'react-router-dom';

export const WarningBarWrapper = styled.div`
  width: 100%;
  height: 44px;
  position: sticky;
  color: var(--inverse-interactive);
  top: 0;
  left: 0;
  z-index: 50;
  color: var(--inverse-interactive);
  background-color: ${(props: WarningBarProps) =>
    props.variant === 'default' ? 'var(--interactive-03)' : 'var(--interactive-05)'};
`;
export const WarningBarInner = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--spacing-3);
`;

const textCss = css`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  text-overflow: ellipsis;
  color: var(--inverse-interactive);

  em {
    color: var(--inverse-interactive);
    font-style: normal;
    text-decoration: underline;
  }

  span {
    color: var(--inverse-interactive);

    @media ${propGet('breakpoint.max.sm')} {
      display: none;
    }
  }
`;

export const WarningBarMessage = styled.div`
  ${textCss}
`;

export const WarningBarLink = styled(Link)`
  ${textCss}
`;
