import { FC, memo, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Between } from './types';

export type FlexAlignItems = "start" | "center" | "end";
export type FlexBetween = Between;
export type FlexJustifyContent = "start" | "center" | "end";
export type FlexDirection = "row" | "column";


interface FlexProps {
  spaced?: boolean;
  alignItems?: FlexAlignItems;
  grow?: boolean;
  inline?: boolean;
  between?: FlexBetween;
  justifyContent?: FlexJustifyContent;
  direction?: FlexDirection;
  wrap?: boolean;
  relative?: boolean;
  className?: string;
  children?: ReactNode;
}

const flexAlignBetweenMap: Record<FlexBetween, string> = {
  "none": 'gap-0',
  "min": 'gap-2',
  "small": 'gap-3',
  "normal": 'gap-4',
  "medium": 'gap-4',
  "large": 'gap-5',
};

const justifyContentMap = {
  "center": 'justify-center',
  "end": 'justify-end',
  "start": 'justify-start',
};

const directionMap = {
  "column": 'flex-col',
  "row": 'flex-row',
};

const Flex: FC<FlexProps> = memo(
  ({
    children,
    inline,
    spaced = false,
    wrap = false,
    relative = false,
    alignItems = "center",
    grow = false,
    between = "normal",
    justifyContent,
    direction,
    className,
  }) => {
    const classNames = [inline ? 'inline-flex' : 'flex'];
    if (spaced) {
      classNames.push('justify-between');
    }
    if (alignItems) {
      switch(alignItems) {
        case "start":
          classNames.push(`items-start`);
          break;
        case "center":
          classNames.push(`items-center`);
          break;
        case "end":
          classNames.push(`items-end`);
          break;
      }
    }
    if (grow) {
      classNames.push('grow');
    }
    if (relative) {
      classNames.push('relative');
    }
    if (wrap) {
      classNames.push('flex-wrap');
    }
    if (between) {
      classNames.push(flexAlignBetweenMap[between]);
    }
    if (justifyContent) {
      classNames.push(justifyContentMap[justifyContent]);
    }
    if (direction) {
      classNames.push(directionMap[direction]);
    }

    return <div className={twMerge(classNames, className)}>{children}</div>;
  },
);

export default Flex;
