import React, { memo, FC } from 'react';
import {
  isCompanyExpired,
  isCompanyExpireSoon,
  isCompanyUnpaid,
  isCompanySuspended,
} from '../../../utils/payment';
import { CompanyStatuses, CompanySubscription } from '../../../generated/graphql';
import ExpiredBar from './ExpiredBar';
import SuspendedBar from './SuspendedBar';
import UnpaidBar from './UnpaidBar';
import { useMe } from '../../../lib/use-me';
import { useCompany } from '../../../lib/use-me-company';

interface InvalidCompanyStatusBarProps {}

const InvalidCompanyStatusBar: FC<InvalidCompanyStatusBarProps> = memo(() => {
  const me = useMe();
  const company = useCompany();
  const status = company?.status as CompanyStatuses;
  const expiredAt = company?.expiredAt;
  const subscription = company?.subscription as CompanySubscription;

  if (isCompanyExpired(expiredAt!, status) || isCompanyExpireSoon(expiredAt!, status)) {
    return <ExpiredBar user={me!} expiredAt={expiredAt} status={status} />;
  }

  if (isCompanyUnpaid(status)) {
    return <UnpaidBar user={me!} subscription={subscription} />;
  }

  if (isCompanySuspended(status)) {
    return <SuspendedBar user={me!} subscription={subscription} />;
  }
  return null;
});

export default InvalidCompanyStatusBar;
