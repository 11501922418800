import { useState } from "react";
import { hasElement } from "../utils/array";

export interface UseTutorialPropsStep {
    title: string;
    body: string;
    image?: string | null;
}

export interface UseTutorialProps {
    steps: UseTutorialPropsStep[]
}

interface UseTutorialReturn {
    step: number;
    setStep(s: number): void;
    stepContent: UseTutorialPropsStep | undefined;
    hasPreviousStep(): boolean;
    hasNextStep(): boolean;
    nextStep(): void;
    previewStep(): void;
    isEnd(): boolean;
    hasMultipleSteps: boolean;
    isEmpty: boolean;
}
const useTutorial = ({
    steps: _steps = []
}: UseTutorialProps): UseTutorialReturn => {

    const steps = _steps || [];
    const [step, setStep] = useState(1);
    const stepIndex = step-1;

    const stepContent = steps[stepIndex]

    const hasNextStep = () => step+1 <= steps.length;
    const hasPreviousStep = () => step-1 >= 1;
    const isEnd = () => step === steps.length;

    const nextStep = () => {
        if(hasNextStep()) {
            setStep(step+1);
        }
    }
    const previewStep = () => {
        if(hasPreviousStep()) {
            setStep(step-1);
        }
    }

    return {
        step,
        setStep,
        stepContent,
        hasPreviousStep,
        hasNextStep,
        nextStep,
        previewStep,
        isEnd,
        hasMultipleSteps: steps.length > 1,
        isEmpty: !hasElement(steps)
    }
}

export default useTutorial;