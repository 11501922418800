import React, { memo, FC, ReactNode } from 'react';
import PageTutorial from '../../molecules/PageTutorial';

interface DefaultPageBodyProps {
  children?: ReactNode;
}
const DefaultPageBody: FC<DefaultPageBodyProps> = memo(({ children }) => {
  return (
    <>
      <PageTutorial />
      <div className="px-5 sm:px-7 md:px-10 pt-8 pb-[90px]">{children}</div>
    </>
  );
});

export default DefaultPageBody;
