import { ReactNode, useState, useCallback, FC } from 'react';
import { FullscreenTopbar } from '../../layouts/FullscreenLayout';
import pkg from '../../../../package.json';
import { getItem, setItem } from '../../../lib/local-storage';
import {
  SheetTemplateSideBar,
  SheetTemplateSideBarBody,
  SheetTemplateSideBarToggleButton,
  SheetTemplateInnerWrapper,
  SheetTemplateSideBarLink,
  SheetTemplateSideBarLinkLabel,
  SheetTemplateSideBarLinkIcon,
  SheetTemplateWrapper,
  SheetTemplateBody,
  SheetTemplateSidebarActions,
  AlertWrap,
} from './styled';
import { useRouter } from '../../../lib/use-router';
import { LinkOption } from './types';
import AlertBadge from '../../atoms/_icons/Alert';
import { useTheme } from '../../../lib/use-theme';
import { hasElement } from '../../../utils/array';
import AngleArrowLeftIcon from '../../quarks/icons/AngleArrowLeft';
import AngleArrowRightIcon from '../../quarks/icons/AngleArrowRight';

interface SheetTemplateProps {
  linkOptions?: LinkOption[];
  topBarInfo?: ReactNode;
  topBarActions?: ReactNode;
  sidebarActions?: ReactNode;
  closeLabel?: string | ReactNode;
  closeIcon?: ReactNode;
  variant?:
    | 'feedback-overview'
    | 'objective-form'
    | 'personalSheet'
    | 'personalSheet-overview'
    | null;
  onClose?: () => void;
  getCurrent?: (option: LinkOption) => boolean;
  zIndex?: number;
  useSidebarToggle?: boolean;
  useSidebarToggleNameSuffix?: string;
  children?: ReactNode;
}

const SheetTemplate: FC<SheetTemplateProps> = ({
  children,
  linkOptions,
  topBarInfo,
  topBarActions,
  sidebarActions,
  onClose,
  closeLabel,
  variant,
  getCurrent,
  closeIcon,
  zIndex,
  useSidebarToggle = false,
  useSidebarToggleNameSuffix = '',
}) => {
  const theme = useTheme();
  const { pathname } = useRouter();
  const storageName =
    'sheet-template-sidebar-' +
    pkg.version +
    (useSidebarToggleNameSuffix ? `-${useSidebarToggleNameSuffix}` : '');

  const storageOpen = useSidebarToggle ? getItem(storageName) : true;

  const [open, setOpen] = useState(
    storageOpen === undefined
      ? true
      : storageOpen === 'true' || storageOpen === true
      ? true
      : false,
  );

  const sidebarToggle = useCallback(
    o => {
      if (!useSidebarToggleNameSuffix) return;
      setOpen(o);
      setItem(storageName, o);
    },
    [useSidebarToggleNameSuffix, setOpen, storageName],
  );

  const _closeIcon = <AngleArrowLeftIcon size="24px" pathClassName="text-base-02 dark:text-base-02-dark fill-current" />
  const _openIcon = <AngleArrowRightIcon size="24px" pathClassName="text-base-02 dark:text-base-02-dark fill-current" />
  const sidebarToggleButtonIcon = (open: boolean) => (open ? _closeIcon : _openIcon);

  return (
    <SheetTemplateWrapper zIndex={zIndex} variant={variant}>
      <FullscreenTopbar
        onClose={onClose}
        closeIcon={closeIcon}
        closeLabel={closeLabel}
        info={topBarInfo}
        actions={topBarActions}
      />
      <SheetTemplateInnerWrapper>
        {hasElement(linkOptions) && (
          <>
            <SheetTemplateSideBar isOpen={open}>
              <SheetTemplateSideBarBody>
                {linkOptions?.map(option => {
                  const current = getCurrent ? getCurrent(option) : option.value === pathname;
                  return (
                    <SheetTemplateSideBarLink
                      to={option.value}
                      key={option.value}
                      current={current ? 1 : 0}
                    >
                      {option.icon && (
                        <SheetTemplateSideBarLinkIcon>{option.icon}</SheetTemplateSideBarLinkIcon>
                      )}
                      <SheetTemplateSideBarLinkLabel>{option.label}</SheetTemplateSideBarLinkLabel>
                      {option.alert && (
                        <AlertWrap>
                          <AlertBadge />
                        </AlertWrap>
                      )}
                    </SheetTemplateSideBarLink>
                  );
                })}
              </SheetTemplateSideBarBody>

              {useSidebarToggle && (
                <SheetTemplateSideBarToggleButton
                  isOpen={open}
                  onClick={() => sidebarToggle(!open)}
                >
                  {sidebarToggleButtonIcon(open)}
                </SheetTemplateSideBarToggleButton>
              )}
            </SheetTemplateSideBar>
            {useSidebarToggle && (
              <SheetTemplateSideBarToggleButton isOpen={open} onClick={() => sidebarToggle(!open)}>
                {sidebarToggleButtonIcon(open)}
              </SheetTemplateSideBarToggleButton>
            )}
          </>
        )}
        <SheetTemplateBody
          hasSidebarActions={!!sidebarActions}
          isOpenSidebar={open}
          hasLinkOptions={hasElement(linkOptions)}
        >
          {children}
        </SheetTemplateBody>

        {!!sidebarActions && (
          <SheetTemplateSidebarActions>{sidebarActions}</SheetTemplateSidebarActions>
        )}
      </SheetTemplateInnerWrapper>
    </SheetTemplateWrapper>
  );
};

export default SheetTemplate;
