import { takeAwayMaybeElement, takeAwayEmptyElement, hasElement } from '../../../../utils/array';
import { DomainEntityInput } from '../../../../generated/graphql';

export const getEntitiesFiltersFromQuery = (
  query: any,
  includeCustomKey: string | string[] | null = null,
): Record<string, string[] | undefined> => {
  const teamId = query.teamId
    ? takeAwayMaybeElement<string[]>(query.teamId?.split(','))
    : undefined;
  const groupTagId = query.groupTagId
    ? takeAwayMaybeElement<string[]>(query.groupTagId?.split(','))
    : undefined;
  const memberIds = query.userId
    ? takeAwayMaybeElement<string[]>(query.userId?.split(','))
    : undefined;
  const statuses = query.statuses
    ? takeAwayMaybeElement<string[]>(query.statuses?.split(','))
    : undefined;
  const authorTypes = query.authorTypes
    ? takeAwayMaybeElement<string[]>(query.authorTypes?.split(','))
    : undefined;

  const includeCustomKeys = takeAwayEmptyElement<string[]>(includeCustomKey);
  const includeCustomMap: Record<string, string[] | undefined> = {};
  if (hasElement(includeCustomKeys)) {
    includeCustomKeys.forEach(includeCustomKey => {
      includeCustomMap[includeCustomKey] = query[includeCustomKey]
        ? takeAwayMaybeElement<string[]>(query[includeCustomKey]?.split(','))
        : undefined;
    });
  }

  return {
    memberIds,
    teamId,
    groupTagId,
    statuses,
    authorTypes,
    ...includeCustomMap,
  };
};

export const getDomainEntityInputFromQuery = (query: any) => {
  const entities = getEntitiesFiltersFromQuery(query);

  const domainEntities: DomainEntityInput[] = [];

  entities?.memberIds?.forEach(id => {
    domainEntities.push({
      type: 'user',
      id,
    });
  });

  entities?.teamId?.forEach(id => {
    domainEntities.push({
      type: 'team',
      id,
    });
  });

  entities?.groupTagId?.forEach(id => {
    domainEntities.push({
      type: 'groupTag',
      id,
    });
  });

  return domainEntities;
};
