import styled from 'styled-components';

export const PageError = styled.div`
  text-align: center;
  padding: var(--spacing-7) var(--spacing-5);
`;

export const ErrorBody = styled.div`
  margin-bottom: var(--spacing-6);
`;

export const ErrorFooter = styled.div``;
