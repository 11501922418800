import { useEffect, useContext } from 'react';
import { useRouter } from './use-router';
import { HubspotChatContext } from '../components/contexts/HubspotChatContext';

export const useHideHubspotChat = () => {
  const { location } = useRouter();
  const { setVisibility } = useContext(HubspotChatContext);
  useEffect(() => {
    // setVisibility((prevState: number) => prevState - 1);
    setVisibility(0);
    return () => {
      // setVisibility((prevState: number) => prevState + 1);
      setVisibility(1);
    };
  }, [location.pathname, setVisibility]);
};
