import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { AuthContext } from '../components/contexts/AuthContext';
import { genAuthorities, genPermissions } from '../utils/permission';
import { getActiveTeams } from '../utils/team';
import { useCompanyTeams } from '../lib/use-me-company';
import Dictionary, { FeedbackDictionary, ObjectiveSheetDictionary } from '../constants/dictionary';
import { useGetCurrentLanguage } from './use-language';
import { useRouter } from './use-router';
import { takeAwayMaybeElement } from '../utils/array';
import { getScopedTeamIdsFromTeam } from '../utils/scoped-role';
import { Languages } from '../common/enum';
import {
  Feedback,
  Maybe,
  ObjectiveSheetCustomDictionary,
  ScopedRole,
  Team,
  User,
  GetMeNavigation,
  GetMeNavigationQuery,
  GetMeNavigationQueryVariables,
  GlobalNavigation,
  O3Pair,
  GetMePairs,
  GetMePairsQuery,
  GetMePairsQueryVariables,
} from '../generated/graphql';
import { getEntitiesFiltersFromQuery } from '../components/organisms/_filters/SearchEntitiesFilter/utils';

export const useMe = (): User => {
  const { me } = useContext(AuthContext);
  return me!;
};

export const useRefetchMe = () => {
  const { refetchMe } = useContext(AuthContext);
  return refetchMe!;
};

export const useLogout = () => {
  const { logout } = useContext(AuthContext);
  return logout;
};

export const useMyTeams = () => {
  const { me } = useContext(AuthContext);
  return takeAwayMaybeElement<Team[]>(me?.teams);
};

export const useMyActiveTeams = () => {
  const teams = useMyTeams();
  return getActiveTeams(teams);
};

export const useMePairs = (): {
  loading: boolean;
  mentorPairs: O3Pair[];
  menteePairs: O3Pair[];
} => {
  const { data, loading } = useQuery<GetMePairsQuery, GetMePairsQueryVariables>(
    GetMePairs,
    {
      fetchPolicy: 'cache-first',
    },
  );

  return {
    loading,
    mentorPairs: takeAwayMaybeElement<O3Pair[]>(data?.pairs?.mentorPairs),
    menteePairs: takeAwayMaybeElement<O3Pair[]>(data?.pairs?.menteePairs),
  }
};

export const usePlanType = () => {
  const { companyPlanType } = useContext(AuthContext);
  return companyPlanType;
};

export const useFeatures = () => {
  const { features } = useContext(AuthContext);
  return features;
};

export const useNavigation = (): {
  loading: boolean;
  navigation: Maybe<GlobalNavigation> | null | undefined
} => {
  const me = useMe();
  const { loading, data } = useQuery<GetMeNavigationQuery, GetMeNavigationQueryVariables>(
    GetMeNavigation,
    {
      fetchPolicy: 'cache-first',
    },
  );

  return {
    navigation: data?.navigation ? data?.navigation : me?.navigation,
    loading
  }
};

export const useUserRole = (scopeTeamId: string | null = null) => {
  const me = useMe();
  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(me.scopedRoles);

  const authorities = genAuthorities(me, scopeTeamId);
  const permissions = genPermissions(authorities);
  return {
    me,
    scopedRoles,
    ...authorities,
    permissions,
  };
};

export const useCurrentScopeTeamId = () => {
  const { query } = useRouter();
  const { teamId: teamIds } = getEntitiesFiltersFromQuery(query, ['teamId']);

  const teamId = teamIds?.[0];
  return teamId;
};

export const useCurrentScopeTeamIds = () => {
  const me = useMe();
  const { teams: companyTeams, loading } = useCompanyTeams();
  const scopeTeamId = useCurrentScopeTeamId();
  if (loading || !scopeTeamId) {
    return null;
  }
  return getScopedTeamIdsFromTeam(me, companyTeams, scopeTeamId);
};

export type UseDictionnaryReturn = Record<string, string | number>;

export const useDictionary = () => {
  const me = useMe();
  const getCurrentLanguage = useGetCurrentLanguage();
  const currentLang = getCurrentLanguage();

  const cDictJa: Record<string, string> = {
    ...(me?.company?.dictionary?.words || {}),
    ...(me?.company?.dictionary?.ja || {}),
  };
  const cDictEn: Record<string, string> = me?.company?.dictionary?.en || {};

  return Object.values(Dictionary).reduce((dict, word) => {
    const tWord =
      currentLang === Languages.valueOf('en')
        ? cDictEn[word.key] || word.en
        : cDictJa[word.key] || word.ja;
    Object.assign(dict, { [word.key]: tWord });
    return dict;
  }, {});
};

export const useFeedbackDictionary = (
  feedback?: Feedback | Maybe<Partial<Feedback>> | undefined,
) => {
  const dict = useDictionary();
  const getCurrentLanguage = useGetCurrentLanguage();
  const currentLang = getCurrentLanguage();

  const cDictJa: Record<string, string> = feedback?.dictionary?.ja || {};
  const cDictEn: Record<string, string> = feedback?.dictionary?.en || {};

  const feedbackDict = Object.values(FeedbackDictionary).reduce((dict, word) => {
    const tWord =
      currentLang === Languages.valueOf('en')
        ? cDictEn[word.key] || word.en
        : cDictJa[word.key] || word.ja;
    Object.assign(dict, { [word.key]: tWord });
    return dict;
  }, {});

  return {
    ...dict,
    ...feedbackDict,
  };
};

export const useObjectiveSheetDictionary = (
  dictionary: Maybe<ObjectiveSheetCustomDictionary> | undefined | null,
) => {
  const dict = useDictionary();
  const getCurrentLanguage = useGetCurrentLanguage();
  const currentLang = getCurrentLanguage();

  const cDictJa: Record<string, string> = dictionary?.ja || {};
  const cDictEn: Record<string, string> = dictionary?.en || {};

  const ObjectiveSheetDict = Object.values(ObjectiveSheetDictionary).reduce((dict, word) => {
    const tWord =
      currentLang === Languages.valueOf('en')
        ? cDictEn[word.key] || word.en
        : cDictJa[word.key] || word.ja;
    Object.assign(dict, { [word.key]: tWord });
    return dict;
  }, {});

  return {
    ...dict,
    ...ObjectiveSheetDict,
  };
};

export const useHasPathPermission = (path: string) => {
  const { permissions: P } = useUserRole(useCurrentScopeTeamId());

  if (!path || P.URL[path] === undefined) {
    return true;
  }

  return P.URL[path];
};

export const useGetHasPathPermission = () => {
  const { permissions: P } = useUserRole(useCurrentScopeTeamId());

  return (path: string) => {
    if (!path || P.URL[path] === undefined) {
      return true;
    }

    return P.URL[path];
  };
};

export const useHasCurrentPagePermission = () => {
  const { match } = useRouter();
  return useHasPathPermission(match?.path);
};
