export const changeNullPropsToEmptyString = (obj: { [key: string]: any }) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === null) {
      newObj[key] = '';
    }
  });
  return newObj;
};

export const changeEmptyStringPropsToNull = (obj: { [key: string]: any }) => {
  const newObj: { [key: string]: any } = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === '') {
      newObj[key] = null;
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const clearUndefinedProperty = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return obj;
};

export const getKeyByValue = (object: Record<string, any>, value: any) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const isEmptyObject = (obj: any): boolean => !Object.keys(obj || {}).length;