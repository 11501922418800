import React, { FC, ReactNode } from 'react';
import { ApolloProvider as BaseProvider } from '@apollo/client';
import { initApolloClient } from '../../../lib/apollo-client';

interface ApolloProviderProps {
  children?: ReactNode
}
const ApolloProvider: FC<ApolloProviderProps> = ({ children }) => {
  const client = initApolloClient();
  {/* @ts-expect-error */}
  return <BaseProvider client={client}>{children}</BaseProvider>;
};

export default ApolloProvider;
