export const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    // scriptエレメントがあるなら、すでに呼び出し済み
    const element = Array.prototype.map
      .call(document.getElementsByTagName('script'), e => e)
      .filter((s: any) => s.src.indexOf(url) >= 0);
    if (element.length) return resolve(true);

    // scriptエレメントを追加
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);

    return null;
  });
};
