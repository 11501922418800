import React from 'react';
import styled, { css } from 'styled-components';

interface TextLineProps {
  half?: boolean;
}
const TextLineDiv = styled.div`
  background: var(--ui-02);
  height: 14px;
  width: 100%;

  ${(props: TextLineProps) =>
    props.half &&
    css`
      width: 50%;
    `}
  & + & {
    margin-top: 14px;
  }
`;

export const TextLine = ({ half }: TextLineProps) => <TextLineDiv half={half} />;

export const Checkbox = styled.div`
  height: 24px;
  width: 24px;
  min-width: 24px;
  background: var(--ui-02);
  border-radius: var(--radius-tiny);
`;

interface ContainerProps {
  height: number;
  half?: boolean;
}
export const ContainerLoading = styled.div<ContainerProps>`
  background: var(--ui-02);
  height: 14px;
  width: 100%;
  border-radius: var(--radius-small);

  ${(props: ContainerProps) => css`
    ${props.half && 'width: 50%;'}
    height: ${props.height};
  `}
`;