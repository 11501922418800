import { loadScript } from './saas';

declare global {
  interface Window {
    Raven: any;
  }
}

const appHost = process.env.REACT_APP_ADMIN_HOST;

const env = (_host => {
  if (typeof _host === 'undefined') return 'prod';

  const host = `${_host}`;
  if (host.indexOf('localhost') >= 0 || host.indexOf('dev.') >= 0) return 'dev';
  if (host.indexOf('stg.') >= 0) return 'stg';
  return 'prod';
})(appHost);

const URI_SENTRY = {
  dev: 'https://7d50acb12d13401d8e4b63973ddb6dca@sentry.io/191016',
  stg: 'https://20251b6fd840406d9cab5578aae799e6@sentry.io/190992',
  prod: 'https://982735e6f1174e77983fb6e074f91f71@sentry.io/190994',
}[env];

const getInstance = () => (window && window.Raven ? window.Raven : null);

const initialize = async () => {
  // instanceがあるなら、すでに呼び出し済み
  if (getInstance()) return {};

  return await loadScript('https://cdn.ravenjs.com/3.4.0/raven.min.js')
    .then(() => {
      if (URI_SENTRY) window.Raven.config(URI_SENTRY).install();
      return {};
    })
    .catch(() => ({ error: true }));
};

const initialized = () => getInstance() && getInstance().isSetup();

const identify = ({ id, email }: any) => {
  if (!initialized()) return;
  window.Raven.setUserContext({
    id,
    email,
  });
};

const report = (level: any, title: any, from: any, options = {}) => {
  if (!initialized()) return;
  window.Raven.captureMessage(title, {
    level,
    logger: from,
    extra: {
      options,
    },
  });
};

const error = (title: any, from: any, options = {}) => report('error', title, from, options);
const warning = (title: any, from: any, options = {}) => report('warning', title, from, options);
const info = (title: any, from: any, options = {}) => report('info', title, from, options);
const debug = (title: any, from: any, options = {}) => report('debug', title, from, options);

// NOTES: action.payload を送ると、ユーザーの入力情報がすべてイベントログに残ってしまうので、送らない
const capture = (action: any) => info(action.type, action.type /* , action.payload */);

export default {
  initialize,
  identify,
  report,
  error,
  warning,
  info,
  debug,
  capture,
};
