import { FC } from 'react';
import { Link } from 'react-router-dom';
import { hasElement } from '../../utils/array';
import Typo from './Typo';
import AngleArrowRightIcon from './icons/AngleArrowRight';

export type BreadcrumbLabel = {
  label: string;
  to?: string;
};

interface BreadcrumbProps {
  labels: (BreadcrumbLabel | string)[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ labels: _labels }) => {

  const labels = _labels.map(_label => {
    if(typeof _label === 'string') {
      return {
        label: _label,
      }
    }
    return _label;
  })

  if (!hasElement(labels)) {
    return null;
  }

  return (
    <nav aria-label="breadcrumb" className="overflow-hidden mb-4 p-0">
      <ol className="list-none flex items-center gap-x-2">
        {labels.map((link: BreadcrumbLabel, index: number) => (
          <li key={`breadcrumb-li-${index}`}>
            <span className="flex items-center gap-x-2">
              {index > 0 && (
                <AngleArrowRightIcon size="20px" pathClassName="fill-current" />
              )}
              {link.to ? (
                <>
                  {/* @ts-expect-error */}
                  <Link to={link.to} className="text-base-02 dark:text-base-02-dark text-sm cursor-pointer">{link.label}</Link>
                </>
              ) : (
                <>
                  <Typo variant="body-02" color="text-02">{link.label}</Typo>
                </>
              )}
            </span>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumb;