import numeral from 'numeral';
import i18next from 'i18next';

import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

import { isAfterDate } from './date-fns';
import {
  Maybe,
  InvoiceItem,
  CompanyStatuses,
  PaymentTypes,
  CompanySubscription,
} from '../generated/graphql';

export const isCompanyActivated = (status: CompanyStatuses) => {
  return status === CompanyStatuses.Activated;
};

export const isCompanySubscribedWithCard = (subscription: CompanySubscription) => {
  return subscription?.paymentType === PaymentTypes.Card;
};

export const isCompanyExpireSoon = (expiredAt: Date, status: CompanyStatuses) => {
  if (!status || !expiredAt) return false;
  if (status === CompanyStatuses.Expired) return true;
  if (status !== CompanyStatuses.Activated) return false;
  return !isAfterDate(subDays(new Date(expiredAt), 3), new Date());
};

export const isCompanyExpired = (expiredAt: Date, status: CompanyStatuses) => {
  if (!status) return false;
  if (status === CompanyStatuses.Expired) return true;
  if (status !== CompanyStatuses.Activated) return false;
  if (!expiredAt) return false;
  return !isAfterDate(expiredAt, new Date());
};

export const isCompanyResignReserved = (status: CompanyStatuses) => {
  return status === CompanyStatuses.ResignReserved;
};

export const isCompanyRegistered = (status: CompanyStatuses) => {
  return status === CompanyStatuses.Registered;
};

export const isCompanyUnpaid = (status: CompanyStatuses) => {
  return status === CompanyStatuses.Unpaid;
};

export const isCompanySuspended = (status: CompanyStatuses) => {
  return status === CompanyStatuses.Suspended;
};

export const formatCurrency = (num: number) => {
  return numeral(num).format('0,0[.]00');
};

export const formatCurrencyWithYen = (num: number) => {
  return `¥ ${formatCurrency(num)}`;
};

export const formatCurrencyWithYenAfter = (num: number) => {
  return i18next.t('{{amount}} 円', {
    amount: formatCurrency(num),
  });
};

const TAX_CHANGE_DATE_STR = '2019-10-01'; //FIXME
const taxRatePrev = 8;
const taxRateCurr = 10;

const TAX_CHANGE_DATE = startOfDay(new Date(TAX_CHANGE_DATE_STR));

export const currentTaxRate = (date = new Date()) =>
  date < TAX_CHANGE_DATE ? taxRatePrev : taxRateCurr;

interface calcPricesProps {
  price: number;
  tax: number;
  quantity: number;
}

export const calcPrices = ({ price, tax, quantity }: calcPricesProps) => {
  const subtotal = Math.floor(quantity * price);
  const totalTax = Math.floor(quantity * price * tax);
  const total = subtotal + totalTax;

  return { subtotal, totalTax, total };
};
export const isNoPlan = (plan: Maybe<InvoiceItem> | undefined) => plan?.id === 'none'; //FIXME
