import i18next from "i18next";
import url from "../../../constants/url";
// import { UserRoles } from "../../../generated/graphql";
import { UseTutorialPropsStep } from "../../../lib/use-tutorial";

export const tutorials: Record<string, Record<string, UseTutorialPropsStep[]>> = {
    // [url.selfManagement.myPage.index]: {
    //     [UserRoles.Owner]: [
    //         {
    //             title: i18next.t('Ownerマイボード'),
    //             body: `Ownerここでは、マイボードを活用して目標を設定し、チーム目標を共有し、目標の進捗状況を把握し、\nユーザーのアクションを追跡します。`,
    //             image: '/img/vector/tutorial-present.svg',
    //         },
    //         {
    //             title: i18next.t('Ownerマイボード2'),
    //             body: `Ownerサンプルサンプルサンプルサンプルサンプルサンプルサンプル`,
    //             image: '/img/vector/o3s-remote-meeting.svg',
    //         },
    //         {
    //             title: i18next.t('Ownerマイボード3'),
    //             body: `サンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプル`,
    //             image: '/img/vector/support-contact.svg',
    //         }
    //     ],
    //     default: [
    //         {
    //             title: i18next.t('マイボード'),
    //             body: `ここでは、マイボードを活用して目標を設定し、チーム目標を共有し、目標の進捗状況を把握し、\nユーザーのアクションを追跡します。`,
    //             image: '/img/vector/tutorial-present.svg',
    //         },
    //         {
    //             title: i18next.t('マイボード2'),
    //             body: `サンプルサンプルサンプルサンプルサンプルサンプルサンプル`,
    //             image: '/img/vector/o3s-remote-meeting.svg',
    //         },
    //         {
    //             title: i18next.t('マイボード3'),
    //             body: `サンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプル`,
    //             image: '/img/vector/support-contact.svg',
    //         }
    //     ]
    // }
    [url.test.index]: {
        default: [
            {
                title: i18next.t('マイボード'),
                body: `ここでは、マイボードを活用して目標を設定し、チーム目標を共有し、目標の進捗状況を把握し、\nユーザーのアクションを追跡します。`,
                image: '/img/vector/tutorial-present.svg',
            },
            {
                title: i18next.t('マイボード2'),
                body: `サンプルサンプルサンプルサンプルサンプルサンプルサンプル`,
                image: '/img/vector/o3s-remote-meeting.svg',
            },
            {
                title: i18next.t('マイボード3'),
                body: `サンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプルサンプル`,
                image: '/img/vector/support-contact.svg',
            }
        ]
    }
}