import { memo, FC } from 'react';
import { PageTutorialWrap } from './styled';
import { useTranslation } from 'react-i18next';
import { tutorials } from './tutorial';
import Heading from '../../quarks/Heading';
import useTutorial, { UseTutorialPropsStep } from '../../../lib/use-tutorial';
import Box from '../../quarks/Box';
import Typo from '../../quarks/Typo';
import { Button } from 're-design';
import { useRouter } from '../../../lib/use-router';
import { useMe } from '../../../lib/use-me';
import { hasOwnerRole } from '../../../utils/role';
import { UserRoles } from '../../../generated/graphql';

const PageTutorial: FC = memo(() => {
  const { t } = useTranslation();

  const me = useMe();
  const userRole: UserRoles = (() => {
    if(hasOwnerRole(me)) {
      return UserRoles.Owner
    }
    return UserRoles.Member;
  })();

  const { pathname } = useRouter();
  const tutorialSteps: UseTutorialPropsStep[] = (() => {
    if(tutorials[pathname]) {
      return tutorials[pathname][userRole] || tutorials[pathname]['default'] || null;
    }
    return [];
  })();
  
  const { isEmpty, stepContent, previewStep, nextStep, hasPreviousStep, hasNextStep, isEnd, hasMultipleSteps } = useTutorial({
      steps: tutorialSteps
  })

  if(isEmpty || !stepContent) return null;
  
  return (
      <PageTutorialWrap>
      <div className="px-5 xs:px-7 md:px-10 py-8 min-h-[280px]">
        <div className="grid grid-cols-5 gap-5">
            <div className={stepContent.image ? 'col-span-5 md:col-span-3' : 'col-span-5'}>

                <div className="flex flex-col">
                  <Heading bold variant="h2" between="medium">{stepContent.title}</Heading>

                  <Box className="grow min-h-[60px]">
                      <Typo preLine>
                          {stepContent.body}
                      </Typo>
                  </Box>

                  {hasMultipleSteps && (
                      <Box align="right">
                          {hasPreviousStep() && <Button variant="light" onClick={() => previewStep()}>{t('前へ')}</Button>}
                          {hasNextStep() && <Button variant="light" onClick={() => nextStep()}>{t('次へ')}</Button>}

                          {isEnd() && (
                              <Button variant="light" onClick={() => nextStep()}>{t('閉じる')}</Button>
                          )}
                      </Box>
                  )}
                </div>
            </div>
            {stepContent.image && (
                <div className="col-span-2 text-right pr-5 hidden md:block">
                    <img src={stepContent.image} className="w-full max-w-[200px] inline" alt={stepContent.title} />
                </div>
            )}
        </div>
      </div>
    </PageTutorialWrap>
    );
});

export default PageTutorial;