import { isMobile } from 'react-device-detect';
import { HubspotChatProps } from '../generated/graphql';

declare global {
  interface Window {
    HubSpotConversations: any;
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
  }
}

const hasLoaded = () => {
  const status = window?.HubSpotConversations?.widget?.status();
  return status?.loaded;
};

const identify = (identifier: HubspotChatProps) => {
  if(!window.HubSpotConversations) return;
  
  window.hsConversationsSettings = {
    identificationEmail: identifier.identificationEmail,
    identificationToken: identifier.identificationToken,
  };

  if (window.HubSpotConversations) {
    window.HubSpotConversations.clear({ resetWidget: true });
  }

  if (isMobile) return;

  if (!hasLoaded()) {
    window.HubSpotConversations.widget.load();
  } else {
    window.HubSpotConversations.widget.refresh();
  }
};

const load = (options?: any) => {
  if (!hasLoaded()) window.HubSpotConversations.widget.load(options || {});
};

const selector = '#hubspot-messages-iframe-container';
const show = (options?: any) => {
  load(options);

  if (!document?.querySelector) return;
  const element = document.querySelector(selector) as HTMLElement;
  if (!element) return;

  element.style.setProperty('visibility', 'visible', 'important');
};
const hide = () => {
  if (!document?.querySelector) return;
  const element = document.querySelector(selector) as HTMLElement;
  if (!element) return;

  element.style.setProperty('visibility', 'hidden', 'important');
};

const showAndOpen = () => {
  load();
  window.HubSpotConversations.widget.open();
  show();
};

const open = () => {
  if (!hasLoaded()) return;
  window.HubSpotConversations.widget.open();
};
const close = () => {
  if (!hasLoaded()) return;
  window.HubSpotConversations.widget.close();
};

const remove = () => {
  if (!hasLoaded()) return;
  window.HubSpotConversations.widget.remove();
};

export default {
  hasLoaded,
  identify,
  load,
  show,
  hide,
  showAndOpen,
  open,
  close,
  remove,
};
