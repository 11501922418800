import styled, { css } from 'styled-components';
import { propGet } from 're-design';
import SideSheet from '../SideSheet';

interface FullscreenProps {
  zIndex: number;
}

export const FullscreenCss = css`
  display: block;
  color: var(--text-01);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: ${(props: any) => props.zIndex || 'var(--index-fullscreen-modal)'};
  -webkit-overflow-scrolling: touch;

  ${SideSheet} {
    &__content {
      height: 100%;
    }
  }
`;

export const FullScreenForm = styled.form`
  ${FullscreenCss}
  background-color: var(--ui-background-02);
`;

interface LayoutProps {
  variant?: string;
}
export const Layout = styled.div`
  ${FullscreenCss}
  background-color: var(--ui-background-02);

  ${(props: LayoutProps) =>
    props.variant === 'style-01' &&
    css`
      background: var(--ui-background-01);
    `}

  ${(props: FullscreenProps) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
`;

interface LayoutBodyProps {
  size?: 'large' | 'normal' | 'small';
  fluid?: boolean;
}
export const LayoutBody = styled.div`
  margin: var(--spacing-9) auto;
  width: ${(props: LayoutBodyProps) =>
    props.fluid ? 'auto' : `calc(780px + (var(--spacing-7) * 2))`};
  padding: var(--spacing-8) var(--spacing-7) var(--spacing-5) var(--spacing-7);
  max-width: 100%;

  @media ${propGet('breakpoint.max.md')} {
    width: 100%;
    margin: var(--spacing-9) 0 0 0;
    border-radius: var(--radius-none);
    padding: var(--spacing-6) var(--spacing-5) var(--spacing-5) var(--spacing-5);
  }

  @media ${propGet('breakpoint.max.sm')} {
    width: 100%;
    margin: var(--spacing-7) 0 0 0;
    border-radius: var(--radius-none);
    padding: var(--spacing-5) var(--spacing-3) var(--spacing-3) var(--spacing-3);
  }

  ${(props: LayoutBodyProps) =>
    !!props.size &&
    css`
      ${props.size === 'small' &&
      css`
        width: calc(440px + (var(--spacing-7) * 2));
      `}

      ${props.size === 'large' &&
      css`
        width: calc(980px + (var(--spacing-7) * 2));
      `}
    `}
`;

export const LayoutTopbarContent = styled.div`
  display: flex;
  align-items: center;
`;

export const LayoutTopbarInfo = styled.div``;

export const LayoutTopbarActions = styled.div``;

export const LayoutTopbarCloseButtonLabel = styled.div`
  font-size: 1.6rem;
  line-height: 1;
  margin-left: var(--spacing-4);
`;

export const LayoutTopbarCloseButton = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  margin-right: var(--spacing-5);
  cursor: pointer;
  color: var(--text-02);

  svg {
    height: 24px;
    width: 24px;
    path {
      fill: var(--text-02);
    }
  }

  :hover {
    color: var(--text-01);
    svg {
      path {
        fill: var(--text-01);
      }
    }
  }
`;

export const LayoutInner = styled.div``;

export const LayoutTopbar = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  height: 56px;
  background-color: var(--ui-background-02);
  align-items: center;
  display: flex;
  border-bottom: 1px solid var(--border-01);
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 var(--spacing-5);

  + ${LayoutInner} {
    margin-top: 56px;
  }

  + ${LayoutBody} {
    margin-top: 56px;
    padding-top: 40px;
  }

  @media print {
    display: none;
  }
`;

export const FullscreenSidebar = styled.div`
  width: 320px;
  position: fixed;
  box-shadow: inset 1px 0px 0px var(--border-01);
  right: 0;
  height: 100%;
  top: 56px;
  padding: var(--spacing-8) var(--spacing-7);
  max-height: calc(100vh - 56px);
  overflow-y: scroll;

  @media ${propGet('breakpoint.max.md')} {
    min-width: 100%;
    position: relative;
    top: 0;
    max-height: initial;
    overflow-y: auto;
  }
`;

export const FullscreenContent = styled.div`
  width: calc(100% - 320px);
  padding: var(--spacing-9) var(--spacing-10);
  max-height: calc(100vh - 56px);
  overflow-y: scroll;
  @media ${propGet('breakpoint.max.md')} {
    width: 100%;
    padding: var(--spacing-9) var(--spacing-5);
    max-height: initial;
    overflow-y: auto;
  }
`;
