import React, { FC, useContext, memo } from 'react';
import { ThemeActionContext } from '../../contexts/ThemeContext';

const AppLogo: FC<{
  height?: number;
  width?: number;
}> = memo(({ width = 108, height = 24 }) => {
  const { currentTheme } = useContext(ThemeActionContext);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 108 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6333 2L35.3067 16.0733L40.3244 4.31333H43.4333L43.7311 14.72L46.9 7.55333H50.1689L44.0289 20.5711H40.6333L40.3533 9.85556L35.3689 21.9244H32V21.0933L32.0044 2H35.6333ZM51.1622 10.6267L48.3356 21.8489H51.3867L54.2133 10.6267H51.1622ZM61.7111 8.52222C62.8289 8.52222 63.5311 9.27556 63.9467 9.89333L66.4089 7.54C65.4689 6.19111 63.9267 5.68889 62.5578 5.68889C59.7045 5.68889 57.2845 7.51556 56.6089 10.2111L56.5978 10.2578C56.0356 12.4956 57.1378 13.7533 59.1956 15.1911C60.7289 16.2644 60.9578 16.8133 60.78 17.52C60.5511 18.4333 59.7067 19.1644 58.5867 19.1644C57.24 19.1644 56.4645 18.16 55.9467 17.4978L53.5245 19.8733C54.7045 21.4511 56.3245 22 57.9222 22C60.6156 22 63.2089 20.1267 63.8956 17.3867L63.9067 17.34C64.44 15.2156 63.5845 13.9822 61.6733 12.5889C59.8022 11.2178 59.5756 10.6711 59.7645 9.91778C59.9756 9.07111 60.7978 8.52222 61.7111 8.52222ZM76.1311 8.72889L76.8534 5.85111H67.72L67 8.72889H70.0356L66.7489 21.84H69.8089L73.0956 8.72889H76.1311ZM80.1156 10.5333L77.4511 15.7867H80.1L80.1156 10.5333ZM82.8822 5.78222L83.3089 21.84H80.1822L80.1511 18.5044H76.0422L74.3622 21.84H71.3489L79.8022 5.78222H82.8822ZM98.6622 5.85111H98.4911H95.6711L93.4845 14.5778L91.1289 5.85111H88.2978L84.2911 21.84H87.2822L89.56 12.7489L92.0067 21.84H94.6556L97.9422 8.72889H100.807L97.52 21.84H100.58L103.867 8.72889H106.902L107.624 5.85111H98.6622ZM52.9645 9.39333C53.9867 9.39333 55.0222 8.56444 55.2778 7.54222C55.5333 6.52 54.9133 5.69111 53.8911 5.69111C52.8689 5.69111 51.8333 6.52 51.5778 7.54222C51.3222 8.56444 51.9422 9.39333 52.9645 9.39333Z"
        fill={currentTheme === 'light' ? '#7C8383' : '#f8f8f8'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53111 24H21.4689C22.8667 24 24 22.8667 24 21.4689V2.53111C24 1.13334 22.8667 0 21.4689 0H2.53111C1.13334 0 0 1.13334 0 2.53111V21.4689C0 22.8667 1.13334 24 2.53111 24Z"
        fill="#00C09A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.071 9.41989L14.2 13.6554L14.0244 7.50656H12.1866L9.21994 14.4555L9.41328 6.13989H7.26883L7.2666 17.911H9.25773L12.2022 10.7799L12.3666 17.111H14.3733L18.0044 9.41989H16.071Z"
        fill="white"
      />
    </svg>
  );
});

export default AppLogo;
