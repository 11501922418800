import { Level } from '@tiptap/extension-heading';
import { RawDraftContentState } from '../../../../lib/draft-js';
import { getRichTextInitialValueMap } from '../../../../utils/richText';
const replaceAllWords = (
  phrase: string,
  replaceWords: string[],
  newWords: Record<string, string>,
) => {
  const regex = new RegExp('(' + replaceWords.join('|') + ')', 'giu');
  return phrase.replace(regex, matched => {
    return newWords[matched.toLowerCase()];
  });
};

const commutateWorsEnUS = {
  period: '.',
  'new line': '\n',
  coma: ',',
};

const commutateWorsJa = {
  改行: '\n',
  まる: '。',
  こま: '、',
  てん: '、',
};

export const commutateSpeechRecognitionWords = (transcript: string, lang: string) => {
  const replaceWords =
    lang === 'ja' ? Object.keys(commutateWorsJa) : Object.keys(commutateWorsEnUS);

  return replaceAllWords(transcript, replaceWords, {
    ...commutateWorsEnUS,
    ...commutateWorsJa,
  });
};

export const headingLevelMap: Record<string, Level> = {
  'header-one': 1,
  'header-two': 2,
  'header-three': 3,
}

// JSONやstringをEditorが理解できる形式に変更する
export const convertValue = (value: RawDraftContentState | string, defaultText = '') => {
  try {
    const { body, hasText } = getRichTextInitialValueMap({
      data: value,
    });

    return hasText ? body : defaultText;
  } catch (_) {
    return defaultText || '';
  }
};
