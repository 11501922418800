import React, { FC, ReactNode, Ref } from 'react';

import { BaseSideSheetBody } from './styled';

interface SideSheetBodyProps {
  noPadding?: boolean;
  variant?: string;
  innerRef?: Ref<any>;
  children?: ReactNode;
}

const SideSheetBody: FC<SideSheetBodyProps> = ({ children, noPadding, variant, innerRef }) => (
  <BaseSideSheetBody noPadding={noPadding} variant={variant} ref={innerRef}>
    {children}
  </BaseSideSheetBody>
);

export default SideSheetBody;
