import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { propGet } from 're-design';
import { Layout } from '../FullscreenModal/styled';

const SIDE_BAR_WIDTH = 286;
const SHEET_VIEW_HEADER_HEIGHT = 56;
const SIDE_TAB_WIDTH = 56;

const SIDE_BAR_WIDTH_CLOSED = 32;

export const SheetViewWrapper = styled.div`
  width: 100%;

  ${(props: any) => {
    let width = ['512px', '512px'];

    if(props.expanded) {
      width = ['100%', 'auto']
    } else {
      if(props.size === "large") {
        width = ['642px', '642px', '512px', '512px'];
      }
    }
    return css`
      max-width: ${width[0]};
      min-width: ${width[1]};

      ${width[2] && width[3] && css`
        @media ${propGet('breakpoint.max.mx')} {
          max-width: ${width[2]};
          min-width: ${width[3]};
        }
      `}
    `;
  }}
  box-shadow: inset 1px 0px 0px var(--border-01);
  background: var(--ui-01);
  height: 100%;

  @media ${propGet('breakpoint.max.md')} {
    max-width: 100%;
    min-width: 100%;
  }

  ${(props: { hideOnMobile?: boolean }) =>
    props.hideOnMobile &&
    css`
      @media ${propGet('breakpoint.max.md')} {
        display: none;
      }
    `}

  ${(props: { variant?: string }) =>
    props.variant === 'objective-form' &&
    css`
      width: 280px;
      max-width: 280px;
      min-width: 280px;
      box-shadow: inset -1px 0px 0px var(--border-01);
    `}
`;

export const SheetViewHeader = styled.div`
  width: 100%;
  padding: var(--spacing-4) var(--spacing-5);
  border-bottom: 1px solid var(--border-01);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${SHEET_VIEW_HEADER_HEIGHT}px;
`;

export const SheetViewBody = styled.div`
  width: 100%;
  height: calc(100% - ${SHEET_VIEW_HEADER_HEIGHT}px);
  overflow-y: auto;
  overflow-x: hidden;

  ${(props: any) =>
    props.hideHeader &&
    css`
      height: 100%;
    `}

  @media ${propGet('breakpoint.max.mobile')} {
    height: 100%;
  }
`;

interface SheetTemplateWrapperProps {
  variant?: 'feedback-overview' | 'objective-form' | null;
}

export const SheetTemplateWrapper = styled(Layout)`
  overflow-y: hidden;
  z-index: ${(props: { zIndex: number }) => props.zIndex || 'var(--index-sheet-template)'};

  ${(props: SheetTemplateWrapperProps) =>
    props.variant === 'feedback-overview' &&
    css`
      ${SheetViewWrapper} {
        max-width: 320px;

        @media ${propGet('breakpoint.max.md')} {
          max-width: 100%;
        }
      }
    `};
`;

interface SheetTemplateBody {
  hasSidebarActions?: boolean;
  isOpenSidebar?: boolean;
  hasLinkOptions?: boolean;
}
export const SheetTemplateBody = styled.div`
  height: 100%;
  width: 100%;
  order: 1;

  ${(props: SheetTemplateBody) => {
    const sidebarActionsWidth = props.hasSidebarActions ? '56px' : '0px';
    const sidebarWidth = props.hasLinkOptions ? (props.isOpenSidebar ? '286px' : '32px') : '0px';
    return css`
      width: calc(100vw - (${sidebarActionsWidth} + ${sidebarWidth}));

      @media ${propGet('breakpoint.max.lg')} {
        width: calc(100vw - (${sidebarActionsWidth}));
      }

      @media ${propGet('breakpoint.max.mobile')} {
        width: calc(100vw);
      }
    `;
  }}

  @media ${propGet('breakpoint.max.mobile')} {
    order: 2;

    ${(props: SheetTemplateBody) =>
      props.hasSidebarActions &&
      css`
        padding-top: ${SHEET_VIEW_HEADER_HEIGHT}px;
      `}
  }
`;

export const SheetTemplateBodyScroll = styled.div`
  min-width: 600px;
  @media ${propGet('breakpoint.max.tablet')} {
    min-width: auto;
  }
`;

export const SheetTemplateSidebarActions = styled.div`
  order: 2;
  height: 100%;
  width: 56px;
  min-width: 56px;
  display: flex;
  flex-flow: column;
  border-left: 1px solid var(--border-01);
  background: var(--ui-01);

  @media print {
    display: none;
  }

  @media ${propGet('breakpoint.max.mobile')} {
    order: 1;
    height: 56px;
    min-height: 56px;
    width: 100%;
    flex-flow: row;
    justify-content: space-around;
    border-bottom: 1px solid var(--border-01);
    border-left: none;
    position: fixed;
    top: ${SHEET_VIEW_HEADER_HEIGHT}px;
    left: 0;
    z-index: 5;
  }
`;

export const SheetTemplateSideBarBody = styled.div`
  padding: var(--spacing-3);
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  padding-top: 36px;
`;

export const SheetTemplateSideBarToggleButton = styled.div`
  position: absolute;
  top: calc(54px + 20px);
  left: calc(${SIDE_BAR_WIDTH_CLOSED}px - 16px);
  width: 32px;
  height: 32px;
  padding: 4px;
  background: var(--ui-01);
  box-shadow: 0 0 4px 0 var(--shadow-01);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background: var(--ui-03);
  }

  ${(props: { isOpen: boolean }) =>
    props.isOpen &&
    css`
      left: calc(${SIDE_BAR_WIDTH}px - 16px);
    `}
`;

interface SheetTemplateSideBarProps {
  isOpen: boolean;
}

export const SheetTemplateSideBar = styled.div`
  width: ${SIDE_BAR_WIDTH}px;
  min-width: ${SIDE_BAR_WIDTH}px;
  background-color: var(--ui-02);
  overflow-x: visible;
  // transition: width ease 0.1s;

  ${SheetTemplateSideBarToggleButton} {
    display: none;
  }

  + ${SheetTemplateSideBarToggleButton} {
    display: block;

    @media print {
      display: none;
    }

    @media ${propGet('breakpoint.max.lg')} {
      display: none;
    }
  }

  ${(props: SheetTemplateSideBarProps) =>
    !props.isOpen &&
    css`
      width: ${SIDE_BAR_WIDTH_CLOSED}px;
      min-width: ${SIDE_BAR_WIDTH_CLOSED}px;
      ${SheetTemplateSideBarBody} {
        pointer-events: none;
        opacity: 0;
      }

      &:hover {
        width: ${SIDE_BAR_WIDTH}px;
        min-width: ${SIDE_BAR_WIDTH}px;
        position: fixed;
        bottom: 0;
        top: 56px;
        z-index: 10;

        ${SheetTemplateSideBarBody} {
          pointer-events: all;
          opacity: 1;
        }
        ${SheetTemplateSideBarToggleButton} {
          display: block;
          left: calc(${SIDE_BAR_WIDTH}px - 16px);
          top: 20px;
        }
        + ${SheetTemplateSideBarToggleButton} {
          display: none;

          + ${SheetTemplateBody} {
            margin-left: ${SIDE_BAR_WIDTH_CLOSED}px;
          }
        }
      }
    `}

  & + ${SheetTemplateBody} {
    width: calc(100% - ${SIDE_BAR_WIDTH}px - ${SIDE_TAB_WIDTH}px);

    ${(props: SheetTemplateSideBarProps) =>
      !props.isOpen &&
      css`
        width: calc(100% - ${SIDE_BAR_WIDTH_CLOSED}px - ${SIDE_TAB_WIDTH}px);
      `}

    @media ${propGet('breakpoint.max.lg')} {
      width: 100%;
    }
  }

  @media ${propGet('breakpoint.max.lg')} {
    display: none;
  }

  @media print {
    display: none;
  }
`;

interface SheetTemplateSideBarLinkProps {
  current?: boolean;
  alert?: boolean;
}

export const SheetTemplateSideBarLink = styled(Link)`
  font-size: 1.4rem;
  color: var(--text-02);
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  padding: 13px 24px 13px 24px;
  text-decoration: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--ui-01);
  }
  ${(props: SheetTemplateSideBarLinkProps) =>
    props.current &&
    css`
      background-color: var(--ui-01);
      font-weight: bold;
      color: var(--text-01);
    `};
`;

export const SheetTemplateSideBarLinkLabel = styled.div`
  word-break: break-all;
  line-height: 1.4;
`;

export const SheetTemplateSideBarLinkIcon = styled.div`
  width: 24px;
  max-width: 24px;
  min-width: 24px;
  overflow: hidden;
  position: relative;
  left: var(--spacing-2-inverse);
`;

export const SheetTemplateInnerWrapper = styled.div`
  padding-top: 56px;
  overflow: hidden;
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
  position: relative;
  @media ${propGet('breakpoint.max.mobile')} {
    flex-flow: column;
  }
`;

export const AlertWrap = styled.div`
  min-width: 24px;
  text-align: center;
  margin-right: -16px;
`;

export const SheetTimelineWrapper = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  flex-grow: 1;
  flex-direction: row-reverse;
`;
