import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface LoaderProps {
  size?: 'button' | 'extra-tiny' | 'tiny' | 'small' | 'normal' | 'large';
  color?: string;
  withMargin?: boolean;
}

const BaseLoader = styled.div`
  display: block;
  position: relative;
  display: block;
  margin: 0 auto;

  ${(props: LoaderProps) =>
    props.withMargin &&
    css`
      margin: 55px auto;
    `}

  ${(props: LoaderProps) => {
    switch (props.size) {
      case 'extra-tiny':
        return css`
          width: 12px;
          height: 12px;
        `;
      case 'button':
        return css`
          width: 16px;
          height: 16px;
        `;
      case 'tiny':
        return css`
          width: 22px;
          height: 22px;
        `;
      case 'small':
        return css`
          width: 48px;
          height: 48px;
        `;
      default:
      case 'normal':
        return css`
          width: 86px;
          height: 86px;
        `;
      case 'large':
        return css`
          width: 160px;
          height: 160px;
        `;
    }
  }};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 4px;
    border: 4px solid ${(props: LoaderProps) => props.color || 'var(--interactive-01)'};
    border-radius: 50%;
    animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props: LoaderProps) =>
      props.color || 'var(--interactive-01)'} transparent transparent transparent;

    :nth-child(1) {
      animation-delay: -0.45s;
    }
    :nth-child(2) {
      animation-delay: -0.3s;
    }
    :nth-child(3) {
      animation-delay: -0.15s;
    }

    ${(props: LoaderProps) => {
      switch (props.size) {
        case 'button':
        case 'tiny':
        case 'extra-tiny':
          return css`
            margin: 1px;
            border-width: 1px;
            width: calc(100% - 1px);
            height: calc(100% - 1px);
          `;
        case 'small':
          return css`
            margin: 3px;
            border-width: 3px;
            width: calc(100% - 3px);
            height: calc(100% - 3px);
          `;
        default:
        case 'normal':
          return css`
            margin: 6px;
            border-width: 6px;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
          `;
        case 'large':
          return css`
            margin: 12px;
            border-width: 12px;
            width: calc(100% - 12px);
            height: calc(100% - 12px);
          `;
      }
    }};
   }

   @keyframes loading {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }
 }
`;

const Loader: FC<LoaderProps> = props => (
  <BaseLoader {...props}>
    <div />
    <div />
    <div />
    <div />
  </BaseLoader>
);
export default Loader;
