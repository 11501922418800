import { FC } from 'react';
import { CommonIconProps, defaultPathClassName } from './utils';

const AngleArrowRightIcon: FC<CommonIconProps> = props => {
  const { size = '100%', className, pathClassName = defaultPathClassName } = props;
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        className={pathClassName}
        d="M9.75818 18L9 17.1564L13.506 11.9922L13.0255 11.4414L13.0282 11.4444L9.02455 6.8562L9.77127 6C10.878 7.2678 13.9675 10.8084 15 11.9922C14.2331 12.8718 14.9809 12.0144 9.75818 18Z"
      />
    </svg>
  );
};

export default AngleArrowRightIcon;
