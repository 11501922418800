const palette = {
  gray10: '#F7F9F9',
  gray20: '#EAEDED',
  gray30: '#D5D7D7',
  gray40: '#AAADAD',
  gray50: '#7A7C7C',
  gray60: '#6C7070',
  gray70: '#5D6161',
  gray80: '#4B4E4E',
  gray90: '#363838',
  gray100: '#202222',

  blue70: '#4493CC',
  blue50: '#55B8FF',
  blue30: '#DDEDFF',

  red70: '#B83C5B',
  red50: '#EB4D75',
  red30: '#FFDDED',

  magenta70: '#CA5F9E',
  magenta50: '#EC65B5',

  springGreen70: '#65B268',
  springGreen50: '#87EB8B',

  white: '#FFFFFF',
  black: '#181A1A',

  purple70: '#7371C7',
  purple50: '#8C88FF',

  orange70: '#D48B41',
  orange50: '#FFAA55',

  yellow70: '#D9CC59',
  yellow50: '#F5E874',

  green100: '#2E736A',
  green70: '#3C9E92',
  green60: '#4CBFB1',
  green50: '#50D2C2',
  green40: '#ACE9DC',
  green30: '#E6F9F5',
};

export default palette;
