import {
  User,
  UserStatuses,
  UserRoles,
  MemberFilterComparisonOperatorTypes,
  LoginableUserStatuses,
  Maybe,
  PeriodUser,
} from '../generated/graphql';
import { hasElement, takeAwayEmptyElement, takeAwayMaybeElement } from './array';
import { hasRole } from './role';
import { periodTeamsToTeams } from './team';
import { omit } from './lodash';

export const isLoginableUser = (user: User) =>
  Object.values(LoginableUserStatuses).some(s => {
    return s === user.status;
  });

export const getInvitedUsers = (users: Maybe<Maybe<User>[]> | undefined): User[] =>
  takeAwayEmptyElement<User[]>(users).filter(user => user.status === UserStatuses.Invited);

export const getActiveUsers = (users: Maybe<Maybe<User>[]> | undefined): User[] =>
  takeAwayEmptyElement<User[]>(users).filter(user => isLoginableUser(user));

export const getActiveUsersWithMemberRole = (users: Maybe<Maybe<User>[] | undefined>): User[] =>
  takeAwayEmptyElement<User[]>(users).filter(
    user => isLoginableUser(user) && hasRole(user, [UserRoles.Member]),
  );

export const filterUsers = (
  users: User[],
  availableUserRoles: UserRoles[] | 'all',
  availableUserStatuses: UserStatuses[] | 'all',
) => {
  return users.filter(user => {
    const fulfillUserStatus =
      availableUserStatuses === 'all'
        ? true
        : availableUserStatuses.includes(user?.status! as UserStatuses);
    const fulfillUserRoles =
      availableUserRoles === 'all' ? true : hasRole(user, availableUserRoles);
    return fulfillUserRoles && fulfillUserStatus;
  });
};

export const filtersEntitiesToMemberFilters = (filters: any) => {
  const userValues = [] as any[];
  const teamValues = [] as any[];
  const groupTagValues = [] as any[];

  (filters?.filterEntities || []).forEach((id: string) => {
    userValues.push({ type: 'user', id });
  });
  (filters?.memberIds || []).forEach((id: string) => {
    userValues.push({ type: 'user', id });
  });
  (filters?.teamId || []).forEach((id: string) => {
    teamValues.push({ type: 'team', id });
  });
  (filters?.groupTagId || []).forEach((id: string) => {
    groupTagValues.push({ type: 'groupTag', id });
  });

  if (userValues.length === 0 && teamValues.length === 0 && groupTagValues.length === 0) {
    return [
      {
        comparisonType: MemberFilterComparisonOperatorTypes.In,
        key: 'all',
        values: [],
      },
    ];
  }

  const memberFilters = [];
  if (userValues.length > 0)
    memberFilters.push({
      comparisonType: MemberFilterComparisonOperatorTypes.In,
      key: 'user',
      values: userValues,
    });
  if (teamValues.length > 0)
    memberFilters.push({
      comparisonType: MemberFilterComparisonOperatorTypes.In,
      key: 'team',
      values: teamValues,
    });
  if (groupTagValues.length > 0)
    memberFilters.push({
      comparisonType: MemberFilterComparisonOperatorTypes.In,
      key: 'group',
      values: groupTagValues,
    });

  return memberFilters;
};

export const getUserOptions = (users: Maybe<Maybe<User>>[] | undefined): SelectOption[] => {
  return takeAwayMaybeElement<User[]>(users).map(user => ({
    value: user.id,
    label: user.displayName!,
  }));
};

export const periodUserToUser = (user: PeriodUser): User => {
  return {
    id: user.userId,
    __typename: 'User',
    teams: hasElement(user?.teams) ? periodTeamsToTeams(user?.teams) : null,
    status: user?.status === 'active' ? UserStatuses.Registered : user?.status,
    ...omit(user, ['userId', 'status', 'periodId', '__typename', 'teams', 'primaryTeam']),
  };
};
export const periodUsersToUsers = (
  users: Maybe<Maybe<PeriodUser>[]> | undefined | null,
): User[] => {
  return takeAwayMaybeElement<PeriodUser[]>(users).map(user => periodUserToUser(user));
};
