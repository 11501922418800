import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { propGet } from 're-design';
import AppLogo from '../../atoms/AppLogo';
import Loader from '../../atoms/Loader';

interface LoadingPageProps {
  relative?: boolean;
}

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${(props: LoadingPageProps) =>
    props.relative &&
    css`
      width: 100%;
      height: 100%;
    `}
`;

const LoadingContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;

  svg {
    display: block;
    margin: 0 auto;
  }
`;

const LoadingContentText = styled.div`
  margin-top: var(--spacing-6);
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-02);
  line-height: 1.4;
  span {
    display: inline-block;
    margin: 0 var(--spacing-2);
  }
  br {
    display: none;
    @media ${propGet('breakpoint.max.xs')} {
      display: block;
    }
  }
`;

const LoadingPage: FC<LoadingPageProps> = ({ relative }) => {
  const { t } = useTranslation();
  return (
    <LoadingWrapper relative={relative}>
      <LoadingContent>
        <AppLogo width={150} height={34} />
        <Loader withMargin />
        <LoadingContentText>
          <span>{t('読み込み中です。')}</span>
          <br />
          <span>{t('少々お待ち下さい…')}</span>
        </LoadingContentText>
      </LoadingContent>
    </LoadingWrapper>
  );
};

export default LoadingPage;
