import { HelmetProps } from 'react-helmet-async';

const description =
  'Wistantは、1on1、目標管理、フィードバックをワンストップで実現するマネジメントツールです。組織をもっと良くする「ピープルマネジメント」を今すぐ始めましょう。';

const defaultProps = {
  meta: [
    { name: 'description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:image', content: 'https://demo.wistant.com/ogp.png' },
    { property: 'og:site_name', content: 'Wistant' },
    { property: 'og:description', content: description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'fb:app_id', content: '188250198416259' },
  ],
};

type generateHeadProps = {
  title: string;
  url: string | undefined;
};

export const generateHead = ({ title: _title, url: _url }: generateHeadProps): HelmetProps => {
  const title = _title ? `${_title} - Wistant` : 'Wistant';
  return {
    ...defaultProps,
    title,
    meta: [
      ...defaultProps.meta,
      { property: 'og:title', content: title },
      {
        property: 'og:url',
        content: _url
          ? `https://${((document || {}).location || {}).hostname}${_url}`
          : ((document || {}).location || {}).href,
      },
    ],
  };
};
