import { has } from '../../utils/lodash';
import { sortWithoutBreaking } from '../../utils/array';

export default class EnumMap {
  constructor(itemMap) {
    this.items = [];
    this.valueKeyMap = {}; // value - key
    this._createMap(itemMap);
  }

  length() {
    return this.items.length;
  }

  keyOf(value) {
    return this.valueKeyMap[value];
  }

  valueOf(key) {
    const item = this.items.find(item => item.key === key);
    if (item) {
      return item.value;
    }
    return undefined;
  }

  has(key) {
    return has(this, key);
  }

  labelOf(key) {
    const item = this.items.find(item => item.key === key);
    if (item) {
      if (typeof item.labelFunction === 'function') {
        return item.labelFunction();
      }
      return item.label || '';
    }
    return undefined;
  }

  keys() {
    return this.items.map(item => item.key);
  }

  values() {
    return this.items.map(item => item.value);
  }

  keyMap() {
    const _map = {};
    this.items.forEach(item => {
      _map[item.key] = item.key;
    });
    return _map;
  }

  valueMap() {
    const _map = {};
    this.items.forEach(item => {
      _map[item.key] = item.value;
    });
    return _map;
  }

  labelMap() {
    const _map = {};
    this.items.forEach(item => {
      _map[item.key] = item.label;
    });
    return _map;
  }

  list() {
    return this.items.map(item => {
      const label = this.labelOf(item.key);
      return {
        ...item,
        label,
      };
    });
  }

  get(key) {
    return this.items[key];
  }

  _createMap(itemMap) {
    const valueMap = {}; // key - value
    const labelMap = {}; // key - label

    Object.keys(itemMap).forEach(key => {
      const item = itemMap[key];
      // key, value, label の重複は許可しない
      if (has(this.valueKeyMap, key)) {
        throw new Error(`duplicated key '${key}' is not allowed.`);
      }
      if (has(valueMap, item.value)) {
        throw new Error(`duplicated value '${item.value}' is not allowed.`);
      }
      if (has(labelMap, item.label)) {
        throw new Error(`duplicated label '${item.label}' is not allowed.`);
      }

      // add to maps
      this.valueKeyMap[item.value] = key;
      valueMap[key] = item.value;
      labelMap[key] = item.label;

      // store item
      // const enumItem = Object.assign({}, item, { key });
      this[key] = {
        key,
        ...item,
      };
      this.items.push({
        key,
        ...item,
      });
    });

    // sort items
    this.items = sortWithoutBreaking(this.items, (a, b) => {
      if (a.order === b.order) {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      } else {
        return a.order - b.order;
      }
    });
  }
}
