import { ReactNode, FC } from 'react';
import { Tabs, Responsive } from 're-design';
import Flex from '../../quarks/Flex';
import Typo from '../../quarks/Typo';

interface SheetActionTabsProps {
  current: string;
  items: any[];
  onClick?: (key: string) => void;
}

const SheetActionTabs: FC<SheetActionTabsProps> = ({ items = [], current, onClick }) => {
  if (!items.length) {
    return null;
  }
  const laptopItems = items.map(item => ({
    ...item,
    hide: item.key === 'main' ? true : item.hide || false,
  }));

  return (
    <>
      <Responsive mobile>
        <Tabs controlled current={current} items={items} fluid size="large" onClick={onClick} />
      </Responsive>
      <Responsive tablet laptop>
        <Tabs
          controlled
          current={current}
          items={laptopItems}
          direction="vertical"
          onClick={onClick}
        />
      </Responsive>
    </>
  );
};

interface SheetActionTabsButtonProps {
  text: string;
  icon: ReactNode;
}

export const SheetActionTabsButton: FC<SheetActionTabsButtonProps> = ({ text, icon }) => {
  return (
    <Flex direction="column" between="none">
      {icon}
      <Typo variant="label-03" color="text-02">
        {text}
      </Typo>
    </Flex>
  );
};

export default SheetActionTabs;
