import React, { useMemo, FC, useCallback, ReactNode } from 'react';
import { setItem, removeItem, getItem } from '../../lib/local-storage';
import { isJson } from '../../utils/string';

type LocalStorageState = {
  key: string;
  values?: any;
};

type LocalStorageAction = {
  type: 'SET_LOCAL_STORAGE' | 'REMOVE_LOCAL_STORAGE';
  payload?: any;
};

export interface SetLocalStorageParams {
  notUpdateState?: boolean;
}

interface LocalStorageActionContext {
  getLocalStorage(key: string, defaultValue: any): any;
  removeLocalStorage(key: string): void;
  setLocalStorage: (key: string, values: any, params: SetLocalStorageParams | undefined) => void;
}

export const LocalStorageStateContext = React.createContext<LocalStorageState[]>(
  [] as LocalStorageState[],
);
export const LocalStorageStateConsumer = LocalStorageStateContext.Consumer;
export const LocalStorageActionContext = React.createContext<LocalStorageActionContext>(
  {} as LocalStorageActionContext,
);
export const LocalStorageActionConsumer = LocalStorageActionContext.Consumer;

const LocalStorageReducer = (state: LocalStorageState[], action: LocalStorageAction) => {
  const { key, values } = action.payload;
  const copyState = [...state];
  const index = copyState.findIndex(item => item.key === key);

  switch (action.type) {
    case 'SET_LOCAL_STORAGE': {
      if (index > -1) {
        copyState[index].values = values;
      } else {
        copyState.push({
          key,
          values,
        });
      }

      return copyState;
    }
    case 'REMOVE_LOCAL_STORAGE': {
      if (index > -1) {
        copyState.slice(index, 1);
      }
      return copyState;
    }
  }
};

interface LocalStorageProviderProps {
  children?: ReactNode;
}

const LocalStorageProvider: FC<LocalStorageProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(LocalStorageReducer, []);

  const removeLocalStorage = useCallback(
    (key: string) => {
      removeItem(`LOCAL_STORAGE-${key}`);
      dispatch({
        type: 'REMOVE_LOCAL_STORAGE',
        payload: {
          key,
        },
      });
    },
    [dispatch],
  );

  const setLocalStorage = useCallback(
    (key: string, values?: any, params?: SetLocalStorageParams) => {
      setItem(`LOCAL_STORAGE-${key}`, JSON.stringify(values));

      if (!params?.notUpdateState) {
        dispatch({
          type: 'SET_LOCAL_STORAGE',
          payload: {
            key,
            values,
          },
        });
      }
    },
    [dispatch],
  );

  const getLocalStorage = useCallback(
    (key: string, defaultValue: any) => {
      const localStorageStr = getItem(`LOCAL_STORAGE-${key}`);

      if (localStorageStr && isJson(localStorageStr || '')) {
        return JSON.parse(localStorageStr);
      }

      const findItem: LocalStorageState | undefined = state.find(item => item.key === key);
      return findItem?.values || defaultValue;
    },
    [state],
  );

  const actions = useMemo(
    () => ({
      setLocalStorage,
      getLocalStorage,
      removeLocalStorage,
    }),
    [setLocalStorage, getLocalStorage, removeLocalStorage],
  );

  return (
    <LocalStorageStateContext.Provider value={state}>
      <LocalStorageActionContext.Provider value={actions}>
        {children}
      </LocalStorageActionContext.Provider>
    </LocalStorageStateContext.Provider>
  );
};

export default LocalStorageProvider;
