import { FC, MouseEvent, PropsWithChildren, memo } from 'react';
import { TypoColor, typoColorMap } from './types';
import { twMerge } from 'tailwind-merge';

export type TextAlign = "left" | "center" | "right" | "inherit";
export type TypoOverflow = "hidden" | "visible" | "inherit" | "auto" | "scroll" | "ellipsis"
export type TypoTag = "div" | "p" | "span" | "small";
export type TypoVariant = "body-01" | "body-02" | "label-01" | "label-02" | "label-03" | "meta-01" | "meta-02"
export type TypoBreak = "normal" | "all";

export interface TypoProps {
  tag?: TypoTag;
  variant?: TypoVariant;
  className?: string;
  overflow?: TypoOverflow;
  color?: TypoColor;
  inline?: boolean;
  bold?: boolean;
  pre?: boolean;
  preLine?: boolean;
  underline?: boolean;
  lineThrough?: boolean;
  lineHeight?: string | number;
  pointer?: boolean;
  break?: TypoBreak;
  onClick?: (e: MouseEvent) => void;
  capitalize?: boolean;
}

const Typo: FC<PropsWithChildren<TypoProps>> = memo(
  ({
    tag = "p",
    className = '',
    variant = "body-01",
    overflow = "auto",
    break: typoBreak = "normal",
    color = "text-01",
    inline = false,
    bold = false,
    pre = false,
    preLine = false,
    underline = false,
    lineThrough = false,
    lineHeight,
    pointer = false,
    capitalize = false,
    children,
    ...props
  }) => {
    const styles: any = {};
    if (lineHeight) {
      styles.lineHeight = lineHeight;
    }

    const variantClassNames = (() => {
      switch (variant) {
        case "body-01":
          return ['text-base', 'leading-normal'];
        case "body-02":
          return ['text-sm', 'leading-normal'];
        case "label-01":
          return ['text-sm', 'leading-normal'];
        case "label-02":
          return ['text-xs', 'leading-normal'];
        case "label-03":
          return ['text-2xs', 'leading-normal'];
        case "meta-01":
          return ['text-sm', 'leading-normal'];
        case "meta-02":
          return ['text-xs', 'leading-normal'];
        default:
          return ['text-base', 'leading-normal'];
      }
    })();

    const colorClassNames = color ? [typoColorMap[color]] : [];
    const classNames: string[] = [...variantClassNames, ...colorClassNames];
    
    if (overflow) {
      switch(overflow) {
        case "ellipsis":
          classNames.push('text-ellipsis overflow-hidden text-nowrap');
          break;
        case "auto":
        default:
          classNames.push('overflow-auto');
          break;
        case "hidden":
          classNames.push('overflow-hidden');
          break;
        case "inherit":
          classNames.push('overflow-inherit');
          break;
        case "scroll":
          classNames.push('overflow-scroll');
          break;
        case "visible":
          classNames.push('overflow-visible');
          break;
      }
    }
    if (inline) {
      classNames.push('inline');
    }
    if (bold) {
      classNames.push('font-bold');
    }
    if (pre || preLine) {
      if (pre) {
        classNames.push('whitespace-pre');
      } else if (preLine) {
        classNames.push('whitespace-pre-line');
      }
    }
    if (underline) {
      classNames.push('underline');
    }
    if (lineThrough) {
      classNames.push('line-through');
    }
    if (lineThrough) {
      classNames.push('line-through');
    }
    if (pointer) {
      classNames.push('cursor-pointer');
    }
    if (capitalize) {
      classNames.push('capitalize');
    }
    if (typoBreak) {
      switch(typoBreak) {
        case "normal":
        default:
          classNames.push(`break-normal`);
          break;
        case "all":
          classNames.push(`break-all`);
          break;
      }
    }

    switch (tag) {
      case 'div':
        return <div style={styles} className={twMerge(classNames, className)} {...props}>{children}</div>;
      case 'p':
      default:
        return <p style={styles} className={twMerge(classNames, className)} {...props}>{children}</p>;
      case 'span':
        return <span style={styles} className={twMerge(classNames, className)} {...props}>{children}</span>;
      case 'small':
        return <small style={styles} className={twMerge(classNames, className)} {...props}>{children}</small>;
    }
  },
);

export default Typo;
