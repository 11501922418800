import React, { FC, ReactNode } from 'react';
import { propGet } from 're-design';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';

import SideSheetBody from './Body';
import SideSheetHeader from './Header';
import SideSheetFooter from './Footer';
import { useHideHubspotChat } from '../../../lib/use-hubspot-chat';
import { useGetLastAction } from '../../../lib/sidesheet';

export enum SideSheetVariant {
  WithTopbar = 'withTopbar',
  FullHeight = 'fullHeight',
  Fullscreen = 'fullscreen',
}

interface SideSheetProps {
  className?: string;
  sideSheetClassName?: string;
  elementTopBar?: ReactNode;
  contentLabel?: string;
  isOpen: boolean;
  onClose: () => any;
  variant?: 'withTopbar' | 'fullHeight' | 'fullscreen';
  ui?: 'cloud' | 'large-cloud';
  zIndex?: number;
  parentNode?: HTMLElement;
  children?: ReactNode;
}

const SideSheetAdapter: FC<SideSheetProps> = ({
  children,
  className = 'sideSheet',
  sideSheetClassName = '',
  isOpen,
  onClose,
  contentLabel,
  parentNode,
}) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  useHideHubspotChat();

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={contentLabel}
      ariaHideApp={false}
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      className={`${contentClassName} ${sideSheetClassName}`}
      overlayClassName={overlayClassName}
      parentSelector={() => (parentNode ? parentNode : document.body)}
    >
      {children}
    </ReactModal>
  );
};

const SideSheet = styled(SideSheetAdapter)`
  &__overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__portal {
    z-index: ${(props: SideSheetProps) => props.zIndex || 'var(--index-sidesheet)'};
  }

  &__content {
    width: 100%;
    max-width: 720px;
    height: 100%;
    align-self: center;
    position: relative;
    background-color: var(--ui-01);
    box-shadow: 0 8px 8px 0 var(--shadow-01);
    position: fixed;
    top: 0;
    right: 0;
    z-index: ${(props: SideSheetProps) => props.zIndex || 'var(--index-sidesheet)'};
    display: flex;
    flex-direction: column;
    outline: none;
    overflow: hidden;

    ${(props: SideSheetProps) =>
      (!props.variant || props.variant === 'withTopbar') &&
      css`
        top: 56px;
        height: calc(100% - 56px);
      `}

    ${(props: SideSheetProps) =>
      props.variant === 'fullscreen' &&
      css`
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 100%;
      `}

    ${(props: SideSheetProps) =>
      props.ui === 'large-cloud' &&
      css`
        border-radius: var(--radius-small);
        margin: var(--spacing-5);
        box-shadow: 0 0 8px 0 var(--shadow-01);
        width: calc(100% - 32px);
        height: calc(100% - 32px);

        @media ${propGet('breakpoint.max.sm')} {
          max-width: 436px;
          margin: var(--spacing-3);
          width: calc(100% - 16px);
          height: calc(100% - 16px);
        }

        ${(!props.variant || props.variant === 'withTopbar') &&
        css`
          height: calc(100% - 88px);

          @media ${propGet('breakpoint.max.sm')} {
            height: calc(100% - 72px);
          }
        `}

        ${props.variant === 'fullscreen' && css``}
      `}

    ${(props: SideSheetProps) =>
      props.ui === 'cloud' &&
      css`
        border-radius: var(--radius-small);
        margin: var(--spacing-5);
        max-width: 452px;
        box-shadow: 0 0 8px 0 var(--shadow-01);
        width: calc(100% - 32px);
        height: calc(100% - 32px);

        @media ${propGet('breakpoint.max.sm')} {
          max-width: 436px;
          margin: var(--spacing-3);
          width: calc(100% - 16px);
          height: calc(100% - 16px);
        }

        ${(!props.variant || props.variant === 'withTopbar') &&
        css`
          height: calc(100% - 88px);

          @media ${propGet('breakpoint.max.sm')} {
            height: calc(100% - 72px);
          }
        `}
      `}

    ${() => {
      const getLastAction = useGetLastAction();
      const lastAction = getLastAction();
      if (lastAction === 'SWITCH_SIDE_SHEET') {
        return css`
          animation: none;
        `;
      } else {
        return css`
          ${(props: SideSheetProps) =>
            !props.ui &&
            props.variant !== 'fullscreen' &&
            css`
              animation-name: side-sheet;
              animation-duration: 0.2s;
              animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            `}
        `;
      }
    }}
  }

  @keyframes side-sheet {
    0% {
      transform: translateX(100vw);
    }

    100% {
      width: translateX(0);
    }
  }
`;

export default SideSheet;

export { SideSheet, SideSheetBody, SideSheetHeader, SideSheetFooter };
