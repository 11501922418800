import { createGlobalStyle } from 'styled-components';
import { themeGetColor, propGet } from 're-design';

export const ResetStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
  }

  *, ::before, ::after {
    box-sizing: border-box;
  }

  abbr {
    text-decoration: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  html, body {
    width: 100%;
  }

  body {
    line-height: 1;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--ui-background-01);

    &.ModalOpen {
      overflow: hidden;
    }
    &.ReactModal__Body--open {
      @media ${propGet('breakpoint.max.sm')} {
        overflow: hidden;
      }
    }

    * {
      ::-webkit-scrollbar {
        background: none;
        width:8px;
        height:8px;
        @media ${propGet('breakpoint.max.sm')} {
          width:6px;
          height:6px;
        }
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: var(--ui-05);
        @media ${propGet('breakpoint.max.sm')} {
          border-radius: 3px;
        }
      }
    }
  }
  textarea, input, button, select {
    font-family: inherit;
    font-size: inherit;
  }

  img {
    max-width: 100%;
  }

  #hubspot-messages-iframe-container {
    z-index: var(--index-hubspot-iframe)!important;
  }
  .twitter-picker {
    box-shadow: none!important;
    background: var(--ui-background-02)!important;
  }
`;

export const RootStyle = createGlobalStyle`
  :root {
    //palette
    --ui-background-01: ${themeGetColor('ui-background-01')};
    --ui-background-02: ${themeGetColor('ui-background-02')};
    --ui-background-03: ${themeGetColor('ui-background-03')};
    
    --ui-01: ${themeGetColor('ui-01')};
    --ui-02: ${themeGetColor('ui-02')};
    --ui-03: ${themeGetColor('ui-03')};
    --ui-04: ${themeGetColor('ui-04')};
    --ui-05: ${themeGetColor('ui-05')};

    --interactive-01: ${themeGetColor('interactive-01')};
    --interactive-02: ${themeGetColor('interactive-02')};
    --interactive-03: ${themeGetColor('interactive-03')};
    --interactive-04: ${themeGetColor('interactive-04')};
    --interactive-05: ${themeGetColor('interactive-05')};
    --inverse-interactive: ${themeGetColor('inverse-interactive')};
    --hover-interactive-01: ${themeGetColor('hover-interactive-01')};
    --hover-interactive-02: ${themeGetColor('hover-interactive-02')};
    --hover-interactive-03: ${themeGetColor('hover-interactive-03')};
    --hover-interactive-04: ${themeGetColor('hover-interactive-04')};
    --hover-interactive-05: ${themeGetColor('hover-interactive-05')};

    --text-01: ${themeGetColor('text-01')};
    --text-02: ${themeGetColor('text-02')};
    --text-03: ${themeGetColor('text-03')};
    --text-04: ${themeGetColor('text-04')};
    --text-05: ${themeGetColor('text-05')};
    --text-error: ${themeGetColor('text-error')};
    --text-disabled: ${themeGetColor('text-disabled')};
    --text-success: ${themeGetColor('text-success')};

    --link: ${themeGetColor('link')};
    --link-visited: ${themeGetColor('link-visited')};
    --link-hover: ${themeGetColor('link-hover')};
    --link-active: ${themeGetColor('link-active')};

    --border-01: ${themeGetColor('border-01')};
    --border-02: ${themeGetColor('border-02')};
    --border-03: ${themeGetColor('border-03')};
    --border-04: ${themeGetColor('border-04')};

    --chart-background: ${themeGetColor('chart-background')};
    --chart-01: ${themeGetColor('chart-01')};
    --chart-02: ${themeGetColor('chart-02')};
    --chart-03: ${themeGetColor('chart-03')};
    --chart-04: ${themeGetColor('chart-04')};
    --chart-05: ${themeGetColor('chart-05')};
    --chart-06: ${themeGetColor('chart-06')};
    --chart-07: ${themeGetColor('chart-07')};
    --chart-08: ${themeGetColor('chart-08')};
    --chart-01-pale: ${themeGetColor('chart-01-pale')};
    --chart-02-pale: ${themeGetColor('chart-02-pale')};
    --chart-03-pale: ${themeGetColor('chart-03-pale')};

    --focus: ${themeGetColor('focus')};
    --focus-pale: ${themeGetColor('focus-pale')};
    --disabled: ${themeGetColor('disabled')};
    --disabled-checked: ${themeGetColor('disabled-checked')};
    --error: ${themeGetColor('error')};
    --error-pale: ${themeGetColor('error-pale')};

    --success: ${themeGetColor('success')};
    --success-pale: ${themeGetColor('success-pale')};
    --success-bold: ${themeGetColor('success-bold')};

    --shadow-01: ${themeGetColor('shadow-01')};
    --shadow-02: ${themeGetColor('shadow-02')};
    --shadow-03: ${themeGetColor('shadow-03')};
    --shadow-04: ${themeGetColor('shadow-04')};

    --field: ${themeGetColor('field')};

    --spacing-1: ${propGet('spacing.1')};
    --spacing-2: ${propGet('spacing.2')};
    --spacing-3: ${propGet('spacing.3')};
    --spacing-4: ${propGet('spacing.4')};
    --spacing-5: ${propGet('spacing.5')};
    --spacing-6: ${propGet('spacing.6')};
    --spacing-7: ${propGet('spacing.7')};
    --spacing-8: ${propGet('spacing.8')};
    --spacing-9: ${propGet('spacing.9')};
    --spacing-10: ${propGet('spacing.10')};

    --spacing-1-inverse: -${propGet('spacing.1')};
    --spacing-2-inverse: -${propGet('spacing.2')};
    --spacing-3-inverse: -${propGet('spacing.3')};
    --spacing-4-inverse: -${propGet('spacing.4')};
    --spacing-5-inverse: -${propGet('spacing.5')};
    --spacing-6-inverse: -${propGet('spacing.6')};
    --spacing-7-inverse: -${propGet('spacing.7')};
    --spacing-8-inverse: -${propGet('spacing.8')};
    --spacing-9-inverse: -${propGet('spacing.9')};
    --spacing-10-inverse: -${propGet('spacing.10')};

    --radius-none: ${propGet('radius.none')};
    --radius-tiny: ${propGet('radius.tiny')};
    --radius-small: ${propGet('radius.small')};
    --radius-normal: ${propGet('radius.normal')};
    --radius-medium: ${propGet('radius.medium')};
    --radius-large: ${propGet('radius.large')};
    --radius-circular: ${propGet('radius.circular')};

    --typo-size-min: ${propGet('typo.size.min')};
    --typo-size-tiny: ${propGet('typo.size.tiny')};
    --typo-size-small: ${propGet('typo.size.small')};
    --typo-size-std: ${propGet('typo.size.std')};
    --typo-size-normal: ${propGet('typo.size.normal')};
    --typo-size-mid: ${propGet('typo.size.mid')};
    --typo-size-big: ${propGet('typo.size.big')};
    --typo-size-large: ${propGet('typo.size.large')};
    --typo-size-max: ${propGet('typo.size.max')};

    --opacity-0: ${propGet('opacity.0')};
    --opacity-1: ${propGet('opacity.1')};
    --opacity-2: ${propGet('opacity.2')};
    --opacity-3: ${propGet('opacity.3')};
    --opacity-4: ${propGet('opacity.4')};
    --opacity-5: ${propGet('opacity.5')};
    --opacity-6: ${propGet('opacity.6')};
    --opacity-7: ${propGet('opacity.7')};
    --opacity-8: ${propGet('opacity.8')};
    --opacity-9: ${propGet('opacity.9')};
    --opacity-10: ${propGet('opacity.10')};

    //z-index
    --index-hubspot-iframe: 290;
    --index-sheet-template: 2000;
    --index-sidesheet: 3000;
    --index-modal: 4000;
    --index-fullscreen-modal: 5000;
    --index-datepicker: 10100;
    --index-tooltip: 10200;
    --index-popper: 10200;
    --index-modal-confirm: 11000;
    --index-toast: 9999999;
  }
`;
