import {
  Okr,
  Objective,
  ObjectiveSourceTypes,
  O3CompanyTopicStatuses,
  O3CompanyTopic,
  FeedbackSheetForm,
  FeedbackSheetGradeItem,
  FeedbackSheetGradeItemTemplate,
  FeedbackSheetNumberItem,
  FeedbackSheetObjectiveItem,
  FeedbackSheetCommentItem,
  FeedbackSheetItemTemplate,
  FeedbackSheetItemTypes,
  O3PreSurveyTopic,
  O3Meeting,
  FeedbackLayer,
  FeedbackLayerTypes,
  FeedbackMiddleLayer,
  SupportAction,
  SupportActionEventTypes,
  ManagementIndicator,
  ManagementIndicatorTypes,
  O3PairReport,
  O3Pair,
  KeyResult,
  OkrTypes,
  Team,
  FeedbackSheetLayerMemberStatuses,
  Period,
  PeriodType,
  ExportedFileStatuses,
} from '../generated/graphql';
import isSameDay from 'date-fns/isSameDay';
import isDate from 'date-fns/isDate';
import isEqual from 'date-fns/isEqual';
import compareDesc from 'date-fns/compareDesc';
import compareAsc from 'date-fns/compareAsc';

import { ImplementationItem } from '../common/hr/people-management/o3s/status';
import { SatisfactionRatingItem } from '../common/hr/people-management/o3s/rating';
import { FeedbackSheetMemberStatus, toFeedbackLayerSortOrder } from './feedback';
import { isNumber } from './lodash';
import { GroupByRequesterInterface } from '../components/molecules/FeedbackResponseStatus/presenter';

export const comparatorString = (a: string, b: string) => {
  return (a || '').localeCompare(b || '');
};

export const comparatorTitle = (a: any, b: any) => {
  if (!b || !b.title) return 1;
  if (!a || !a.title) return -1;
  return comparatorString(a.title, b.title);
};

export const SORT_ASC = 1;
export const SORT_DESC = -1;

function comparatorStringId(a: any, b: any) {
  if (+a && +b) return +a - +b;
  if (!+a && +b) return 1;
  if (+a && !+b) return -1;

  const [aType, aId] = a.toString().split(':');
  const [bType, bId] = b.toString().split(':');
  const typeCompared = aType.localeCompare(bType);
  return typeCompared || +aId - +bId;
}

export function comparatorId(a: any, b: any) {
  if (!a || !a.id) return 1;
  if (!b || !b.id) return -1;
  return comparatorStringId(a.id, b.id);
}
export function comparatorIdDesc(a: any, b: any) {
  return -comparatorId(a, b);
}

export function comparatorFileCreatedAtAsc(a: any, b: any) {
  if (!a || (!a.fileCreatedAt && !a.status)) return 1;
  if (!b || (!b.fileCreatedAt && !b.status)) return -1;

  if (a.status === ExportedFileStatuses.Requested && b.status !== ExportedFileStatuses.Requested) {
    return -1;
  } else if (
    a.status !== ExportedFileStatuses.Requested &&
    b.status === ExportedFileStatuses.Requested
  ) {
    return 1;
  }

  return compareDesc(new Date(a.fileCreatedAt), new Date(b.fileCreatedAt));
}

export function comparatorFileCreatedAtDesc(a: any, b: any) {
  return -comparatorFileCreatedAtAsc(a, b);
}

export function comparatorOrder(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;
  if (a.order === b.order) return comparatorId(a, b);
  return +a.order - +b.order;
}

export function comparatorTeamOrder(a: Record<string, Team>, b: Record<string, Team>) {
  if (!b) return 1;
  if (!a) return -1;
  return comparatorOrder(a.team, b.team);
}

export function comparatorObjectiveOrder(
  a: Record<string, Objective>,
  b: Record<string, Objective>,
) {
  if (!b) return 1;
  if (!a) return -1;
  return comparatorOrder(a.objective, b.objective);
}

export function comparatorItemId(a: any, b: any) {
  if (!a || !a.item) return 1;
  if (!b || !b.item) return -1;
  return comparatorStringId(a.item.id, b.item.id);
}

export function comparatorItemOrder(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;
  if (a.item.order === b.item.order) return comparatorItemId(a, b);
  return +a.item.order - +b.item.order;
}

export const comparatorObjectiveOwnerFactory = (sourceType: ObjectiveSourceTypes) => (
  a: Objective,
  b: Objective,
): number => {
  switch (sourceType) {
    case ObjectiveSourceTypes.User: {
      if (!b) return 1;
      if (!a) return -1;
      if (!b.user || !b.user.displayName) return 1;
      if (!a.user || !a.user.displayName) return -1;
      const cmp = (a.user.displayName || '').localeCompare(b.user.displayName || '');
      return cmp || comparatorOrder(a, b);
    }
    case ObjectiveSourceTypes.Team: {
      if (!b) return 1;
      if (!a) return -1;
      if (!b.team || !b.team.name) return 1;
      if (!a.team || !a.team.name) return -1;
      const cmp = (a.team.name || '').localeCompare(b.team.name || '');
      return cmp || comparatorOrder(a, b);
    }
    default:
      return comparatorOrder(a, b);
  }
};

export function comparatorDescDonePostSurveyAt(a: O3Meeting, b: O3Meeting) {
  if (!b) return 1;
  if (!a) return -1;
  const cmp = b.donePostSurveyAt - a.donePostSurveyAt;
  return cmp || +b.id - +a.id;
}

const getTopicPriority = (status?: string) => {
  switch (status) {
    case O3CompanyTopicStatuses.Active:
      return 1;
    case O3CompanyTopicStatuses.Inactive:
      return 100;
    case O3CompanyTopicStatuses.Deleted:
      return 1000;
    case undefined:
      return 10;
    default:
      return 10;
  }
};

export const comparatorTopicStatus = (a: O3CompanyTopic, b: O3CompanyTopic) => {
  if (a.status === b.status) return 0;
  return getTopicPriority(a.status) - getTopicPriority(b.status);
};

export const comparatorByProperties = (sortBy: any[]) => (a: any, b: any) => {
  let i = 0;
  let result = 0;

  while (i < sortBy.length && result === 0) {
    let direction = 1;
    let prop;
    if (sortBy[i] instanceof Array) {
      direction = sortBy[i][1] || 0;
      prop = sortBy[i][0] || '';
    } else {
      prop = sortBy[i] || '';
    }

    const nestedProp = prop.split('.');

    let varA = a;
    nestedProp.forEach((x: any) => {
      varA = (varA || {})[x];
    });

    let varB = b;
    nestedProp.forEach((x: any) => {
      varB = (varB || {})[x];
    });

    const isVarAInvalid = varA === undefined || varA === null;
    const isVarBInvalid = varB === undefined || varB === null;

    if (!isVarAInvalid && isVarBInvalid) {
      return direction * -1;
    }

    if (isVarAInvalid && !isVarBInvalid) {
      return direction;
    }

    if (!isVarAInvalid && !isVarBInvalid) {
      if (typeof varA === 'string' && typeof varB === 'string') {
        result = direction * comparatorString(varA, varB);
      } else if (Number.isFinite(varA) && Number.isFinite(varB)) {
        result = direction * (varA - varB);
      } else {
        result = direction * (varA < varB ? -1 : varA > varB ? 1 : 0);
      }
    }
    if (result !== 0) break;
    i++;
  }
  return result;
};

export const comparatorFeedbackSheetGradeItem = (
  a?: FeedbackSheetGradeItem,
  b?: FeedbackSheetGradeItem,
) => {
  if (!a?.grade && !b?.grade) return 0;
  if (!a?.grade) return 1;
  if (!b?.grade) return -1;

  const grades = (a?.itemTemplate as FeedbackSheetGradeItemTemplate)?.gradeOptions?.map(
    o => o?.grade,
  );
  if (!grades) return 0;

  const _ai = grades.findIndex(grade => grade === a?.grade);
  const _bi = grades.findIndex(grade => grade === b?.grade);
  const ai = _ai < 0 ? grades.length : _ai;
  const bi = _bi < 0 ? grades.length : _bi;
  return ai - bi;
};

export const comparatorFeedbackSheetNumberItem = (
  a?: FeedbackSheetNumberItem,
  b?: FeedbackSheetNumberItem,
) => {
  if (!isNumber(a?.value) && !isNumber(b?.value)) return 0;
  if (!isNumber(a?.value)) return 1;
  if (!isNumber(b?.value)) return -1;
  return a!.value! - b!.value!;
};

export const comparatorFeedbackSheetCommentItem = (
  a?: FeedbackSheetCommentItem,
  b?: FeedbackSheetCommentItem,
) => {
  if (!a?.comment?.text && !b?.comment?.text) return 0;
  if (!a?.comment?.text) return 1;
  if (!b?.comment?.text) return -1;
  return comparatorString(a?.comment?.text, b?.comment?.text);
};

export const comparatorFeedbackSheetObjectiveItem = (
  a?: FeedbackSheetObjectiveItem,
  b?: FeedbackSheetObjectiveItem,
) => {
  // TODO:
  //
  return 0;
};

export const comparatorFeedbackSheetFormReceiver = (
  a?: FeedbackSheetForm,
  b?: FeedbackSheetForm,
) => {
  if (!a?.to && !b?.to) return 0;
  if (!a?.to) return 1;
  if (!b?.to) return -1;
  return comparatorDisplayName(a.to, b.to);
};

export const comparatorFeedbackSheetFormSender = (a?: FeedbackSheetForm, b?: FeedbackSheetForm) => {
  const hasFromA = (a?.from?.length || 0) > 0;
  const hasFromB = (b?.from?.length || 0) > 0;
  if (!hasFromA && !hasFromB) return 0;
  if (!hasFromA) return 1;
  if (!hasFromB) return -1;
  return comparatorDisplayName(a!.from![0], b!.from![0]);
};

export const comparatorFeedbackSheetFormLayer = (a?: FeedbackSheetForm, b?: FeedbackSheetForm) => {
  if (!a?.layer?.type && !b?.layer?.type) return 0;
  if (!a?.layer?.type) return 1;
  if (!b?.layer?.type) return -1;
  return toFeedbackLayerSortOrder(a.layer) - toFeedbackLayerSortOrder(b.layer);
};

export const comparatorFeedbackSheetItem = (
  itemTemplates: FeedbackSheetItemTemplate[],
  sortKey: string,
) => {
  if (!(sortKey?.length > 0)) return () => 0;
  const order = sortKey.endsWith('A') ? 1 : -1;
  const itemTemplateId = sortKey.substring(0, sortKey.length - 1);
  const itemTemplate = itemTemplates?.find(itemTemplate => +itemTemplate.id === +itemTemplateId);

  return (a?: FeedbackSheetForm, b?: FeedbackSheetForm): number => {
    // receiver でソート
    if (itemTemplateId === 'to') {
      return order * comparatorFeedbackSheetFormReceiver(a, b);
    }
    // sender でソート
    if (itemTemplateId === 'from') {
      return order * comparatorFeedbackSheetFormSender(a, b);
    }
    if (itemTemplateId === 'team') {
      return order * comparatorName(a?.to?.teams?.[0], b?.to?.teams?.[0]);
    }
    // layer でソート
    if (itemTemplateId === 'layer') {
      return order * comparatorFeedbackSheetFormLayer(a, b);
    }

    // item でソート
    if (!itemTemplate) return 0;
    const itemA = a?.items?.find(item => item?.itemTemplateId === itemTemplateId);
    const itemB = b?.items?.find(item => item?.itemTemplateId === itemTemplateId);
    if (!itemA && !itemB) return 0;
    if (!itemA) return 1;
    if (!itemB) return -1;

    itemA.itemTemplate = itemTemplate;
    itemB.itemTemplate = itemTemplate;
    switch (itemTemplate.type) {
      case FeedbackSheetItemTypes.Grade: {
        return (
          order *
          comparatorFeedbackSheetGradeItem(
            itemA as FeedbackSheetGradeItem,
            itemB as FeedbackSheetGradeItem,
          )
        );
      }
      case FeedbackSheetItemTypes.Number: {
        return (
          order *
          comparatorFeedbackSheetNumberItem(
            itemA as FeedbackSheetNumberItem,
            itemB as FeedbackSheetNumberItem,
          )
        );
      }
      case FeedbackSheetItemTypes.Comment: {
        return (
          order *
          comparatorFeedbackSheetCommentItem(
            itemA as FeedbackSheetCommentItem,
            itemB as FeedbackSheetCommentItem,
          )
        );
      }
      case FeedbackSheetItemTypes.Objective: {
        return (
          order *
          comparatorFeedbackSheetObjectiveItem(
            itemA as FeedbackSheetObjectiveItem,
            itemB as FeedbackSheetObjectiveItem,
          )
        );
      }
      default: {
        return 0;
      }
    }
  };
};

export const comparatorDateDesc = (a: any, b: any) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!isDate(new Date(b.date))) return 1;
  if (!isDate(new Date(a.date))) return -1;
  if (isSameDay(new Date(a.date), new Date(b.date))) return comparatorId(a, b);
  return compareDesc(new Date(a.date), new Date(b.date));
};

export const comparatorDateAsc = (a: any, b: any) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!isDate(new Date(b.date))) return 1;
  if (!isDate(new Date(a.date))) return -1;
  if (isSameDay(new Date(a.date), new Date(b.date))) return comparatorId(a, b);
  return compareAsc(new Date(a.date), new Date(b.date));
};

export const comparatorCreatedAtDesc = (a: any, b: any) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!isDate(new Date(b.createdAt))) return 1;
  if (!isDate(new Date(a.createdAt))) return -1;
  if (isSameDay(new Date(a.createdAt), new Date(b.createdAt))) return comparatorId(a, b);
  return compareDesc(new Date(a.createdAt), new Date(b.createdAt));
};

export const comparatorCreatedAtAsc = (a: any, b: any) => {
  return -comparatorCreatedAtDesc(a, b);
};

export const comparatorEndAtDesc = (a: any, b: any) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!isDate(new Date(b.endAt))) return 1;
  if (!isDate(new Date(a.endAt))) return -1;
  if (isSameDay(new Date(a.endAt), new Date(b.endAt))) return comparatorId(a, b);
  return compareDesc(new Date(a.endAt), new Date(b.endAt));
};

export const comparatorKeyAsDate = (a: any, b: any, key: string) => {
  const _key = key || 'date';

  if (!a && !b) return comparatorId(a, b);
  if (!b) return 1;
  if (!a) return -1;

  const _dateA = new Date(a[_key]);
  const _dateB = new Date(b[_key]);
  if (!isDate(_dateA) && !isDate(_dateB)) return comparatorId(a, b);
  if (!isDate(_dateB)) return 1;
  if (!isDate(_dateA)) return -1;
  if (isSameDay(_dateA, _dateB)) return comparatorId(a, b);

  return compareAsc(_dateA, _dateB);
};

export const comparatorPinnedTopic = (a: O3PreSurveyTopic, b: O3PreSurveyTopic) => {
  if (!b) return 1;
  if (!a) return -1;
  if (a.pinned && !b.pinned) return -1;
  if (b.pinned && !a.pinned) return 1;
  return comparatorOrder(a, b);
};

export const comparatorActiveTopic = (a: any, b: any) => {
  if (!b) return 1;
  if (!a) return -1;
  if (a.isActive && !b.isActive) return -1;
  if (b.isActive && !a.isActive) return 1;
  if (a.isActive && b.isActive) return comparatorOrder(a, b);
  return comparatorId(a, b);
};

export function comparatorDisplayName(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;
  const cmp = (a.displayName || '').localeCompare(b.displayName || '');
  return cmp || +a.id - +b.id;
}

export function comparatorUserOptionsDisplayName(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;
  const cmp = (a?.user?.displayName || '').localeCompare(b?.user?.displayName || '');
  return cmp || +a.id - +b.id;
}

export function comparatorAssigneeDisplayName(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;
  const cmp = (a?.assignee?.displayName || '').localeCompare(b?.assignee?.displayName || '');
  return cmp || +a.id - +b.id;
}

const getEntityOptionTypePriority = (entityOption?: any) => {
  if (entityOption?.team) return 10;
  if (entityOption?.groupTag) return 20;
  if (entityOption?.user) return 30;
  return 10000;
};

export function comparatorEntityOptionTypes(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;
  return getEntityOptionTypePriority(a) - getEntityOptionTypePriority(b);

  // if (a?.user) return 1;
  // if (a?.team) return -1;
  // if (a?.groupTag) return -1;
  // return +a.id - +b.id;
}

export function comparatorEntityOptions(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;

  if (a?.team) return comparatorTeamName(a, b);
  if (a?.user) return comparatorUserOptionsDisplayName(a, b);
  if (a?.groupTag) return comparatorGroupTagName(a, b);

  return +a.id - +b.id;
}

const getSourceTypePriority = (sourceType: ObjectiveSourceTypes) => {
  switch (sourceType) {
    case ObjectiveSourceTypes.User:
      return 1;
    case ObjectiveSourceTypes.Team:
      return 100;
    case ObjectiveSourceTypes.Company:
      return 1000;
    default:
      return 10;
  }
};

export const comparatorObjectiveSourceType = (a: Objective, b: Objective) => {
  if (!b) return 1;
  if (!a) return -1;
  if (a.sourceType && !b.sourceType) return -1;
  if (b.sourceType && !a.sourceType) return 1;
  if (!a.sourceType && !b.sourceType) return comparatorTitle(a, b);
  return getSourceTypePriority(a.sourceType!) - getSourceTypePriority(b.sourceType!);
};

export const comparatorObjectiveCategory = (a: Objective, b: Objective) => {
  if (!a.category && !b.category) return 0;
  if (!b.category) return -1;
  if (!a.category) return 1;
  return comparatorOrder(a.category, b.category);
};

const getTypenamePriority = (__typenameOrOkrType: string) => {
  switch (__typenameOrOkrType) {
    case 'Objective':
    case 'objective':
      return 1;
    case 'KeyResult':
    case 'keyResult':
      return 10;
    default:
      return 100;
  }
};

export const comparatorOkr = (a: Okr, b: Okr) => {
  const getObjective = (okr: Okr): Objective | null => {
    if (!okr.okrType) return null;
    if (okr.okrType === OkrTypes.Objective) return okr as Objective;
    if (okr.okrType === OkrTypes.KeyResult) return (okr as KeyResult).parent as Objective;
    return null;
  };

  const _a = getObjective(a);
  const _b = getObjective(b);

  if (!_b) return 1;
  if (!_a) return -1;

  // OのsourceTypeが違う場合sourceType順でsort
  if (_a?.sourceType !== _b?.sourceType) return comparatorObjectiveSourceType(_a!, _b!);

  // sourceTypeが一緒でidが違う場合タイトル順でsort
  if (_a.id !== _b.id) return comparatorTitle(_a, _b) || +_a.id - +_b.id;

  // sourceTypeが一緒でidが一緒の場合、同じ目標のOまたはKRなので、KR同士の場合orderでsort
  if (a.okrType === b.okrType) return comparatorOrder(a, b);

  // OとKRだったらOが先にくるようにsort
  return getTypenamePriority(a.okrType!) - getTypenamePriority(b.okrType!);
};

export const comparatorObjectiveAchievementRateDesc = (a: Objective, b: Objective) => {
  if (!b) return 1;
  if (!a) return -1;
  return +b.achievementRate! - +a.achievementRate!;
};

export const comparatorObjectiveAchievementRateAsc = (a: Objective, b: Objective) => {
  if (!b) return 1;
  if (!a) return -1;
  return +a.achievementRate! - +b.achievementRate!;
};

export const comparatorObjectiveUpdatedAtDesc = (a: Objective, b: Objective) => {
  if (!b) return 1;
  if (!a) return -1;
  const aDate = new Date(a.achievementRateUpdatedAt || a.indicatorUpdatedAt || a.updatedAt);
  const bDate = new Date(b.achievementRateUpdatedAt || b.indicatorUpdatedAt || b.updatedAt);
  if (!isDate(bDate)) return 1;
  if (!isDate(aDate)) return -1;
  if (isEqual(aDate, bDate)) return comparatorId(a, b);
  return compareDesc(aDate, bDate);
};

export const comparatorObjectiveUpdatedAtAsc = (a: Objective, b: Objective) => {
  if (!b) return 1;
  if (!a) return -1;
  const aDate = new Date(a.achievementRateUpdatedAt || a.indicatorUpdatedAt || a.updatedAt);
  const bDate = new Date(b.achievementRateUpdatedAt || b.indicatorUpdatedAt || b.updatedAt);
  if (!isDate(bDate)) return 1;
  if (!isDate(aDate)) return -1;
  if (isEqual(aDate, bDate)) return comparatorId(a, b);
  return compareAsc(aDate, bDate);
};

interface ObjectWithLayer extends AnyProperties {
  layer: FeedbackLayer & FeedbackMiddleLayer;
}

const defaultLayerOrder = {
  [FeedbackLayerTypes.Self]: 0,
  [FeedbackLayerTypes.Middle]: {
    1: 10,
    2: 20,
    3: 30,
  },
  [FeedbackLayerTypes.Final]: 100,
  [FeedbackLayerTypes.Read]: 1000,
  [FeedbackLayerTypes.Supervise]: 10000,
};

type LayerOrder = typeof defaultLayerOrder;

export const comparatorFeedbackLayer = (
  a: FeedbackLayer & FeedbackMiddleLayer,
  b: FeedbackLayer & FeedbackMiddleLayer,
  layerOrder: LayerOrder = defaultLayerOrder,
) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!b.type) return 1;
  if (!a.type) return -1;
  const aOrder =
    a.type === FeedbackLayerTypes.Middle
      ? layerOrder[a.type!][a.order as 1 | 2 | 3]
      : layerOrder[a.type!];
  const bOrder =
    b.type === FeedbackLayerTypes.Middle
      ? layerOrder[b.type!][b.order as 1 | 2 | 3]
      : layerOrder[b.type];

  return aOrder - bOrder;
};

export const comparatorFormsTemplateItem = (a: ObjectWithLayer, b: ObjectWithLayer) => {
  return comparatorFeedbackLayer(a.layer, b.layer, {
    [FeedbackLayerTypes.Self]: 0,
    [FeedbackLayerTypes.Final]: 10,
    [FeedbackLayerTypes.Middle]: {
      3: 100,
      2: 200,
      1: 300,
    },
    [FeedbackLayerTypes.Read]: 1000,
    [FeedbackLayerTypes.Supervise]: 10000,
  });
};

export const comparatorFeedbackTemplateItems = (a: ObjectWithLayer, b: ObjectWithLayer) => {
  return comparatorFeedbackLayer(b.layer, a.layer, {
    [FeedbackLayerTypes.Self]: 0,
    [FeedbackLayerTypes.Final]: 400,
    [FeedbackLayerTypes.Middle]: {
      3: 300,
      2: 200,
      1: 100,
    },
    [FeedbackLayerTypes.Read]: 500,
    [FeedbackLayerTypes.Supervise]: 5000,
  });
};

const supportActionEventTypesOrder = [
  SupportActionEventTypes.Unknown,
  SupportActionEventTypes.CustomCreated,
  SupportActionEventTypes.CustomCreatedViaObjective,
  SupportActionEventTypes.CustomCreatedViaMeeting,
  SupportActionEventTypes.CustomCreatedViaFeedbackSheet,
  SupportActionEventTypes.TopicPostedViaCheckInForm,
  SupportActionEventTypes.RainingConditionedViaCheckInForm,
  SupportActionEventTypes.LowActionScoreViaCheckInForm,
  SupportActionEventTypes.LowCommunicationScoreViaCheckInForm,
  SupportActionEventTypes.CommentPostedViaCheckInForm,
];

export const comparatorSupportActionEventType = (a: SupportAction, b: SupportAction) => {
  if (a.eventType === b.eventType) {
    return comparatorIdDesc(a, b);
  }
  return (
    supportActionEventTypesOrder.indexOf(a.eventType as SupportActionEventTypes) -
    supportActionEventTypesOrder.indexOf(b.eventType as SupportActionEventTypes)
  );
};

const managementIndicatorTypesOrder = [
  ManagementIndicatorTypes.CheckInCondition,
  ManagementIndicatorTypes.ObjectiveAchievementRate,
  ManagementIndicatorTypes.ObjectiveUpdateRate,
  ManagementIndicatorTypes.CheckInActionScore,
  ManagementIndicatorTypes.CheckInCommunicationScore,
  ManagementIndicatorTypes.O3MeetingDoneRate,
];

export const comparatorManagementIndicatorType = (
  a: ManagementIndicator,
  b: ManagementIndicator,
) => {
  if (a?.type === b?.type) {
    return comparatorId(a, b);
  }

  return (
    managementIndicatorTypesOrder.indexOf(a?.type) - managementIndicatorTypesOrder.indexOf(b?.type)
  );
};

export const comparatorTeamName = (a: ImplementationItem, b: ImplementationItem) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!b.team || !b.team.name) return 1;
  if (!a.team || !a.team.name) return -1;
  const cmp = (a.team.name || '').localeCompare(b.team.name || '');
  return cmp || +a.team.id - +b.team.id;
};

export const comparatorName = (a: any, b: any) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!b.name) return 1;
  if (!a.name) return -1;
  return (a.name || '').localeCompare(b.name || '');
};

export const comparatorGroupTagName = (a: ImplementationItem, b: ImplementationItem) => {
  if (!b) return 1;
  if (!a) return -1;
  if (!b.groupTag || !b.groupTag.name) return 1;
  if (!a.groupTag || !a.groupTag.name) return -1;
  const cmp = (a.groupTag.name || '').localeCompare(b.groupTag.name || '');
  return cmp || +a.groupTag.id - +b.groupTag.id;
};

export const comparatorTotalCount = (a: ImplementationItem, b: ImplementationItem) => {
  if (!b) return 1;
  if (!a) return -1;
  const cmp = a.totalCount! - b.totalCount!;
  return cmp || +a.member?.id! - +b.member?.id!;
};

export const comparatorNoPreSurvey = (a: ImplementationItem, b: ImplementationItem) => {
  if (!b) return 1;
  if (!a) return -1;
  const cmp = a.summary.noPreSurvey - b.summary.noPreSurvey;
  return cmp || +a.member?.id! - +b.member?.id!;
};

export const comparatorVeryNotFulfilling = (
  a: SatisfactionRatingItem,
  b: SatisfactionRatingItem,
) => {
  const a_verynotfulfilling =
    a?.data?.verynotfulfilling === undefined ? -1 : a?.data?.verynotfulfilling;
  const b_verynotfulfilling =
    b?.data?.verynotfulfilling === undefined ? -1 : b?.data?.verynotfulfilling;
  const cmp = a_verynotfulfilling - b_verynotfulfilling;
  return cmp || +a.member?.id! - +b.member?.id!;
};

export const comparatorNotFulfilling = (a: SatisfactionRatingItem, b: SatisfactionRatingItem) => {
  const a_notfulfilling = a?.data?.notfulfilling === undefined ? -1 : a?.data?.notfulfilling;
  const b_notfulfilling = b?.data?.notfulfilling === undefined ? -1 : b?.data?.notfulfilling;
  const cmp = a_notfulfilling - b_notfulfilling;
  return cmp || +a.member?.id! - +b.member?.id!;
};

export const comparatorFulfilling = (a: SatisfactionRatingItem, b: SatisfactionRatingItem) => {
  const a_fulfilling = a?.data?.fulfilling === undefined ? -1 : a?.data?.fulfilling;
  const b_fulfilling = b?.data?.fulfilling === undefined ? -1 : b?.data?.fulfilling;
  const cmp = a_fulfilling - b_fulfilling;
  return cmp || +a.member?.id! - +b.member?.id!;
};

export const comparatorVeryFulfilling = (a: SatisfactionRatingItem, b: SatisfactionRatingItem) => {
  const a_veryfulfilling = a?.data?.veryfulfilling === undefined ? -1 : a?.data?.veryfulfilling;
  const b_veryfulfilling = b?.data?.veryfulfilling === undefined ? -1 : b?.data?.veryfulfilling;

  const cmp = a_veryfulfilling - b_veryfulfilling;
  return cmp || +a.member?.id! - +b.member?.id!;
};

export function comparatorFeedbackSheetTemplateWrapper(a: any, b: any) {
  if (!a) return -1;
  if (!b) return 1;
  if (a.isDefault && b.isDefault) return comparatorOrder(a, b);
  if (a.isDefault) return -1;
  if (b.isDefault) return 1;
  return comparatorOrder(a, b);
}

export function comparatorO3PairMentee(a: O3Pair, b: O3Pair) {
  if (!b?.mentee?.displayName) return 1;
  if (!a?.mentee?.displayName) return -1;
  return comparatorString(a.mentee.displayName!, b.mentee.displayName!);
}

export function comparatorO3PairMentor(a: O3Pair, b: O3Pair) {
  if (!b?.mentor?.displayName) return 1;
  if (!a?.mentor?.displayName) return -1;
  return comparatorString(a.mentor.displayName!, b.mentor.displayName!);
}

export function comparatorO3PairReportMentee(a: O3PairReport, b: O3PairReport) {
  if (!b?.pair?.mentee?.displayName) return 1;
  if (!a?.pair?.mentee?.displayName) return -1;
  return comparatorString(a.pair.mentee.displayName!, b.pair.mentee.displayName!);
}

export function comparatorO3PairReportMentor(a: O3PairReport, b: O3PairReport) {
  if (!b?.pair?.mentor?.displayName) return 1;
  if (!a?.pair?.mentor?.displayName) return -1;
  return comparatorString(a.pair.mentor.displayName!, b.pair.mentor.displayName!);
}

export function comparatorBooleanTrueFirst(a: boolean, b: boolean) {
  return a === b ? 0 : a ? -1 : 1;
}

export function comparatorFeedbackStatuses(
  a: Record<string, FeedbackSheetLayerMemberStatuses>,
  b: Record<string, FeedbackSheetLayerMemberStatuses>,
) {
  return comparatorString(b.status, a.status);
}

export function comparatorFeedbackSheetMember(
  a: Partial<FeedbackSheetMemberStatus>,
  b: Partial<FeedbackSheetMemberStatus>,
) {
  if (a.isRequesting && !b.isRequesting) {
    return -1;
  } else if (!a.isRequesting && b.isRequesting) {
    return 1;
  } else {
    const layerOrder: Record<FeedbackLayerTypes, number> = {
      [FeedbackLayerTypes.Self]: 0,
      [FeedbackLayerTypes.Middle]: 1,
      [FeedbackLayerTypes.Final]: 2,
      [FeedbackLayerTypes.Read]: 3,
      [FeedbackLayerTypes.Supervise]: 4,
    };
    const layerDiff = layerOrder[a.layer?.type!] - layerOrder[b.layer?.type!];
    if (layerDiff !== 0) {
      return layerDiff;
    } else if (
      a.layer?.type === FeedbackLayerTypes.Middle &&
      b.layer?.type === FeedbackLayerTypes.Middle
    ) {
      const orderDiff = (a.layer?.order || 0) - (b.layer?.order || 0);
      if (orderDiff !== 0) {
        return orderDiff;
      } else {
        return comparatorDisplayName(a.user, b.user);
      }
    } else {
      return comparatorDisplayName(a.user, b.user);
    }
  }
}

export const comparatorPeriodByPeriodType = (a: Period, b: Period) => {
  const periodOrder = {
    [PeriodType.Year]: 4,
    [PeriodType.HalfYear]: 3,
    [PeriodType.Quarter]: 2,
    [PeriodType.FourMonth]: 1,
    [PeriodType.Month]: 0,
  };

  return periodOrder[a.type as PeriodType] - periodOrder[b.type as PeriodType];
};

export function comparatorObjectiveTreeOrder(a: any, b: any) {
  if (!b) return 1;
  if (!a) return -1;

  const aIsKeyResult = a.okrType === OkrTypes.KeyResult;
  const bIsKeyResult = b.okrType === OkrTypes.KeyResult;

  if (aIsKeyResult && !bIsKeyResult) {
    return -1;
  } else if (!aIsKeyResult && bIsKeyResult) {
    return 1;
  } else {
    return comparatorOrder(a, b);
  }
}

export function comparatorNestedPath(a: any, b: any) {
  if (!b || !b.path) return 1;
  if (!a || !a.path) return -1;

  const pathA = a.path.split('/').map(Number);
  const pathB = b.path.split('/').map(Number);

  const minLength = Math.min(pathA.length, pathB.length);
  for (let i = 0; i < minLength; i++) {
    if (pathA[i] !== pathB[i]) {
      return pathA[i] - pathB[i];
    }
  }

  return pathA.length - pathB.length;
}


export function comparatorFeedbackGroupByRequesters (a: GroupByRequesterInterface, b: GroupByRequesterInterface) {
  const sortOrder: Record<FeedbackLayerTypes, number> = {
    [FeedbackLayerTypes.Self]: 100,
    [FeedbackLayerTypes.Final]: 200,
    [FeedbackLayerTypes.Middle]: 300,
    [FeedbackLayerTypes.Read]: 0,
    [FeedbackLayerTypes.Supervise]: 0,
  };
  const volontaryOrder = 400;

  const orderA = (() => {
    if(a.isVoluntary) {
      return volontaryOrder;
    }
    return a.requesterType ? sortOrder[a.requesterType] || 0 : a.index;
  })();

  const orderB = (() => {
    if(b.isVoluntary) {
      return volontaryOrder;
    }
    return b.requesterType ? sortOrder[b.requesterType] || 0 : a.index;
  })();

  if (orderA === 0 && orderB === 0) {
    return a.index - b.index;
  }

  return orderA - orderB;
}