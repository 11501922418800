import React, { FC, memo } from 'react';
import { WarningBarProps } from './types';
import { WarningBarWrapper, WarningBarInner, WarningBarMessage, WarningBarLink } from './styled';

const WarningBar: FC<WarningBarProps> = memo<WarningBarProps>(
  ({ url, children, variant = 'default' }) => {
    return (
      <WarningBarWrapper variant={variant}>
        <WarningBarInner>
          {url ? (
            <WarningBarLink to={url}>{children}</WarningBarLink>
          ) : (
            <WarningBarMessage>{children}</WarningBarMessage>
          )}
        </WarningBarInner>
      </WarningBarWrapper>
    );
  },
);

export default WarningBar;
