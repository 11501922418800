import { ApolloLink, Operation, NextLink } from 'apollo-link';

import pkg from '../../../package.json';

import showVersionUpToast from './toast';

type VersionUpLinkResponse = {
  extensions?: {
    apiVersion?: string;
  };
};

class VersionUpLink extends ApolloLink {
  request(operation: Operation, forward: NextLink) {
    return forward(operation).map((response: VersionUpLinkResponse) => {
      const context = operation.getContext();
      const headers = context.response.headers;
      const apiVersion = headers.get('X-API-Version');
      if (pkg.version !== apiVersion) {
        showVersionUpToast();
      }
      return response;
    });
  }
}

export default VersionUpLink;
