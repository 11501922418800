import i18next from 'i18next';
import { omit, uniqBy } from './lodash';
import {
  User,
  Team,
  Maybe,
  TeamStatuses,
  PeriodTeam,
  GetTeamTreeQuery,
  GetTeamTreeQueryVariables,
  GetTeamTree,
  GetPeriodTeamTreeQuery,
  GetPeriodTeamTreeQueryVariables,
  GetPeriodTeamTree,
  GetPeriodTeamsFilterInput,
} from '../generated/graphql';
import { createReusableQueryHook } from '../lib/apollo-client';
import { comparatorDisplayName } from './comparators';
import { useMe } from '../lib/use-me';
import {
  hasElement,
  takeAwayEmptyElement,
  takeAwayMaybeElement,
  sortWithoutBreaking,
} from './array';
import { getActiveUsers, periodUsersToUsers } from './user';

export type TeamOption = {
  key: string;
  label: string;
  value: string;
  team?: Team;
};

export const getTeamOptions = (teams: Maybe<Maybe<Team>>[] | undefined | null) => {
  const options: SelectOption[] = takeAwayMaybeElement<Team[]>(teams).map(team => ({
    value: team.id!,
    label: team.name!,
  }));
  return options;
};

export const getTeamItem = (team: Team): TeamOption => ({
  key: `team:${team?.id}`,
  label: team?.name!,
  value: team?.id!,
  team,
});

export const getTeamsText = (
  teams: Team[],
  member: User | null,
  _default?: string,
  separator?: string,
) =>
  teams
    ?.map(team => {
      const isManager = member
        ? getActiveUsers(team?.managers).some(m => m.id === member?.id)
        : false;
      const appendix = isManager ? `（${i18next.t('オーナー')}）` : '';
      return team?.name + appendix;
    })
    .join(separator || '\n') ||
  _default ||
  '';

export const getActiveTeams = (teams: any | any[]): Team[] =>
  takeAwayEmptyElement<Team[]>(teams).filter(t => t.status === TeamStatuses.Active);

export const getTeamsTextFromMember = (member: User, _default?: string, separator?: string) =>
  getTeamsText(getActiveTeams(member?.teams), member, _default, separator);

export const isTeamManager = (team: Team, userId: ID) =>
  team?.managers?.some(m => m?.id === userId);

export const periodTeamToTeam = (periodTeam: Maybe<PeriodTeam> | undefined | null): Team | null => {
  if (!periodTeam) {
    return null;
  }
  return {
    id: periodTeam.teamId,
    __typename: 'Team',
    parent: periodTeam?.parent ? periodTeamToTeam(periodTeam?.parent) : null,
    children: hasElement(periodTeam?.children) ? periodTeamsToTeams(periodTeam?.children) : null,
    managers: hasElement(periodTeam?.managers) ? periodUsersToUsers(periodTeam?.managers) : null,
    members: hasElement(periodTeam?.members) ? periodUsersToUsers(periodTeam?.members) : null,
    subTeamManagers: hasElement(periodTeam?.subTeamManagers)
      ? periodUsersToUsers(periodTeam?.subTeamManagers)
      : null,
    subTeamMembers: hasElement(periodTeam?.subTeamMembers)
      ? periodUsersToUsers(periodTeam?.subTeamMembers)
      : null,
    status: periodTeam?.status === 'active' ? TeamStatuses.Active : TeamStatuses.Deleted,
    ...omit(periodTeam, [
      'teamId',
      'periodId',
      '__typename',
      'parent',
      'children',
      'managers',
      'subTeamManagers',
      'members',
      'subTeamMembers',
      'status',
    ]),
  };
};

export const periodTeamsToTeams = (
  periodTeams: Maybe<Maybe<PeriodTeam>[]> | undefined | null,
): Team[] => {
  return takeAwayMaybeElement<Team[]>(periodTeams?.map(periodTeam => periodTeamToTeam(periodTeam)));
};

export const useGetTeamTree = (
  periodId?: string | undefined | null,
  filters: Partial<GetPeriodTeamsFilterInput> = {},
) => {
  const me = useMe();

  if (periodId) {
    return createReusableQueryHook<GetPeriodTeamTreeQuery, GetPeriodTeamTreeQueryVariables>(
      GetPeriodTeamTree,
      {
        filters: {
          depth: 1,
          periodId,
          ...filters,
        },
        getManagers: true,
        getMembers: true,
        companyId: me?.company?.id!,
      },
    );
  }

  return createReusableQueryHook<GetTeamTreeQuery, GetTeamTreeQueryVariables>(GetTeamTree, {
    filters: {
      depth: 1,
      ...filters,
    },
    getManagers: true,
    getMembers: true,
    companyId: me?.company?.id!,
  });
};

export const getTeamAllMembers = (team: Maybe<Team> | undefined): User[] =>
  sortWithoutBreaking(
    uniqBy(
      [
        ...takeAwayEmptyElement<User[]>(team?.members),
        ...takeAwayEmptyElement<User[]>(team?.managers),
        ...takeAwayEmptyElement<User[]>(team?.subTeamMembers),
        ...takeAwayEmptyElement<User[]>(team?.subTeamManagers),
      ],
      'id',
    ),
    comparatorDisplayName,
  );

export const getTeamIdsFromPath = (team: Maybe<Team> | undefined): string[] =>
  takeAwayEmptyElement<string[]>((team?.path || '').split('/'));

export const getParentTeamIdsFromPath = (team: Maybe<Team> | undefined): string[] => {
  const ids = getTeamIdsFromPath(team);
  const teamSplitIndex = ids.findIndex(id => id === team?.id!);
  if (teamSplitIndex === -1) {
    return ids;
  }
  ids.splice(teamSplitIndex, ids.length);
  return ids;
};
