import styled, { css } from 'styled-components';
import { propGet } from 're-design';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 162px);
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Wrap = styled.div`
  padding: var(--spacing-5);
  text-align: center;
  width: 640px;

  @media ${propGet('breakpoint.max.md')} {
    width: 100%;
  }
`;

export const Content = styled.div`
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  display: flex;
  width: 100%;
  padding: var(--spacing-5);

  > svg {
    height: 32px;
    text-indent: 100%;
    margin-bottom: var(--spacing-9);
    width: 120px;
    height: auto;
  }
`;

export const HorizontalRule = styled.hr`
  margin: var(--spacing-7) 0;
  border: 1px solid var(--border-01);
`;

interface ErrorMessageBlockProps {
  isOpen?: boolean;
}
export const ErrorMessageBlock = styled.div`
  display: ${(props: ErrorMessageBlockProps) => (props.isOpen ? 'block' : 'none')};
  text-align: left;
`;

export const ErrorMessageBlockTriggerArrow = styled.div`
  display: inline-block;
  position: relative;
  top: 4px;
  width: 18px;
  height: 18px;
  transition: all ease 0.15s;

  ${(props: ErrorMessageBlockProps) =>
    props.isOpen
      ? css`
          transform: rotate(-90deg);
        `
      : css`
          transform: rotate(90deg);
        `}

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: var(--text-02);
    }
  }
`;
