import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHostInfo } from '../lib/use-host';

type Auth0User = {
  name: string;
  email: string;
  picture: string;
  token: object;
};
type Auth0UserHooksResult = {
  user: Auth0User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
};
type Auth0UserHooksFunc = () => Auth0UserHooksResult;

export const useAuth0User: Auth0UserHooksFunc = () => {
  const hostInfo = useHostInfo();
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const shouldUseAuth0 = !!hostInfo?.auth0?.sso || !!hostInfo?.auth0?.google;
        if (shouldUseAuth0) {
          await getAccessTokenSilently({ audience: hostInfo?.auth0?.audience! });
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setTokenLoaded(true);
      }
    })();
    return () => {
      // cleanup
    };
  }, [getAccessTokenSilently, hostInfo]);

  if (isLoading || !tokenLoaded) {
    return {
      isAuthenticated: false,
      user: null,
      isLoading: true,
    };
  }

  if (!user || !isAuthenticated) {
    return {
      isAuthenticated: false,
      user: null,
      isLoading: false,
    };
  }

  return {
    isAuthenticated: true,
    user: user as Auth0User,
    isLoading: false,
  };
};
