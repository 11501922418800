import { OrderByEnum } from '../generated/graphql';

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function changeOrderToCircledNumber(order: number) {
  if (order < 1 || order > 20) {
    return undefined;
  }
  return String.fromCodePoint(9311 + order);
}

export function isEmail(str: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(String(str).toLowerCase());
}

export function stringToColor(str: string) {
  if (!str) return '#000';

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let u = 0; u < 3; u++) {
    const value = (hash >> (u * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const bytesForHuman = (bytes: number, decimals = 2) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let i = 0;
  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }
  return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i];
};

export const firstLetterToLowerCase = (str: string) => str[0].toLowerCase() + str.slice(1);

export const sortKeyToSortOrderInput = (_sortKey: string | undefined = '') => {
  const sortKey = _sortKey || '';
  if (sortKey.length === 0) return { sortId: null, sortDirection: OrderByEnum.Asc };

  const sortDirection = sortKey.includes('desc') ? OrderByEnum.Desc : OrderByEnum.Asc;
  const sortId = firstLetterToLowerCase(
    sortDirection === OrderByEnum.Desc
      ? (sortKey || '').replace('desc', '')
      : (sortKey || '').replace('asc', '') || '',
  );

  return {
    sortDirection,
    sortId,
  };
};

export const generateUuid = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16); //eslint-disable-line
  });
  return uuid;
};

export const getOrdinal = (n: number) => {
  let ord = 'th';

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st';
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd';
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd';
  }

  return ord;
};

export const splitString = (str: string, splitter = ',') =>
  str
    ? (str || '')
        .trim()
        .split(splitter)
        .map((t: string) => t.trim()) || []
    : [];

export const isValidUrl = (str: string | null | undefined): boolean => {
  if (!str) {
    return false;
  }

  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?.*)?' + // query string
      '(\\#.*)?$', //after sharp, allow all
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const urlWithProtocol = (url: string | undefined | null): string => {
  if (!url) {
    return '';
  }
  if (url.includes('http://') || url.includes('https://') || url.includes('ftp://')) {
    return url;
  }
  return `https://${url}`;
};

export const isUndefined = (val: any) => val === undefined || val === "undefined";

export const ucfirst = (string: any) => {
  if(typeof string !== "string") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRowCount = (str: string) => str?.split(/\r|\r\n|\n/)?.length || 1;