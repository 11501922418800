import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 're-design';
import { twJoin } from 'tailwind-merge';

interface LinkButtonProps {
  href: string;
  disabled?: boolean;
  variant?: string;
  size?: string;
  fluid?: boolean;
  align?: any;
  icon?: ReactNode;
  round?: boolean;
  target?: string;
  rel?: string;
  outline?: boolean;
  onlyIcon?: boolean;
  active?: boolean;
  bold?: boolean;
  children?: ReactNode;
}

const LinkButton: FC<LinkButtonProps> = ({
  disabled,
  variant,
  children,
  href,
  size,
  fluid,
  align,
  icon,
  round,
  rel,
  target,
  outline,
  onlyIcon,
  active,
  bold,
}) => {
  const jsxButton = (
    <Button
      variant={variant}
      disabled={disabled}
      size={size}
      fluid={fluid}
      align={align}
      icon={icon}
      round={round}
      outline={outline}
      onlyIcon={onlyIcon}
      active={active}
      bold={bold}
    >
      {children}
    </Button>
  );

  if (!href) {
    return <div className="block">{jsxButton}</div>;
  }

  return (
    <div  className={twJoin('block', disabled && 'pointer-events-none')}>
      {/* @ts-expect-error */}
      <Link to={href} target={target} rel={rel}>
        {jsxButton}
      </Link>
    </div>
  );
};

export default LinkButton;
