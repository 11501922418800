import { parse } from 'querystring';
import URLSearchParams from 'url-search-params';
import { omit } from './lodash';
import { matchPath } from 'react-router-dom';
import { urlWithProtocol } from './string';
import url from '../constants/url';

export const isPathMatch = (pathname: string, path: string | string[], exact?: boolean) => {
  return matchPath(pathname, {
    path,
    exact,
  });
};

export const getPathname = (url: string): string => {
  const p = url?.split('?');
  return p?.[0] || '';
};

export const getQuery = (search: string, name: string) => {
  return new URLSearchParams(search).get(name);
};

export const hasQuery = (search: string, name: string) => !!getQuery(search, name);

export const addQuery = (search: string, name: string, value: string) => {
  if (!value) return '';

  if (value === 'null') {
    return name;
  }

  const sp = new URLSearchParams(search);
  sp.append(name, value);
  return sp.toString();
};

export const parseQueryString = (search: string) => {
  const _search = search || '';
  return parse(_search[0] === '?' ? _search.substr(1) : _search) || {};
};

interface parseQueryProps {
  search: string;
  pathname: string;
}
export const parseQuery = (location: parseQueryProps) => {
  return parseQueryString(location.search || '');
};

export const formatQuery = (queryObj: any) => {
  let str = Object.keys(queryObj || {}).reduce((p, k) => addQuery(p, k, queryObj[k]), '');
  str = str.replace(new RegExp('=&', 'g'), '&');
  return str;
};

interface buildQueryStringProps {
  location: {
    pathname: string;
    search: string;
  };
  updating?: object;
  removing?: string[];
}
export const buildQueryString = ({ location, updating, removing }: buildQueryStringProps) => {
  if (typeof location !== 'object') return '';
  const oldQuery = parseQuery(location);
  const newQuery = {
    ...omit(oldQuery, removing || []),
    ...updating,
  };
  return formatQuery(newQuery);
};

interface buildPathAndQueryProps {
  location: {
    pathname: string;
    search: string;
  };
  updating?: object;
  removing?: string[];
}
export const buildPathAndQuery = ({ location, updating, removing }: buildPathAndQueryProps) => {
  if (typeof location !== 'object') return '';

  const queryStr = buildQueryString({ location, updating, removing });
  const split = queryStr ? (location.pathname.includes('?') ? '&' : '?') : '';

  return `${location.pathname}${split}${queryStr}`;
};

export const buildUrlFromConstant = (
  urlConstant: string,
  replacingOptions?: any,
  queryObj?: any,
): string => {
  if (!urlConstant) return urlConstant;

  let pathname = urlConstant;
  Object.keys(replacingOptions).forEach(k => {
    const regex = new RegExp(`:${k}`, 'g');
    pathname = pathname.replace(regex, replacingOptions[k]);
  });

  const queryStr = formatQuery(queryObj);
  const split = queryStr ? (pathname.includes('?') ? '&' : '?') : '';
  return queryObj ? `${pathname}${split}${queryStr}` : pathname;
};

export const getPathFromUrl = (url: string) => url.split(/[?#]/)[0];

export const buildWindowLocationUrlForPublicAsset = (path: string) => {
  return urlWithProtocol(`${window.location.origin}/${path}`);
};


export const isHrContext = (pathname: string) => isPathMatch(pathname, url.hr.index) || isPathMatch(pathname, url.ppo3.platform.index);