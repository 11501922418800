import palette from './palette';

const theme_light = {
  'ui-background-01': palette.gray10, //Default page background
  'ui-background-02': palette.white, //Wizard Layout background
  'ui-background-03': palette.gray10, //O3s background

  'ui-01': palette.white, //default container background
  'ui-02': palette.gray20, //default container background on hover
  'ui-03': palette.green30,
  'ui-04': palette.gray100, //tooltip background
  'ui-05': palette.gray30, //listhover Onhover

  'interactive-01': palette.green50,
  'interactive-02': palette.orange50,
  'interactive-03': palette.purple50,
  'interactive-04': palette.blue50,
  'interactive-05': palette.red50,

  'hover-interactive-01': palette.green70,
  'hover-interactive-02': palette.orange70,
  'hover-interactive-03': palette.purple70,
  'hover-interactive-04': palette.blue70,
  'hover-interactive-05': palette.red70,
  'inverse-interactive': palette.white,

  'text-01': palette.gray100, //Primary Text
  'text-02': palette.gray50, //Secondary Text / Label Text
  'text-03': palette.gray30, //Tertiary Text
  'text-04': palette.gray40, //Placeholder Text
  'text-05': palette.white, // Interactive Text
  'text-error': palette.red50, // Error message text
  'text-disabled': palette.gray30, // Disabled elements  / Disabled label / Disabled text / Disabled icons
  'text-success': palette.blue50, //Success message text

  link: palette.gray50, //link;
  'link-visited': palette.gray50, //Visited link;
  'link-hover': palette.gray100, //Hover link;
  'link-active': palette.gray100, //Active link;

  'border-01': palette.gray20, //container border
  'border-02': palette.gray30, //input border
  'border-03': palette.gray50, //input border hover
  'border-04': palette.gray10,

  'chart-background': palette.gray30,
  'chart-01': palette.green50,
  'chart-02': palette.orange50,
  'chart-03': palette.purple50,
  'chart-04': palette.blue50,
  'chart-05': palette.red50,
  'chart-06': palette.springGreen50,
  'chart-07': palette.gray40,
  'chart-08': palette.yellow50,

  'chart-01-pale': palette.green30,
  'chart-04-pale': palette.blue30,
  'chart-05-pale': palette.red30,

  focus: palette.green50, //border focus; field focus;
  disabled: palette.gray30, //(input disabled)
  'disabled-checked': palette.green40,
  error: palette.red50, //field error,
  success: palette.blue50,

  'focus-pale': palette.green30,
  'error-pale': palette.red30,
  'success-pale': palette.blue30,
  'success-bold': palette.blue70,

  'shadow-01': 'rgba(0, 0, 0, 0.1)',
  'shadow-02': 'rgba(0, 0, 0, 0.2)',
  'shadow-03': 'rgba(204, 207, 207, 0.25)',
  'shadow-04': 'rgba(32, 34, 34, 0.1)',
};

export default theme_light;
