import React, { FC } from 'react';
import { Responsive, Button, icons } from 're-design';
import { SheetViewWrapper, SheetViewHeader, SheetViewBody } from './styled';
import { useHideHubspotChat } from '../../../lib/use-hubspot-chat';
import { ReactNode } from 'react';
import { UseFormReturn, useFormState, UseFormStateReturn } from 'react-hook-form';
import Flex from '../../quarks/Flex';
import Heading from '../../quarks/Heading';

interface SheetViewProps {
  onClose?: () => void;
  onBack?: () => void;
  onCloseWithHookFormState?: (state: UseFormStateReturn<any>) => void;
  headerText?: string;
  action?: ReactNode;
  expanded?: boolean;
  withHookform?: boolean;
  form?: UseFormReturn<any, any>;
  hideOnMobile?: boolean;
  hideHeader?: boolean;
  variant?: string;
  size?: string;
  children?: ReactNode;
}

const ButtonWithHookForm: FC<{
  form: UseFormReturn<any, any>;
  onClose?: (state: UseFormStateReturn<any>) => void;
}> = ({ form, onClose }) => {
  const { control } = form;
  const { isDirty } = useFormState({ control });

  return (
    <Button
      icon={<icons.CloseIcon />}
      onlyIcon
      variant="transparent"
      onClick={
        onClose
          ? () =>
              onClose({
                isDirty,
              } as UseFormStateReturn<any>)
          : undefined
      }
    />
  );
};

const SheetRightPanel: FC<SheetViewProps> = ({
  onClose,
  onBack,
  onCloseWithHookFormState,
  children,
  headerText,
  action,
  expanded,
  withHookform,
  form,
  hideOnMobile = false,
  hideHeader = false,
  variant,
  size,
}) => {
  useHideHubspotChat();

  return (
    <SheetViewWrapper expanded={expanded} hideOnMobile={hideOnMobile} variant={variant} size={size}>
      {!hideHeader && (
        <Responsive laptop tablet>
          <SheetViewHeader>
            <Flex between="small">
              {!!onBack && (
                <Button onClick={() => onBack()} onlyIcon icon={<icons.AngleArrowLeft />} />
              )}
              <Heading variant="h3">{headerText}</Heading>
            </Flex>
            <div>
              {action}

              {withHookform && !!form && !!onCloseWithHookFormState ? (
                <ButtonWithHookForm form={form} onClose={onCloseWithHookFormState} />
              ) : (
                <>
                  {!!onClose && (
                    <Button
                      icon={<icons.CloseIcon />}
                      onlyIcon
                      variant="transparent"
                      onClick={onClose}
                    />
                  )}
                </>
              )}
            </div>
          </SheetViewHeader>
        </Responsive>
      )}
      <SheetViewBody hideHeader={hideHeader}>{children}</SheetViewBody>
    </SheetViewWrapper>
  );
};

export default SheetRightPanel;
