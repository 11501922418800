import i18next from 'i18next';
import { takeAwayEmptyElement, unique } from '../../../../utils/array';
import { UseDictionnaryReturn } from '../../../../lib/use-me';

export enum ImportProcessStatus {
  Doing = 'doing',
  Removed = 'removed',
  Ready = 'ready',
  Success = 'success',
  Existing = 'existing',
  Error = 'error',
}

export const processArray = (
  array: any[],
  fn: (curr: any[], index: number, total: number) => Promise<any> | void,
) => {
  return array.reduce((acc, curr: any[], idx: number, originalArray: any[]) => {
    if (idx === originalArray.length) {
      return acc;
    }
    return acc.then(() => fn(curr, idx + 1, originalArray.length));
  }, Promise.resolve());
};

export const uniqById = (arr: any[]) => unique(arr, (i: any) => i?.id);
export const uniqByKey = (arr: any[]) => unique(arr, (i: any) => i?.key);
export const uniqByEmail = (arr: any[]) => unique(arr, (i: any) => i?.email);
export const uniqByName = (arr: any[]) => unique(arr, (i: any) => i?.name);

export const getErrorTypes = (
  errorTypes: string[] | null | undefined,
  dict: UseDictionnaryReturn,
) => {
  return takeAwayEmptyElement<string[]>(
    takeAwayEmptyElement<string[]>(errorTypes).map(errorType => {
      switch (errorType) {
        case 'cycle':
          return i18next.t('サイクルを選択してください');
        case 'email':
          return i18next.t('招待メンバーのメールアドレスを入力してください');
        case 'same-email-in-csv':
          return i18next.t('そのメールアドレスはCSVファイルの中で重複しています');
        case 'same-email-in-system':
          return i18next.t('そのメールアドレスは使用できません（他のユーザーが使用しています）');
        case 'same-employee-number':
          return i18next.t('招待メンバーの社員番号が重複しています');
        case 'firstName':
          return i18next.t('招待メンバーの名を入力してください');
        case 'lastName':
          return i18next.t('招待メンバーの姓を入力してください');
        case 'role':
          return i18next.t('招待メンバーの権限を選択してください');
        case 'manager':
          return i18next.t('担当マネージャーのメールアドレスを入力してください');
        case 'manager-format':
          return i18next.t('担当マネージャーのメールアドレスが正しくありません');
        case 'manager-not-exist':
          return i18next.t('担当マネージャーが見つかりませんでした');
        case 'member':
          return i18next.t('メンバーのメールアドレスを入力してください');
        case 'member-format':
          return i18next.t('メンバーのメールアドレスが正しくありません');
        case 'member-not-exist':
          return i18next.t('メンバーが見つかりませんでした');
        case 'mentor':
          return i18next.t('{{wMentor}}のメールアドレスを入力してください', dict);
        case 'mentor-format':
          return i18next.t('{{wMentor}}のメールアドレスが正しくありません', dict);
        case 'mentor-not-exist':
          return i18next.t('{{wMentor}}が見つかりませんでした', dict);
        case 'mentee':
          return i18next.t('{{wMentee}}を入力してください', dict);
        case 'mentee-format':
          return i18next.t('{{wMentee}}の入力フォーマットが正しくありません', dict);
        case 'mentee-not-exist':
          return i18next.t('{{wMentee}}が見つかりませんでした', dict);
        case 'same-member-manager':
          return i18next.t('自分自身をマネージャーに指定することはできません');
        case 'same-mentor-mentee':
          return i18next.t('{{wMentor}}と{{wMentee}}に同じ人を指定することはできません', dict);

        case 'email-not-exists':
          return i18next.t('入力されたメールアドレスは、Wistantに登録されていません', dict);
        case 'duplicate-email':
          return i18next.t('同じユーザーを重複して設定することはできません');
        case 'already-imported':
          return i18next.t('このメンバーは既に登録されています', dict);

        case 'feedback-assignee-receiver-email':
          return i18next.t(
            '{{wSelfLayerAssignee}}に入力されたメールアドレスが正しくありません',
            dict,
          );
        case 'feedback-assignee-final-email':
          return i18next.t(
            '{{wFinalLayerAssignee}}に入力されたメールアドレスが正しくありません',
            dict,
          );
        case 'feedback-assignee-sender-email':
          return i18next.t('評価者に入力されたメールアドレスが正しくありません');
        case 'feedback-assignee-middle-email':
          return i18next.t(
            '{{wMiddleLayerAssignee}}に入力されたメールアドレスが正しくありません',
            dict,
          );
        case 'feedback-assignee-middle1-email':
          return i18next.t(
            '{{wMiddleLayerAssignee}}{{no}}に入力されたメールアドレスが正しくありません',
            {
              ...dict,
              no: '①',
            },
          );
        case 'feedback-assignee-middle2-email':
          return i18next.t(
            '{{wMiddleLayerAssignee}}{{no}}に入力されたメールアドレスが正しくありません',
            {
              ...dict,
              no: '②',
            },
          );
        case 'feedback-assignee-middle3-email':
          return i18next.t(
            '{{wMiddleLayerAssignee}}{{no}}に入力されたメールアドレスが正しくありません',
            {
              ...dict,
              no: '③',
            },
          );
        case 'feedback-assignee-read-email':
          return i18next.t(
            '{{wReadLayerAssignee}}に入力されたメールアドレスが正しくありません',
            dict,
          );
        case 'feedback-assignee-supervise-email':
          return i18next.t(
            '{{wSuperviseLayerAssignee}}に入力されたメールアドレスが正しくありません',
          );
        case 'feedback-assignee-duplicate-email':
          return i18next.t('評価者に同じユーザーを重複して設定することはできません');
        case 'feedback-assignee-receiver-email-not-exists':
          return i18next.t(
            '{{wSelfLayerAssignee}}に入力されたメールアドレスは、Wistantに登録されていません',
            dict,
          );
        case 'feedback-assignee-final-email-not-exists':
          return i18next.t(
            '{{wFinalLayerAssignee}}に入力されたメールアドレスは、Wistantに登録されていません',
            dict,
          );
        case 'feedback-assignee-middle-email-not-exists':
          return i18next.t(
            '{{wMiddleLayerAssignee}}に入力されたメールアドレスは、Wistantに登録されていません',
            dict,
          );
        case 'feedback-assignee-middle1-email-not-exists':
          return i18next.t(
            '{{wMiddleLayerAssignee}}{{no}}に入力されたメールアドレスは、Wistantに登録されていません',
            { ...dict, no: '①' },
          );
        case 'feedback-assignee-middle2-email-not-exists':
          return i18next.t(
            '{{wMiddleLayerAssignee}}{{no}}に入力されたメールアドレスは、Wistantに登録されていません',
            { ...dict, no: '②' },
          );
        case 'feedback-assignee-middle3-email-not-exists':
          return i18next.t(
            '{{wMiddleLayerAssignee}}{{no}}に入力されたメールアドレスは、Wistantに登録されていません',
            { ...dict, no: '③' },
          );
        case 'feedback-assignee-read-email-not-exists':
          return i18next.t(
            '{{wReadLayerAssignee}}に入力されたメールアドレスは、Wistantに登録されていません',
            dict,
          );
        case 'feedback-assignee-supervise-email-not-exists':
          return i18next.t(
            '{{wSuperviseLayerAssignee}}に入力されたメールアドレスは、Wistantに登録されていません',
          );
        case 'feedback-receiver-duplicate-email':
          return i18next.t(
            '{{wSelfLayerAssignee}}に、同一ユーザーを重複して設定することはできません',
            dict,
          );
        case 'feedback-receiver-already-imported':
          return i18next.t('このメンバーは既に{{wSelfLayerAssignee}}に登録されています', dict);
        case 'feedback-assignee-same-receiver-to-reader':
          return i18next.t(
            '{{wReadLayerAssignee}}に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
            dict,
          );
        case 'feedback-assignee-same-receiver-to-supervisor':
          return i18next.t(
            '{{wSuperviseLayerAssignee}}に{{wSelfLayerAssignee}}と同じユーザーを設定することはできません',
            dict,
          );
        case 'feedback-assignee-same-reader-to-supervisor':
          return i18next.t(
            '{{wSuperviseLayerAssignee}}に閲覧者と同じユーザーを設定することはできません',
            dict,
          );

        case 'personal-sheet-assignee-same-person-to-reader':
          return i18next.t('閲覧者に対象者と同じユーザーを設定することはできません');
        case 'personal-sheet-person-already-imported':
          return i18next.t('このメンバーは既に対象者に登録されています');
        case 'personal-sheet-person-duplicate-email':
          return i18next.t('対象者に、同一ユーザーを重複して設定することはできません', dict);
        case 'personal-sheet-assignee-read-email-not-exists':
          return i18next.t('閲覧者に入力されたメールアドレスは、Wistantに登録されていません');
        case 'personal-sheet-assignee-person-email-not-exists':
          return i18next.t('対象者に入力されたメールアドレスは、Wistantに登録されていません', dict);
        case 'personal-sheet-assignee-duplicate-email':
          return i18next.t('対象者に同じユーザーを重複して設定することはできません');
        case 'personal-sheet-assignee-read-email':
          return i18next.t('閲覧者に入力されたメールアドレスが正しくありません');
        case 'personal-sheet-assignee-person-email':
          return i18next.t('対象者に入力されたメールアドレスが正しくありません');

        case 'objective-sheet-person-already-imported':
          return i18next.t('このメンバーは既に対象者に登録されています');
        case 'objective-sheet-assignee-same-person-to-approver':
          return i18next.t('承認者に対象者と同じユーザーを設定することはできません');
        case 'objective-sheet-assignee-same-person-to-reader':
          return i18next.t('閲覧者に対象者と同じユーザーを設定することはできません');
        case 'objective-sheet-person-duplicate-email':
          return i18next.t('対象者に、同一ユーザーを重複して設定することはできません', dict);
        case 'objective-sheet-assignee-approve-email-not-exists':
          return i18next.t('承認者に入力されたメールアドレスは、Wistantに登録されていません');
        case 'objective-sheet-assignee-read-email-not-exists':
          return i18next.t('閲覧者に入力されたメールアドレスは、Wistantに登録されていません');
        case 'objective-sheet-assignee-person-email-not-exists':
          return i18next.t('対象者に入力されたメールアドレスは、Wistantに登録されていません', dict);
        case 'objective-sheet-assignee-duplicate-email':
          return i18next.t('対象者に同じユーザーを重複して設定することはできません');
        case 'objective-sheet-assignee-approve-email':
          return i18next.t('承認者に入力されたメールアドレスが正しくありません');
        case 'objective-sheet-assignee-read-email':
          return i18next.t('閲覧者に入力されたメールアドレスが正しくありません');
        case 'objective-sheet-assignee-person-email':
          return i18next.t('対象者に入力されたメールアドレスが正しくありません');

        case 'team-create-no-company-id':
          return i18next.t('企業コードが指定されていません');
        case 'team-create-no-code':
          return i18next.t('{{wTeam}}コードが入力されていません', dict);
        case 'team-create-no-name':
          return i18next.t('{{wTeam}}名が入力されていません', dict);
        case 'team-create-duplicated-code':
          return i18next.t('{{wTeam}}コードが重複しています', dict);
        case 'team-parent-error-path':
          return i18next.t('指定された親{{wTeam}}がCSVファイルに存在しません。', dict);

        case 'email-format':
          return i18next.t('入力されたメールアドレスが正しくありません', dict);

        case 'pair-cycle-key-empty':
          return i18next.t('1on1を実施するサイクルが設定されていません', dict);

        default:
          return null;
      }
    }),
  );
};

export const getParentsKeyArrayFromParentKeyPath = (path: string) => {
  const arr = path.replace('://', '').split('::');
  arr.pop();
  return arr;
};
