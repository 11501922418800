import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../../package.json';
import showVersionUpToast from '../../../lib/version-up-link/toast';

const appVersion = packageJson.version;

const verGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster: FC<{}> = () => {
  const { t } = useTranslation();
  const [{ loading, isLatestVersion }, setCacheInfo] = useState({
    loading: true,
    isLatestVersion: false,
  });

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = appVersion;
        const shouldForceRefresh = verGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          setCacheInfo({ loading: false, isLatestVersion: false });
        } else {
          setCacheInfo({ loading: false, isLatestVersion: true });
        }
      });
  }, [t]);

  if (!loading && !isLatestVersion) {
    showVersionUpToast();
  }

  return null;
};

export default CacheBuster;
