import { Maybe } from '../generated/graphql';

export const isArray = (arr: any) => Array.isArray(arr);

export const hasElement = (arr: any): boolean => {
  return isArray(arr) && arr.length > 0;
};

export const sortWithoutBreaking = <T = Array<any>>(
  arr: any,
  comparator: (a: any, b: any) => number,
): T => {
  if (!hasElement(arr) || typeof comparator !== 'function') return [] as T;
  return ([...arr].slice().sort(comparator) || []) as T;
};

// interatee = (arr) => array.idのように、uniqueにしたいvalueを返す関数
export const unique = <T = Array<any>>(_arr: any[], interatee: (arg: any) => any): T => {
  const arr = takeAwayEmptyElement<T>(_arr) as Array<any>;
  const vArr = arr.map(interatee) as Array<any>;

  return arr.filter((_, i) => vArr.indexOf(vArr[i]) === i) as T;
};

export const transformToArrayIsNotArray = <T = Array<any>>(value: any | any[]): T => {
  if (!value) {
    return [] as T;
  }
  if (isArray(value)) {
    return value as T;
  }
  return [value] as T;
};

export const takeAwayMaybeElement = <T = Array<any>>(value: Maybe<Maybe<any>[]> | undefined): T => {
  return transformToArrayIsNotArray(value as any).filter(item => item !== null) as T;
  //export type Maybe<T> = T | null;
};

export const takeAwayNullElement = <T = Array<any>>(value: Maybe<Maybe<any>[]> | undefined): T => {
  return transformToArrayIsNotArray(value as any).filter(item => item !== null) as T;
};

export const takeAwayEmptyElement = <T = Array<any>>(value: any | any[] | undefined): T => {
  return transformToArrayIsNotArray(value as any).filter(item => !!item) as T;
};

export const getArrayValuesByKey = <T = any[]>(
  value: any | any[] | undefined,
  key: string | undefined = 'id',
): T => {
  const arr = takeAwayEmptyElement(value);
  return takeAwayEmptyElement(arr.map(item => item[key]));
};

export const getArrayIds = (
  value: any | any[] | undefined,
  idKey: string | undefined = 'id',
): string[] => getArrayValuesByKey(value, idKey);

export const getFirstArrayItem = <T = any>(_arr: any | any[]): T => {
  const arr = takeAwayEmptyElement<T[]>(_arr);
  return arr[0];
};

export const getLastArrayItem = <T = any>(_arr: any | any[]): T => {
  const arr = takeAwayEmptyElement<T[]>(_arr);
  return arr[arr.length - 1];
};
