import { FC, ReactNode } from 'react';

import { BaseSideSheetFooter } from './styled';
import { TextAlign } from '../../quarks/Typo';

interface SideSheetFooterProps {
  sticky?: boolean;
  align?: TextAlign;
  size?: string;
  minHeight?: string;
  children?: ReactNode;
}

const SideSheetFooter: FC<SideSheetFooterProps> = ({
  children,
  sticky,
  size,
  align,
  minHeight,
}) => (
  <BaseSideSheetFooter align={align} sticky={sticky} size={size} minHeight={minHeight}>
    {children}
  </BaseSideSheetFooter>
);

export default SideSheetFooter;
